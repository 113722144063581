import React from 'react';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { Helmet } from 'react-helmet';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import TopbarNav from './topbar_nav/TopbarNav';
import TopbarProfile from './TopbarProfile';
import SidebarSwipeable from './SidebarSwipeable';
import TopbarManagedSelectUser from './TopbarManagedSelectUser';

const TopbarInvestor = ({ user, currentManagedUser }) => {
  const matches = useMediaQuery('(max-width:991px)');

  return (
    <div
      className="topbar topbar--navigation"
      style={{ position: 'relative', zIndex: 1400 }}
    >
      <Helmet>
        <title>Venture Clubs - Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <div className="topbar__wrapper">
        <div className="topbar__left">
          {matches && user && (
            <SidebarSwipeable />
          )}
          <a
            style={{
              marginLeft: '15px', marginTop: '10px', marginRight: '15px', display: 'block', cursor: 'pointer',
            }}
            className="topbar__logo"
            onClick={() => window.location.replace(`${process.env.AVG_API_PATH}/investor/managed`)}
          />
          {(user != null) && (
            <nav className="topbar__nav">
              {!currentManagedUser ? (
                <>
                  <Tooltip title="Select a user to access their investment data" placement="bottom" arrow>
                    <Link
                      className="topbar__nav-link"
                      to="/managed"
                    >
                      Dashboard
                    </Link>
                  </Tooltip>
                  <Tooltip title="Select a user to access their investment data" placement="bottom" arrow>
                    <Link
                      className="topbar__nav-link"
                      to="/managed/funds/statements"
                    >
                      Documents & Tax Statements
                    </Link>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Link
                    className="topbar__nav-link"
                    to="/managed"
                    style={!currentManagedUser ? { pointerEvents: 'none' } : null}
                  >
                    Dashboard
                  </Link>
                  <Link
                    className="topbar__nav-link"
                    to="/managed/funds/statements"
                    style={!currentManagedUser ? { pointerEvents: 'none' } : null}
                  >
                    Documents & Tax Statements
                  </Link>
                </>
              )}
              <TopbarManagedSelectUser />
            </nav>
          )}
        </div>
        <div className="topbar__right">
          {user != null && <TopbarProfile user={user} />}
        </div>
      </div>
      {matches && user && (
        <TopbarManagedSelectUser />
      )}
    </div>
  );
};

TopbarInvestor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  currentManagedUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

TopbarInvestor.defaultProps = {
  user: null,
  currentManagedUser: '',
};

function mapStateToProps(state) {
  const { auth, currentManagedUser } = state;
  const { user } = auth;
  return { user, currentManagedUser };
}

export default connect(mapStateToProps)(TopbarInvestor);
