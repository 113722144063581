import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Card, CardContent, Stack, Button, Link,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ReinvestCard = React.forwardRef(({ setReinvestDialogOpen, size = 'narrow', sx }, ref) => (
  <Card
    ref={ref}
    sx={{
      maxWidth: size === 'narrow' ? '28ch' : '40ch',
      backgroundColor: 'lightBlue.light',
      ...sx,
    }}
  >
    <CardContent>
      <Stack spacing={1} alignItems={{ xs: 'center', sm: 'center', md: 'start' }}>
        <Typography variant="h2" align="center">
          Re-invest Your Distributions
        </Typography>
        <Typography variant="body2">
          If you want to allocate your distribution proceeds directly into a new fund,
          <strong> please let us know by August 22, 2024. </strong>
          Click the button below to get started.
        </Typography>
        <Button
          type="button"
          variant="contained"
          endIcon={<NavigateNextIcon />}
          onClick={() => setReinvestDialogOpen(true)}
        >
          Get Started
        </Button>
        <div>
          <Typography
            variant="caption"
            sx={{
              textAlign: { xs: 'center', sm: 'center', md: 'left' },
            }}
          >
            Have questions or need help?
            <Link
              href="https://3925488.fs1.hubspotusercontent-na1.net/hubfs/3925488/_SoT_Mirror/22_Investor_Engagement/FAQs_for_Re-Invest.pdf"
              sx={{ display: 'block' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read our full FAQs.
            </Link>
          </Typography>
        </div>
      </Stack>
    </CardContent>
  </Card>
));

ReinvestCard.propTypes = {
  size: PropTypes.oneOf(['wide', 'narrow']),
  sx: PropTypes.object, // eslint-disable-line
};

ReinvestCard.defaultProps = {
  size: 'narrow',
};

export default ReinvestCard;
