/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends React.Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillUpdate({ location, history }) {
    const { ga } = window.ga;
    // eslint-disable-next-line react/destructuring-assignment
    const { pathname } = this.props.location;
    if (location.pathname === pathname) {
      // don't log identical link clicks (nav links likely)
      return;
    }

    if (history.action === 'PUSH'
    && typeof (ga) === 'function') {
      ga('config', 'UA-112414020-2', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname,
      });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
