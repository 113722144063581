import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import MobileDistributionsTable from './MobileDistributionsTable';
import DistributionsTable from './DistributionsTable';

export default function TotalAmountTable({
  distributionData, fundName, showCurrentQuarterDistributions, fundCode,
  fundTotalDistributions,
}) {
  const theme = useTheme();
  const desktopSize = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
      {desktopSize
        ? (
          <DistributionsTable
            distributionData={distributionData}
            fundName={fundName}
            showCurrentQuarterDistributions={showCurrentQuarterDistributions}
            fundCode={fundCode}
            fundTotalDistributions={fundTotalDistributions}
          />
        )
        : (
          <MobileDistributionsTable
            distributionData={distributionData}
            fundName={fundName}
            showCurrentQuarterDistributions={showCurrentQuarterDistributions}
            fundCode={fundCode}
            fundTotalDistributions={fundTotalDistributions}
          />
        )}
    </>
  );
}

TotalAmountTable.propTypes = {
  distributionData: PropTypes.arrayOf(PropTypes.object).isRequired,
  fundName: PropTypes.string,
  showCurrentQuarterDistributions: PropTypes.bool.isRequired,
  fundCode: PropTypes.string,
  fundTotalDistributions: PropTypes.string,
};

TotalAmountTable.defaultProps = {
  fundName: '',
  fundCode: '',
  fundTotalDistributions: '',
};
