import { useState, useEffect } from 'react';
import { getUserInvestments } from '../../../shared/services/apiGateway';

function useUserInvestments(userid = '') {
  const [userInvestments, setUserInvestments] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getUserInvestments(userid, abortController.signal);
      if (!ignore && response !== 'aborted' && response) {
        setUserInvestments(response);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [userid]);

  return userInvestments;
}

export default useUserInvestments;
