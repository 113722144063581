import { useState, useEffect, useMemo } from 'react';

export const sortAlphanumerically = (a, b, orderBy = null) => {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  if (!orderBy) {
    return a.localeCompare(b, 'en', { numeric: true });
  }
  if (a[orderBy] && b[orderBy]) {
    return `${a[orderBy]}`.localeCompare(`${b[orderBy]}`, 'en', { numeric: true });
  }
  return a[orderBy] > b[orderBy] ? 1 : -1;
};

export const sortByDate = (a, b, orderBy) => {
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  if (!a[orderBy] && b[orderBy]) {
    return -1;
  }
  if (!b[orderBy] && a[orderBy]) {
    return 1;
  }
  // if both dates are nullish, sort by a different key instead
  // this enforces some consistent order in nullish values
  if (!b[orderBy] && !a[orderBy]) {
    const firstKey = Object.keys(a)[0];
    return sortAlphanumerically(a, b, firstKey);
  }
  return new Date(a[orderBy]) - new Date(b[orderBy]);
};

export const getComparator = (order, orderBy, comparator) => (
  order === 'asc'
    ? (a, b) => comparator(a, b, orderBy)
    : (a, b) => -comparator(a, b, orderBy)
);

/**
 * Custom hook to handle sorting an array of objects by a key and a
 * given direction, also supports setting what comparator function to use
 *
 * data - array of objects to sort
 * defaultSortBy - default key and direction to sort by e.g. datePaid-asc
 * defaultComparator - default comparator function to use
 * onSortChange - callback to handle when data changes from sort
 * return:
 *    sortBy - current key and direction to sort by
 *    setSortBy - handler to update sortBy value
 *    setComparator - handler to update comparator function
 */
export function useSort(data, defaultSortBy, defaultComparator, onSortChange) {
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [comparator, setComparator] = useState(() => defaultComparator);

  // Create a copy before sorting, as the original array is frozen in strict mode.
  // `useMemo` prevents multiple re-renderings if the shallow-compared value
  // of `data` hasn't changed.
  const sortedData = useMemo(() => [...data], [data]);

  useEffect(() => {
    if (sortedData.length) {
      const [orderBy, order] = sortBy.split('-');

      sortedData.sort(getComparator(order, orderBy, comparator));

      // Compare data before calling sort change handler.
      // this could probably be optimized if we have performance issues
      // with larger data sets or complex objects
      const dataFlat = JSON.stringify(data);
      const sortedFlat = JSON.stringify(sortedData);
      if (dataFlat !== sortedFlat && onSortChange) {
        onSortChange(sortedData, sortBy);
      }
    }
  }, [data, sortedData, sortBy, comparator, onSortChange]);

  return {
    sortBy,
    setSortBy,
    setComparator,
  };
}

export default useSort;
