import { useState, useEffect } from 'react';
import { getUserDistributions } from '../../../shared/services/apiGateway';

function useUserDistributions(userid = '') {
  const [userDistributions, setUserDistributions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false; // don't set old results

    async function fetchData() {
      try {
        setIsError(false);
        setIsLoading(true);
        const response = await getUserDistributions(userid, abortController.signal);
        if (!ignore && response !== 'aborted' && response) {
          setUserDistributions(response);
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [userid]);

  return { userDistributions, isLoading, isError };
}

export default useUserDistributions;
