import { useState, useEffect } from 'react';
// TODO: Remove getUserTokens functionality for migration
import { getUserTokens } from '../../../shared/services/apiGateway';

function useUserTokens() {
  const [userTokens, setUserTokens] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getUserTokens(abortController.signal);
      if (!ignore && response !== 'aborted' && response) {
        setUserTokens(response);
      }
    }

    fetchData();
    return () => {
      ignore = true;
      abortController.abort();
    };
  }, []);
  return userTokens;
}

export default useUserTokens;
