import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from 'mdi-react/MenuIcon';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  text: {
    color: '#646777',
    fontSize: '14px',
    fontFamily: '"Inter", "Roboto", sans-serif',
  },
});

const SwipeableTemporaryDrawer = () => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    open: false,
  });

  const navigate = (link) => {
    console.log(link);
    if (link.indexOf('http') !== -1) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
  };

  const toggleDrawer = (openNewVal) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, open: openNewVal });
  };

  const makeMenuItem = (title, icon, path) => ({
    title,
    icon,
    path,
  });

  const menuList = [
    makeMenuItem('Investor Dashboard', 'list', '/managed'),
    makeMenuItem('Documents', 'list', '/managed/funds/statements'),
    // per 5388 remove AVG Classroom
    // makeMenuItem('AVG Classroom', 'list', 'https://www.avgfunds.com/venture-clubs/classroom/'),
  ];

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuList.map((obj) => (
          <React.Fragment key={obj.title}>
            <ListItem
              className={classes.text}
              button
              key={obj.title}
              onClick={(e) => {
                e.preventDefault();
                navigate(obj.path);
              }}
            >
              {obj.icon && (<span className={`topbar__link-icon lnr lnr-${obj.icon}`} />)}
              <ListItemText classes={{ primary: classes.text }} primary={obj.title} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <React.Fragment key="left">
      <IconButton onClick={toggleDrawer(!state.open)}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={state.open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{
          paper: classes.root,
        }}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default SwipeableTemporaryDrawer;