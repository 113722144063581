import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Stack, List, ListItem, ListItemText,
} from '@mui/material';
import {
  ArticleBox,
  ArticleHeader,
  ArticleHeaderSupertext,
  ArticleHeaderNumber,
  ArticleBannerText,
} from './ArticlesBannerStyles';

const countRecentArticles = (articles) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  return articles.filter((article) => {
    const articleDate = new Date(article.date);
    return articleDate >= thirtyDaysAgo;
  }).length;
};

const ArticlesBanner = ({ portfolioNews }) => {
  const recentArticleCount = countRecentArticles(portfolioNews);
  const [numberOfNewArticles, setNumberOfNewArticles] = useState(0);
  const [typesOfArticles] = useState([
    'Managing Partner Update', 'Syndication Update', 'AV News Alert',
  ]);

  useEffect(() => {
    setNumberOfNewArticles(recentArticleCount);
  }, [recentArticleCount]);

  return (
    <Link to="/news">
      <ArticleBox>
        <Stack
          direction="column"
          justifyContent="center"
          spacing={5.5}
          sx={{
            height: '100%',
            borderRight: { xs: 'none', md: '1px solid #003349' },
          }}
        >
          <ArticleHeader component="h3">
            <ArticleHeaderSupertext component="span" sx={{ marginLeft: '25px' }}>
              In the last 30 days
            </ArticleHeaderSupertext>
            <ArticleHeaderNumber component="span">
              {numberOfNewArticles}
            </ArticleHeaderNumber>
            News Articles
          </ArticleHeader>
          {typesOfArticles && typesOfArticles.length > 0 && (
            <List
              sx={{
                pt: 0,
                pb: 3,
                pl: 3,
                pr: 3,
                maxWidth: 315,
                '&&': {
                  ml: 2,
                },
                borderLeft: '1px solid white',
              }}
            >
              {typesOfArticles.map((type, index) => (
                <ListItem
                  key={type}
                  divider={index !== typesOfArticles.length - 1}
                  sx={{
                    borderColor: 'white',
                    pl: 0,
                    pt: index === 0 ? 0 : 1,
                  }}
                >
                  <ListItemText
                    disableTypography
                    sx={{
                      mt: index === 0 ? 0 : 0.5,
                      lineHeight: '15px',
                    }}
                  >
                    <ArticleBannerText>{type}</ArticleBannerText>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          )}
        </Stack>
      </ArticleBox>
    </Link>
  );
};

ArticlesBanner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  portfolioNews: PropTypes.array,
};

export default ArticlesBanner;
