/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions,
jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Badge } from 'reactstrap';
// import TopbarNavDashboards from './TopbarNavDashboards';
import TopbarNavInvestments from './TopbarNavInvestments';
import usePortfolioNews from '../../../Investor/common/usePortfolioNews';
// import TopbarNavOtherPages from './TopbarNavOtherPages';

const countRecentArticles = (articles) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  return articles.filter((article) => {
    const articleDate = new Date(article.date);
    return articleDate >= thirtyDaysAgo;
  }).length;
};

const TopbarNav = ({ user, userPortfolio }) => {
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  const { distributionsPage } = useFlags();
  const { newsPage } = useFlags();
  const { portfolioNews } = usePortfolioNews(currentManagedUser ? `?userid=${currentManagedUser}` : '');
  const recentArticleCount = countRecentArticles(portfolioNews);
  return (
    <nav className="topbar__nav">
      <Link className="topbar__nav-link" to="/">
        Dashboard
      </Link>
      {
        (distributionsPage && user.isInvestor === 1)
          && (
          <Link
            className="topbar__nav-link"
            to="/distributions"
          >
            Distributions
          </Link>
          )
      }
      {
        user.isInvestor === 1
          && (
          <Link
            className="topbar__nav-link"
            to="/funds/statements"
          >
            Documents & Tax Statements
          </Link>
          )
      }
      {/* <TopbarNavOtherPages /> */}
      {
        (newsPage && user.isInvestor === 1)
        && (
          <Link className="topbar__nav-link" to="/news">
            News {(portfolioNews && recentArticleCount > 0) && (
              <Badge className="mr-1" color="default">{recentArticleCount}</Badge>
          )}
          </Link>
        )
      }
      <TopbarNavInvestments user={user} userPortfolio={userPortfolio} />
    </nav>
  );
};

TopbarNav.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userPortfolio: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
});

export default withRouter((connect(mapStateToProps)(TopbarNav)));
