import React from 'react';
import propTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Modal, Grid, Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Table } from 'reactstrap';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    margin: '0 auto',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: '#646777',
    '& h2': {
      marginTop: 20,
    },
    '& p': {
      fontSize: 16,
      marginBottom: 20,
      marginTop: 20,
    },
  },
  paddedGrid: {
    padding: '16px 48px 24px 48px',
    maxHeight: '600px',
    overflowY: 'scroll',
  },
  table: {
    margin: '20px 0px 30px 0px',
  },
  tableContainer: {
    margin: '20px 0px',
  },
}));

const FeeDetailsModal = ({ openModal, data, closeModal }) => {
  const classes = useStyles();

  if (!data) {
    return null;
  }

  const multipleEntities = (data.length > 1);
  const tables = data.map((entity) => (
    <div className={classes.tableContainer}>
      {multipleEntities && <h3>{entity.entityName}</h3>}
      <Table
        striped
        className={classes.table}
      >
        <thead>
          <tr>
            <th>Type</th>
            <th>% Fee</th>
          </tr>
        </thead>
        <tbody>
          { entity.displayValues.map((row) => {
            let rowValue = row.value;
            if (row.label === 'Additional Investable Capital') {
              rowValue = `$${row.value}`;
            } else if (row.label === 'Committed Capital Reward Tier' || row.label === 'Tier Level') {
              rowValue = row.value;
            } else if (typeof row.value === 'number') {
              rowValue = `${row.value.toFixed(2)}%`;
            }
            return (
              <tr>
                <td>
                  {row.label}
                </td>
                <td>
                  {rowValue}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  ));

  return (
    <Modal
      className={classes.modal}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={closeModal}
    >
      <Fade in={openModal}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid className={`${classes.paper} ${classes.paddedGrid}`}>
            <h2>Details of your fee reduction</h2>
            {tables}
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                type="button"
                size="medium"
                color="primary"
                className={classes.margin}
                onClick={closeModal}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

FeeDetailsModal.propTypes = {
  openModal: propTypes.bool.isRequired,
  closeModal: propTypes.func.isRequired,
  data: propTypes.arrayOf(propTypes.shape({})).isRequired,
};

export default FeeDetailsModal;
