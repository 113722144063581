import { useState, useEffect, useCallback } from 'react';
import { getUserNews } from '../../../shared/services/apiGateway';

function usePortfolioNews(userid = '') {
  const abortController = new AbortController();
  const [portfolioNews, setPortfolioNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  let ignore = false;

  const fetchPortfolioNews = useCallback(async ({
    user = userid,
    start = 0,
    count = 25,
  } = {}) => {
    try {
      setIsError(false);
      setIsLoading(true);
      const response = await getUserNews(user, start, count, abortController.signal);
      if (!ignore && response !== 'aborted' && response) {
        const sortedNews = response.news.sort((a, b) => new Date(b.date) - new Date(a.date));
        const news = sortedNews.map((item) => {
          const fundsArray = item.fund.split(',');
          return { ...item, fund: fundsArray };
        });
        setPortfolioNews(news);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [userid]);

  useEffect(() => {
    fetchPortfolioNews();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [userid]);

  return {
    portfolioNews, fetchPortfolioNews, isLoading, isError,
  };
}

export default usePortfolioNews;
