import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const ConnectIcon = ({ fontSize = 'medium', sx = {} }) => (
  <SvgIcon fontSize={fontSize} sx={sx} viewBox="0 0 15 12">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
      <g clipPath="url(#clip0_1020_2955)">
        {/* eslint-disable max-len */}
        <path d="M8.26723 8.25311L9.20873 9.19461C9.86646 8.5331 10.2377 7.69528 10.2947 6.85476C10.3228 6.43302 10.2709 6.00939 10.1321 5.60951C9.99363 5.21045 9.76602 4.83541 9.45173 4.52221L7.94404 3.01398L6.89886 1.9688L5.78023 0.850175C5.46622 0.536162 5.09146 0.308549 4.69266 0.170038C4.09272 -0.0386741 3.44066 -0.0508242 2.81777 0.109287C2.19514 0.269129 1.59681 0.603392 1.10001 1.09966C0.437961 1.76278 0.0645475 2.6033 0.00757698 3.44733C-0.0210433 3.86881 0.0310672 4.29271 0.169849 4.69204C0.30863 5.09138 0.536512 5.46614 0.850525 5.77961L4.52256 9.45165C4.83604 9.76512 5.2108 9.99301 5.61013 10.1315C6.21008 10.34 6.8616 10.3524 7.48449 10.1928C7.69402 10.1388 7.90057 10.0637 8.10172 9.97114L7.05654 8.92595C6.95907 8.94621 6.86187 8.95944 6.76601 8.96592C6.5114 8.98293 6.26705 8.95107 6.04754 8.87465C5.82803 8.7977 5.632 8.67782 5.46406 8.51069L1.79202 4.83811C1.62408 4.6699 1.5042 4.47442 1.42752 4.25437C1.31277 3.92524 1.29846 3.53886 1.39863 3.14898C1.49907 2.7599 1.7121 2.3711 2.04205 2.0417C2.48053 1.6024 3.02648 1.36966 3.53624 1.33591C3.79112 1.3189 4.03494 1.3513 4.25445 1.42771C4.4745 1.50466 4.66998 1.62454 4.83874 1.79167L5.79184 2.74505L6.73361 3.68655L8.51023 5.46371C8.67817 5.63165 8.79751 5.8274 8.87447 6.04746C8.98922 6.37632 9.00353 6.76242 8.90336 7.15258C8.80373 7.53922 8.59285 7.92506 8.26723 8.25284V8.25311Z" fill="#7B5599" />
        <path opacity="0.7" d="M6.73327 3.6873L5.7915 2.7458C5.13432 3.40731 4.76279 4.24512 4.70609 5.08564C4.67801 5.50739 4.72985 5.93102 4.8689 6.33089C5.00741 6.72996 5.23503 7.10499 5.54904 7.41847L7.057 8.92643L8.10218 9.97161L9.22081 11.0902C9.53428 11.4042 9.90904 11.6316 10.3084 11.7704C10.9083 11.9791 11.5598 11.9912 12.1827 11.8311C12.8059 11.671 13.4037 11.337 13.9005 10.8405C14.5625 10.1779 14.936 9.33683 14.9927 8.49335C15.0207 8.0716 14.9689 7.64824 14.8301 7.24809C14.6916 6.84903 14.4637 6.47427 14.15 6.16079L10.4785 2.4893C10.1645 2.17528 9.79024 1.94713 9.39091 1.80862C8.79096 1.60045 8.13918 1.58776 7.51628 1.74733C7.30703 1.80133 7.09994 1.87693 6.89905 1.96954L7.94423 3.01472C8.04143 2.99447 8.13891 2.9807 8.23476 2.97449C8.4891 2.95748 8.73318 2.98988 8.95323 3.06629C9.17275 3.14324 9.3685 3.26258 9.53698 3.43026L13.2085 7.10229C13.3764 7.2705 13.4963 7.46599 13.573 7.68604C13.6877 8.0149 13.702 8.40155 13.6013 8.79116C13.5014 9.18077 13.2881 9.56958 12.959 9.89871C12.52 10.3377 11.974 10.5705 11.4648 10.604C11.2099 10.621 10.9656 10.5891 10.7463 10.5127C10.5263 10.4357 10.3305 10.3164 10.162 10.1487L9.20866 9.19535L8.26716 8.25385L6.49054 6.4767C6.3226 6.30875 6.20326 6.113 6.12658 5.89295C6.01128 5.56409 5.99751 5.17798 6.09768 4.78783C6.19678 4.40119 6.40792 4.01508 6.73354 3.68757L6.73327 3.6873Z" fill="#7B5599" />
        {/* eslint-enable max-len */}
      </g>
      <defs>
        <clipPath id="clip0_1020_2955">
          <rect width="15" height="11.9395" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

ConnectIcon.propTypes = {
  fontSize: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // eslint-disable-line react/forbid-prop-types
};

ConnectIcon.defaultProps = {
  fontSize: 'medium',
  sx: {},
};

export default ConnectIcon;
