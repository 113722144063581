import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { MobileHeadCells } from '../PortfolioNewsHeadCells';
import MobileTableRow from '../../../common/MobileTableRow';
import { getCellBackgroundColor } from '../styles';
import { getCellContents, MobileNewsItemBox } from './shared';

const MobileManagingPartnerItem = ({ row }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <MobileNewsItemBox
      source={row.source}
      onClick={() => setIsExpanded(!isExpanded)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setIsExpanded(!isExpanded);
        }
      }}
      tabIndex="0"
    >
      <MobileTableRow
        contents={
          isExpanded
            ? <ExpandLessIcon fontSize="small" />
            : <ExpandMoreIcon fontSize="small" />
        }
      />
      {MobileHeadCells.map((headCell) => (
        // the source row needs custom styling to handle the purple background
        headCell.id === 'source' ? (
          <MobileTableRow
            key={headCell.id}
            title={(
              <Box sx={{ padding: '8px 17px' }}>
                {headCell.label}
              </Box>
            )}
            contents={getCellContents(headCell.id, row, isExpanded)}
            bold={headCell.bold}
            sx={{
              padding: 0,
            }}
            contentsSx={(theme) => ({
              mr: 2,
              backgroundColor: getCellBackgroundColor(row.source, isExpanded, theme),
              borderRadius: '0 2px 2px 0',
              color: isExpanded ? 'white' : '#191919',
              border: !isExpanded
                ? '0.5px solid white'
                : 'none',
              borderLeft: !isExpanded
                ? `3px solid ${theme.palette.secondary.main}`
                : 'none',
              transition: 'background-color 0.3s ease, color 0.3s ease',
            })}
          />
        ) : (
          <MobileTableRow
            key={headCell.id}
            title={headCell.label}
            contents={getCellContents(headCell.id, row, isExpanded)}
            direction={headCell.id === 'headline' ? 'column' : 'row'}
            bold={headCell.bold}
          />
        )
      ))}
    </MobileNewsItemBox>
  );
};

MobileManagingPartnerItem.propTypes = {
  row: PropTypes.object.isRequired,
};

export default MobileManagingPartnerItem;
