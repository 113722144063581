export const getFundLogoPath = (fundCode) => {
  if (!fundCode) {
    return '';
  }

  if (fundCode.startsWith('FF')) {
    // For all focused funds, remove the trailing numbers
    return fundCode.replace(/\d+$/, '');
  } if (fundCode.startsWith('TAF')) {
    // For all total access funds, return just TAF
    return 'TAF';
  }

  return fundCode;
};

export const getFundLogo = (fund) => {
  const fundLogo = (fund.company && fund.company.school_name)
    ? fund.company.company_code : getFundLogoPath(fund.fund_code);
  return `https://avg-wordpress-prod.s3.amazonaws.com/uploads/${fundLogo}.png`;
};

export const defaultImg = 'https://la-company-logos.s3.amazonaws.com/avg-avatar.jpg';

export const defaultFundImg = (ev) => {
  // eslint-disable-next-line no-param-reassign
  ev.target.src = defaultImg;
};
