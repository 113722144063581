import { actions } from '@alumni-ventures-group/react-shared';
import { matchPath } from 'react-router-dom';
import { getDashboardLabels, getFundFamilies } from './apiGateway';
import { initSession, AUTH_TYPE } from './authActions';

const { setApplicationInvestor } = actions.applicationActions;
const { requestAllCompanies, receiveAllCompanies, requestAllCompaniesError } = actions.getCompaniesActions;
const { setQueryString } = actions.queryStringActions;
const { setDashboardLabels } = actions.dashboardLabelActions;

const setAuthType = () => (window.location.pathname.indexOf('managed') !== -1 ? AUTH_TYPE.MANAGED : AUTH_TYPE.USER);

function fetchAllCompanies() {
  return (dispatch) => {
    dispatch(requestAllCompanies());

    return getFundFamilies().then((res) => {
      if (res && res.data) {
        dispatch(receiveAllCompanies(res.data));
      } else {
        dispatch(
          requestAllCompaniesError(res.message ? res.message : 'Unable to get company items'),
        );
      }
    });
  };
}

function shouldFetchAllCompanies(state) {
  const { companies } = state;
  if (!companies) {
    return true;
  }
  if (companies.isFetching) {
    return false;
  }
  return true;
}

export function fetchAllCompaniesIfNeeded() {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    if (shouldFetchAllCompanies(getState())) {
      return dispatch(fetchAllCompanies());
    }
  };
}

const redirectToLogin = (authType, query) => {
  let maintainedQuery = '';
  if (query.toString() !== '') {
    maintainedQuery = `?${query.toString()}`;
  }
  if (authType === AUTH_TYPE.MANAGED) {
    window.location = `${process.env.INVESTOR_PORTAL_PATH}/managed/login${maintainedQuery}`;
  } else {
    window.location = `${process.env.INVESTOR_PORTAL_PATH}/login${maintainedQuery}`;
  }
};

const processOpenQuery = (store, query) => {
  if (query.has('open')) {
    store.dispatch(setQueryString(query.toString()));
  }
};

const checkIfRedirectNeeded = (res, authType, query) => {
  const loginRoutes = ['/login', '/login/:id', '/sign-up', '/sign-up/:id', '/managed/login'];

  let isMatchLoginRoute = false;
  for (let i = 0; i < loginRoutes.length; i++) {
    const match = matchPath(window.location.pathname, { path: loginRoutes[i] });
    if (match) {
      isMatchLoginRoute = true;
      break;
    }
  }

  if ((!res || res.isLoggedIn === false) && !isMatchLoginRoute) {
    redirectToLogin(authType, query);
  }
};

const getLabelsAndProcess = async (store) => getDashboardLabels().then((labelsResponse) => {
  if (labelsResponse && labelsResponse.labels) {
    store.dispatch(setDashboardLabels(labelsResponse.labels));
    return labelsResponse;
  }
  return false;
});

const initializationService = async (store, that) => {
  const authType = setAuthType();
  const query = new URLSearchParams(window.location.search);

  store.dispatch(setApplicationInvestor());
  const sessionRes = await initSession(store.dispatch, authType);
  processOpenQuery(store, query);
  checkIfRedirectNeeded(sessionRes, authType, query);
  await getLabelsAndProcess(store);
  if (sessionRes.isLoggedIn) {
    store.dispatch(fetchAllCompaniesIfNeeded());
  }
  that.setState({
    loaded: true,
    loading: false,
  });
};

export default initializationService;
