import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { actions } from '@alumni-ventures-group/react-shared';
import useUserTokens from '../common/useUserTokens';
import { formatTokenAmount } from '../common/utils';
import {
  avgTokenIconUrl, errorMessage, openMoreInfo, toolTipText,
} from '../common/tokenHelper';
import checkApiErrorResponse from '../../../shared/services/checkApiErrorResponse';

const useStyles = makeStyles({
  toolTipText: {
    fontSize: '13px',
    padding: '5px 10px',
  },
  tokenAmount: {
    marginLeft: 5,
    marginTop: 0,
    minWidth: 30,
    fontWeight: 'bold',
  },
  pointerEvents: {
  },
});

const TopbarAVT = ({ dispatch }) => {
  const classes = useStyles();
  const returnedTokenAmount = useUserTokens();
  const [userTokenAmount, setUserTokenAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { showSnackbarWarning } = actions.snackbarActions;

  useEffect(() => {
    if (returnedTokenAmount) {
      setUserTokenAmount(returnedTokenAmount.tokensTotal);
      setIsLoading(false);
      if (checkApiErrorResponse(returnedTokenAmount)) {
        dispatch(showSnackbarWarning(errorMessage));
      }
    }
  }, [returnedTokenAmount]);

  return (
    <Tooltip
      arrow
      interactive
      placement="bottom"
      title={toolTipText(userTokenAmount, classes)}
      leaveDelay={3000}
    >
      <button
        className="topbar__nav-link"
        onClick={openMoreInfo}
        onKeyPress={openMoreInfo}
        style={{ backgroundColor: 'white', borderWidth: 0 }}
        tabIndex={0}
        type="button"
      >
        <img
          src={avgTokenIconUrl}
          style={{ height: 40, width: 40 }}
          alt="Token Icon"
        />
        <p className={classes.tokenAmount}>
          {isLoading ? (<Skeleton variant="text" width={33} />) : formatTokenAmount(userTokenAmount)}
        </p>
      </button>
    </Tooltip>
  );
};

TopbarAVT.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dispatch: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
});

export default withRouter((connect(mapStateToProps)(TopbarAVT)));
