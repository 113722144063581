import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Card, CardBody,
} from 'reactstrap';
import { MaterialTable } from '@alumni-ventures-group/react-shared/lib/components';
import { uid } from 'react-uid';
import sortByNumberValue from '../../helpers/sortByNumberValue';

const columnTitlesReports = [
  {
    alignment: 'left',
    id: 'fundName',
    label: 'Fund Name',
  },
  {
    alignment: 'left',
    id: 'statementDate',
    label: 'Statement Date',
  },
  {
    alignment: 'left',
    id: 'download',
    label: 'File',
    insertRaw: true,
  },
];

const returnTaxesDataObject = (taxes) => taxes.map((tax) => ({
  fundName: tax.fund_name,
  statementDate: tax.statement_date,
  download: tax.download_tax,
}));

const TaxTable = ({ taxes }) => (
  <Row key={uid('id')}>
    <Col>
      <Card>
        <CardBody
          className="pb-1"
          style={{
            // boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
            padding: '8px, 10px',
          }}
        >
          <Row>
            <Col>
              <h4>Tax Statements</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <MaterialTable
                columns={columnTitlesReports}
                dataArray={returnTaxesDataObject(taxes)}
                preSort={{ field: 'statementDate', order: 'desc' }}
                edit={null}
                rowSize={25}
                selectable={false}
                showToolbar={false}
                wrapHeaders
                customSort={sortByNumberValue}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

TaxTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  taxes: PropTypes.any.isRequired,
};

export default TaxTable;
