import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { Alert, AlertTitle } from '@material-ui/lab';

const StatementAlert = ({ title, body }) => (
  <Row className="px-3 py-2 mt-3">
    <Alert severity="info" style={{ width: '100%' }}>
      {title && (
        <AlertTitle>
          {title}
        </AlertTitle>
      )}
      {body}
    </Alert>
  </Row>
);

StatementAlert.propTypes = {
  title: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  body: PropTypes.any.isRequired,
};

StatementAlert.defaultProps = {
  title: '',
};

export default StatementAlert;
