/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Snackbar } from '@alumni-ventures-group/react-shared/lib/components';
import { themeActions, snackbarActions, sidebarActions } from '@alumni-ventures-group/react-shared/lib/actions';
import { propTypes } from '@alumni-ventures-group/react-shared';
// import Topbar from './topbar/Topbar';
import TopbarInvestor from './topbar_investor/TopbarInvestor';
import TopbarManaged from './topbarManaged/TopbarManaged';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
// import Sidebar from './sidebar/Sidebar';
import SidebarMobile from './topbar_with_navigation/sidebar_mobile/SidebarMobile';
import AdminBanner from './admin/AdminBanner';

const { SidebarProps } = propTypes;
const { hideSnackbar } = snackbarActions;
const { changeThemeToDark, changeThemeToLight } = themeActions;
const { changeMobileSidebarVisibility, changeSidebarVisibility } = sidebarActions;
class Layout extends Component {
  changeSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };

  hideSnackBar = () => {
    const { dispatch } = this.props;
    dispatch(hideSnackbar());
  };

  render() {
    const {
      theme,
      auth,
      application,
      sidebar,
      snackbar,
    } = this.props;

    const classes = {
      layout: true,
      'layout--collapse': sidebar.collapse,
      'layout--top-navigation': application.topNavigation,
    };

    classes[theme.className] = true;

    const layoutClass = classNames(classes);
    let adminComp = '';
    let topbarComp = '';
    let sidebarComp = '';
    let snackBarInfo = '';

    if (auth && auth.user && auth.user.adminEmail) {
      adminComp = (
        <AdminBanner adminEmail={auth.user.adminEmail} />
      );
    }

    if (snackbar.isShow && snackbar.message) {
      snackBarInfo = (
        <Snackbar type={snackbar.designType} autoClose={snackbar.autoClose} hideSnackbar={this.hideSnackBar}>
          {snackbar.message}
        </Snackbar>
      );
    }

    if (application.topNavigation && auth.authType === 'USER') {
      topbarComp = <TopbarInvestor changeMobileSidebarVisibility={this.changeMobileSidebarVisibility} />;
      sidebarComp = (
        <SidebarMobile
          sidebar={sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      );
    } else if (auth.authType === 'MANAGED') {
      topbarComp = <TopbarManaged changeMobileSidebarVisibility={this.changeMobileSidebarVisibility} />;
      sidebarComp = (
        <SidebarMobile
          sidebar={sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      );
    } else if (auth.isLoggedIn && application.topNavigation) {
      topbarComp = <TopbarWithNavigation changeMobileSidebarVisibility={this.changeMobileSidebarVisibility} />;
      sidebarComp = (
        <SidebarMobile
          sidebar={sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      );
    }
    // else if (auth.isLoggedIn && !application.topNavigation) {
    //   topbarComp = (
    //     <Topbar
    //       changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
    //       changeSidebarVisibility={this.changeSidebarVisibility}
    //     />
    //   );
    //   sidebarComp = (
    //     <Sidebar
    //       sidebar={sidebar}
    //       changeToDark={this.changeToDark}
    //       changeToLight={this.changeToLight}
    //       changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
    //     />
    //   );
    // }

    return (
      <>
        <div className={layoutClass}>
          {adminComp}
          {topbarComp}
          {sidebarComp}
          {snackBarInfo}
        </div>
      </>
    );
  }
}

Layout.propTypes = {
  sidebar: SidebarProps.isRequired,
  dispatch: PropTypes.func.isRequired,
  theme: PropTypes.any.isRequired,
  auth: PropTypes.any.isRequired,
  application: PropTypes.object.isRequired,
  snackbar: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  auth: state.auth,
  sidebar: state.sidebar,
  application: state.application,
  snackbar: state.snackbar,
});

export default withRouter(connect(mapStateToProps)(Layout));
