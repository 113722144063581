/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import MD5 from 'md5.js';
import { connect } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ava from 'url:../../../images/ava.png';
import TopbarMenuLink from './TopbarMenuLink';
import ContactModal from '../../Investor/common/ContactModal';
import { doLogOut } from '../../../shared/services/authActions';
import TopbarAVT from './TopbarAVT';
import SidebarAVT from './SidebarAVT';

const TopbarProfile = ({ user, logOut }) => {
  const [collapse, setCollapse] = useState(false);
  const [username, setUsername] = useState('');
  const [avatar, setAvatar] = useState(ava);
  const [contactModal, setContactModal] = useState(false);

  const setUser = (updatedUser) => {
    const gravatarHash = updatedUser.email1
      ? new MD5().update(updatedUser.email1.trim().toLowerCase()).digest('hex') : null;
    const updatedAvatar = updatedUser.avatar ? updatedUser.avatar
      : `https://www.gravatar.com/avatar/${gravatarHash}?s=100&d=${encodeURI(
      // eslint-disable-next-line max-len
        'https://la-company-logos.s3.amazonaws.com/avg-avatar.jpg',
      )}`;
    setUsername((
      `${updatedUser.first_name ? updatedUser.first_name
        : updatedUser.firstName} ${updatedUser.last_name ? updatedUser.last_name : updatedUser.lastName}`
    ).toUpperCase());
    setAvatar(updatedAvatar);
  };

  useEffect(() => {
    if (user.id) {
      setUser(user);
    }
  }, [user]);

  // const matches = useMediaQuery('(max-width: 991px)');

  return (
    <>
      {/* {!matches && <TopbarAVT />} */}
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={() => { setCollapse(!collapse); }}>
          <img className="topbar__avatar-img" src={avatar} alt="avatar" />
          <p className="topbar__avatar-name">{username}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={() => { setCollapse(!collapse); }} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* {matches && <SidebarAVT />} */}
            <TopbarMenuLink
              title="Settings"
              icon="list"
              onClick={ () => window.location.replace(`${process.env.APP_AV_VC_PATH}/account/settings`)}
            />
            <TopbarMenuLink
              title="Contact Us"
              icon="bullhorn"
              onClick={() => { setContactModal(!contactModal); }}
            />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink
              title="Log Out"
              icon="exit"
              onClick={async () => {
                await logOut();
                window.location.replace('/login');
              }}
            />
          </div>
        </Collapse>
        <ContactModal
          openModal={contactModal}
          closeModal={() => { setContactModal(!contactModal); }}
        />
      </div>
    </>
  );
};

TopbarProfile.propTypes = {
  logOut: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  logOut: async () => {
    await doLogOut(dispatch);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopbarProfile);
