import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Stack, Typography, Link, Divider,
} from '@mui/material';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import ConnectIcon from '../../common/ConnectIcon';

const ManagingPartnerAuthorCard = ({ author, index = 0, isMobile = false }) => (
  <Box>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: isMobile ? '90px minmax(auto, 232px)' : '102px 163px',
      }}
    >
      <Box
        component="img"
        src={author.picture}
        alt={author.mpName}
        sx={{ minHeight: '107px', objectFit: 'cover' }}
      />
      <Box
        sx={{
          padding: '16px 16px 8px 16px',
          backgroundColor: index % 2 ? 'table.main' : 'table.light',
        }}
      >
        <Stack direction="column">
          <Typography variant="subtext" sx={{ '&&': { color: 'lightGrey.dark' } }}>
            Author
          </Typography>
          <Typography variant="body2Inter" sx={{ mb: 1 }}>
            {author.mpName}
          </Typography>
          <Stack
            direction={isMobile ? 'row' : 'column'}
            spacing={isMobile ? 1 : 0}
            divider={isMobile ? <Divider orientation="vertical" flexItem sx={{ borderColor: '#191919' }} /> : null}
          >
            {/* <Link
              onClick={(e) => e.stopPropagation()}
              href={author.calendly}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ fontSize: 13, letterSpacing: -0.39, fontWeight: 600 }}
            >
              {!isMobile && <AddIcCallIcon fontSize="small" sx={{ mr: 0.5 }} />}Book A Call
            </Link> */}
            <Link
              onClick={(e) => e.stopPropagation()}
              href={author.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ fontSize: 13, letterSpacing: -0.39, fontWeight: 600 }}
            >
              {!isMobile && <ConnectIcon fontSize="small" sx={{ mr: 0.5 }} />}Connect with me
            </Link>
          </Stack>
        </Stack>
      </Box>
    </Box>
  </Box>
);

ManagingPartnerAuthorCard.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mpName: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    calendly: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number,
  isMobile: PropTypes.bool,
};

ManagingPartnerAuthorCard.defaultProps = {
  index: 0,
  isMobile: false,
};

export default ManagingPartnerAuthorCard;
