import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, Box, Stack, Divider, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PortfolioNewsHeadCells from '../PortfolioNewsHeadCells';
import StyledTableRow from './shared';
import PortfolioNewsTableCell, { StyledTableCell } from './PortfolioNewsTableCell';
import ManagingPartnerAuthorCard from '../ManagingPartnerAuthorCard';

const PortfolioNewsTableRow = ({ row, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <StyledTableRow
        hover
        index={index}
        source={row.source}
        onClick={() => setIsExpanded(!isExpanded)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsExpanded(!isExpanded);
          }
        }}
        sx={{
          cursor: 'pointer',
          borderBottom: isExpanded ? 'unset' : 'inherit',
          '&& > *': { borderBottom: isExpanded ? 'unset' : 'inherit' },
        }}
      >
        {PortfolioNewsHeadCells.map((headCell) => (
          headCell.id === 'expand' ? (
            <StyledTableCell
              key={headCell.id}
              tabIndex="0"
            >
              {isExpanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
            </StyledTableCell>
          ) : (
            <PortfolioNewsTableCell
              key={headCell.id}
              headCell={headCell}
              row={row}
              isExpanded={isExpanded}
            />
          )
        ))}
      </StyledTableRow>
      <StyledTableRow
        index={index}
        source={row.source}
        sx={{ borderTop: 'unset', my: 2 }}
      >
        <StyledTableCell colSpan={12} sx={{ pb: 0, pt: 0, cursor: 'auto' }}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box sx={{ my: 4.5, mx: 1 }}>
              <Stack
                direction="row"
                divider={
                  <Divider orientation="vertical" flexItem sx={{ borderColor: '#191919' }} />
                }
                spacing={3}
              >
                {row.author && <ManagingPartnerAuthorCard author={row.author} index={index} />}
                <Typography
                  variant="body2Inter"
                  dangerouslySetInnerHTML={{
                    __html: row.content,
                  }}
                />
              </Stack>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

PortfolioNewsTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default PortfolioNewsTableRow;
