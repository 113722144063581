import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Card, CardContent, Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Use new GridV2
import { Loader } from '@alumni-ventures-group/react-shared/lib/components';

const AmountCard = ({
  label,
  amount,
  paletteColor,
  isLoading,
  toolTip,
}) => (
  <Card
    sx={{
      backgroundColor: `${paletteColor}.main`,
    }}
  >
    <CardContent
      sx={{
        p: { xs: 1, sm: 1.5, md: 3 },
        '&:last-child': {
          pb: { xs: 1, sm: 1.5, md: 3 },
        },
      }}
    >
      <Grid
        container
        direction={{ xs: 'row', sm: 'row', md: 'column' }}
        wrap="nowrap"
        alignItems="center"
        justifyContent="center"
        spacing={1.5}
      >
        <Grid
          xs={5}
          sm={6}
          md={12}
        >
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5" align="center" sx={{ maxWidth: '20ch' }}>
              {label}
            </Typography>
            {toolTip}
          </Grid>

        </Grid>
        <Grid
          xs={7}
          sm={6}
          md={12}
          sx={{ textAlign: 'center', mt: 0 }}
        >
          {isLoading ? (
            <Box sx={{ pt: { xs: 0, sm: 0, md: 1.5 }, height: { xs: 'auto', sm: 'auto', md: 40 } }}>
              <Loader />
            </Box>
          ) : (
            <Typography
              variant="dollarAmount"
              align="center"
              bgcolor={`${paletteColor}.dark`}
              px={1.5}
            >
              {amount || '$0.00'}
            </Typography>
          )}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

AmountCard.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.string,
  paletteColor: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  toolTip: PropTypes.element,
};

AmountCard.defaultProps = {
  amount: undefined,
  isLoading: false,
  toolTip: undefined,
};

export default AmountCard;
