import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, TextField, Grid, InputAdornment,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VisibilityIcon from 'mdi-react/VisibilityIcon';
import VisibilityOffIcon from 'mdi-react/VisibilityOffIcon';
import { Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { ClubResources } from '@alumni-ventures-group/react-shared/lib/services';
import verifyRecaptchaSubmit from '../../containers/Investor/common/Recaptcha';

const { greyColor } = ClubResources;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
  textField: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  margin: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  eye: {
    cursor: 'pointer',
    color: greyColor,
  },
  errorMessage: {
    color: '#ac2929 !important',
    fontFamily: 'inherit',
    fontSize: '13px',
  },
  recaptchaDiv: {
    paddingTop: '15px',
  },
  preLoginAction: {
    color: '#7B569B',
  },
}));

const LoginForm = ({
  handleFormSubmit,
  loginError,
  existingEmail,
  switchScreen,
  loading,
  showCreateAccount = true,
}) => {
  const [passwordHide, setPasswordHide] = useState(true);
  const {
    register, handleSubmit, errors,
  } = useForm();
  const classes = useStyles();
  return (
    <Form
      className={classes.root}
      onSubmit={handleSubmit(handleFormSubmit)}
      id="login-form"
    >
      <TextField
        id="login-email"
        label="Email"
        name="email"
        fullWidth
        margin="normal"
        variant="outlined"
        defaultValue={existingEmail}
        color="secondary"
        error={loginError}
        autoFocus
        inputRef={register({ required: true })}
      />
      {errors.email && <p className={classes.errorMessage}>Email is required.</p>}
      <TextField
        id="login-password"
        inputRef={register({ required: true })}
        label="Password"
        name="password"
        fullWidth
        margin="normal"
        variant="outlined"
        color="secondary"
        type={passwordHide ? 'password' : 'text'}
        error={loginError}
        helperText={loginError ? 'Email or Password is Incorrect' : ''}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {passwordHide ? (
                <VisibilityIcon
                  className={classes.eye}
                  onClick={() => { setPasswordHide(!passwordHide); }}
                />
              ) : (
                <VisibilityOffIcon
                  className={classes.eye}
                  onClick={() => { setPasswordHide(!passwordHide); }}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      {errors.password && <p className={classes.errorMessage}>Password is required.</p>}
      {switchScreen && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            type="button"
            size="small"
            color="secondary"
            onClick={() => { switchScreen(true); }}
            value="forgotPassword"
            tabIndex="-1"
            className={classes.preLoginAction}
          >
            Forgot Password?
          </Button>
        </Grid>
      )}
      <div className={classes.recaptchaDiv}>
        <GoogleReCaptchaProvider reCaptchaKey="6LeBHvkaAAAAALQFJyvosur9393ASMGAMEmxYYTp">
          <GoogleReCaptcha onVerify={verifyRecaptchaSubmit} />
        </GoogleReCaptchaProvider>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <LoadingButton
          variant="contained"
          type="submit"
          size="large"
          color="secondary"
          className={classes.margin}
          loading={loading}
          value="submit"
        >
          Log In
        </LoadingButton>
      </Grid>
      <Grid
        className="mt-3"
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {showCreateAccount && (
          <a href={`${process.env.AV_VC_PATH}/signup`}>
            <Button
              type="button"
              size="small"
              color="primary"
              className={classes.preLoginAction}
            >
              Create an Account
            </Button>
          </a>
        )}
      </Grid>
    </Form>
  );
};

LoginForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  loginError: PropTypes.bool,
  existingEmail: PropTypes.string.isRequired,
  switchScreen: PropTypes.func,
  loading: PropTypes.func.isRequired,
  showCreateAccount: PropTypes.bool,
};

LoginForm.defaultProps = {
  loginError: false,
  switchScreen: null,
  showCreateAccount: true,
};

export default LoginForm;
