import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Card, CardBody,
} from 'reactstrap';
import { MaterialTable } from '@alumni-ventures-group/react-shared/lib/components';
import { uid } from 'react-uid';
import sortByNumberValue from '../../helpers/sortByNumberValue';

const columnTitlesReports = [
  {
    alignment: 'left',
    id: 'reportName',
    label: 'Memo Name',
  },
  {
    alignment: 'left',
    id: 'publication_date',
    label: 'Publication Date',
  },
  {
    alignment: 'left',
    id: 'download',
    label: 'File',
    insertRaw: true,
  },
];

const returnExitsDataObject = (exits) => exits.map((exit) => ({
  reportName: exit.file_display_name,
  fundsList: exit.funds.map((funds) => funds).join(', '),
  publication_date: exit.publication_date,
  // eslint-disable-next-line max-len
  download: exit.file_url,
}));

const ExitTable = ({ exits }) => (
  <Row key={uid('id')}>
    <Col>
      <Card>
        <CardBody
          className="pb-1"
          style={{
            // boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
            padding: '8px, 10px',
          }}
        >
          <Row>
            <Col>
              <h4>Investment Memos</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <MaterialTable
                columns={columnTitlesReports}
                dataArray={returnExitsDataObject(exits)}
                preSort={{ field: 'publication_date', order: 'desc' }}
                edit={null}
                rowSize={25}
                selectable={false}
                showToolbar={false}
                wrapHeaders
                customSort={sortByNumberValue}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

ExitTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  exits: PropTypes.any.isRequired,
};

export default ExitTable;
