import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Notification from 'rc-notification';
import { FullWideNotification } from '@alumni-ventures-group/react-shared/lib/components';
import { actions, propTypes } from '@alumni-ventures-group/react-shared';
import { doSignIn } from '../../shared/services/authActions';
import LogIn from '../LogIn';

const { ThemeProps } = propTypes;
const { resetErrorMessage } = actions.errorActions;

let notification = null;
// eslint-disable-next-line no-return-assign
Notification.newInstance({ style: { top: 65 } }, (n) => notification = n);
class PrivateWrapper extends PureComponent {
  constructor() {
    super();
    this.renderErrorMessage = this.renderErrorMessage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { errorMessage } = this.props;
    const oldError = prevProps.errorMessage;
    if (oldError !== errorMessage) {
      this.renderErrorMessage();
    }
  }

  handleDismissClick = (e) => {
    resetErrorMessage();
    e.preventDefault();
  }

  renderErrorMessage() {
    const { errorMessage } = this.props;
    if (!errorMessage) {
      return null;
    }
    notification.notice({
      content: <FullWideNotification color="warning" title="Note to All Investors:" message={errorMessage} />,
      duration: null,
      closable: true,
      style: { top: 0, left: 0 },
      className: 'full',
    });
    resetErrorMessage();
    return null;
  }

  render() {
    const {
      theme, auth, children, application,
    } = this.props;
    // const { sidebar } = this.props;
    const wrapperClass = classNames({
      wrapper: true,
      'top-navigation': application.topNavigation,
    });

    // const signUpWrapper = classNames({
    //   wrapper: true, 'top-navigation': true,
    // });

    const showLogin = () => {
      if (auth.isLoggedIn) {
        return (<div className={wrapperClass}>{children}</div>);
      }
      if (auth.authType === 'USER' && auth.isLoggedIn) {
        return (
          <div className={wrapperClass}>{children}</div>
        );
      }
      if (auth.authType === 'USER' && !auth.isLoggedIn) {
        return (
          <div className="wrapper top-navigation">
            {children}
          </div>
        );
      }
      if (auth.authType === 'MANAGED' && auth.isLoggedIn) {
        return (
          <div className={wrapperClass}>{children}</div>
        );
      }
      if (auth.authType === 'MANAGED' && !auth.isLoggedIn) {
        return (
          <div className="wrapper top-navigation">
            {children}
          </div>
        );
      }
      return (<div className="wrapper"><LogIn /></div>);
    };

    return (
      <div className={theme.className}>
        {showLogin()}
      </div>
    );
  }
}

PrivateWrapper.propTypes = {
  auth: ThemeProps.isRequired,
  theme: ThemeProps.isRequired,
  children: PropTypes.element.isRequired,
  errorMessage: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  application: PropTypes.object.isRequired,
};

PrivateWrapper.defaultProps = {
  errorMessage: null,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  auth: state.auth,
  sidebar: state.sidebar,
  application: state.application,
  errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  doSignIn: (credentials) => { doSignIn(dispatch, credentials); },
  resetErrorMessage: () => dispatch(resetErrorMessage()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateWrapper));
