import React, { useState } from 'react';
import {
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export default function TotalDistributionTooltip() {
  // need to control the open state, because the default mobile functionality is to require
  // a long press to show the tooltip which is not intuitive
  const [open, setOpen] = useState(false);
  return (
    <Tooltip
      title={(
        <>
          <Typography variant="tooltip">
            Please note:
            <ul className={{ listStyleType: 'none' }}>
              <li>Distribution information prior to 2021 is not displayed in the All Distribution Table.</li>
              <li>Only Distribution information starting from January 1 2022 is displayed.</li>
              <li>For any question please contact us.</li>
            </ul>
          </Typography>
        </>
      )}
      placement="top-start"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <IconButton size="small" sx={{ ml: 0.5 }} onClick={() => setOpen(true)}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
}
