import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import navigateToResetPasswordHandler from '../../../Investor/common/navigateToResetPasswordHandler';
import { loginManagedUser } from '../../../../shared/services/apiGateway';
import LoginForm from '../../../../shared/components/LoginForm';

const navigatingToResetPassword = () => navigateToResetPasswordHandler();

const LoginCard = ({
  dispatch, existingEmail, switchScreen, showCreateAccount,
}) => {
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);

  if (navigatingToResetPassword()) {
    switchScreen(true);
  }

  const handleFormSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await loginManagedUser(data);
      // eslint-disable-next-line eqeqeq
      if (res === false || res.success == false) {
        setLoginError(true);
      } else {
        res.user.users = res.user.managedUsers.map((usr) => ({
          ...usr, firstName: usr.firstName, lastName: usr.lastName, id: usr.id,
        }));
        dispatch({ user: res.user, authCode: res.authToken, type: 'INIT_SESSION_SUCCESS' });
        setLoginError(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setLoginError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="card--not-full-height">
      <CardBody>
        <Row className="w-100">
          <Col>
            <h3>
              <b style={{ color: 'black' }}>
                Authorized Account Login
              </b>
            </h3>
            <h5>
              Login to Alumni Ventures
            </h5>
          </Col>
        </Row>
        <hr />
        <LoginForm
          handleFormSubmit={handleFormSubmit}
          loginError={loginError}
          existingEmail={existingEmail}
          switchScreen={switchScreen}
          loading={loading}
          showCreateAccount={showCreateAccount}
        />
      </CardBody>
    </Card>
  );
};

LoginCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  existingEmail: PropTypes.string,
  switchScreen: PropTypes.func,
};

LoginCard.defaultProps = {
  existingEmail: '',
  switchScreen: null,
};

const mapStateToProps = (state) => ({
  dispatch: state.auth,
});

export default withRouter(connect(mapStateToProps)(LoginCard));
