import React from 'react';
import PropTypes from 'prop-types';
import PortfolioNewsHeadCells from '../PortfolioNewsHeadCells';
import StyledTableRow from './shared';
import PortfolioNewsTableCell from './PortfolioNewsTableCell';

const PortfolioNewsTableRow = ({ row, index }) => (
  <StyledTableRow
    hover
    index={index}
    source={row.source}
    onClick={() => window.open(row.url, '_blank', 'noopener, noreferrer')}
    tabIndex="-1"
  >
    {PortfolioNewsHeadCells.map((headCell) => (
      <PortfolioNewsTableCell
        key={headCell.id}
        headCell={headCell}
        row={row}
        isExpanded={false}
      />
    ))}
  </StyledTableRow>
);

PortfolioNewsTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default PortfolioNewsTableRow;
