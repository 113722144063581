import React from 'react';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

const ContactModalForm = ({
  // eslint-disable-next-line react/prop-types
  bindFirstName, bindLastName, bindEmail, bindPhone, bindMessage,
}) => (
  <>
    <TextField
      id="contact-first-name"
      label="First Name"
      fullWidth
      margin="normal"
      variant="outlined"
      color="secondary"
      {...bindFirstName}
    />
    <TextField
      id="contact-last-name"
      label="Last Name"
      fullWidth
      margin="normal"
      variant="outlined"
      color="secondary"
      {...bindLastName}
    />
    <TextField
      id="contact-email"
      label="Email"
      fullWidth
      margin="normal"
      variant="outlined"
      color="secondary"
      {...bindEmail}
    />
    <InputMask mask="999-999-9999" {...bindPhone}>
      {inputProps => (
        <TextField
          id="contact-phone"
          label="Phone"
          fullWidth
          margin="normal"
          variant="outlined"
          color="secondary"
          {...inputProps}
        />
      )}
    </InputMask>
    <TextField
      className="mb-4"
      id="contact-message"
      label="Enter your message to our team."
      fullWidth
      multiline
      margin="normal"
      variant="outlined"
      color="secondary"
      rows={8}
      {...bindMessage}
    />
  </>
);

export default ContactModalForm;
