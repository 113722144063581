/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Container, Box, Typography,
  Divider, Stack, Drawer, Button,
  ToggleButtonGroup, Select, MenuItem, FormControl, InputLabel,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Use new GridV2
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Helmet } from 'react-helmet';
import { useInViewport } from 'react-in-viewport';
import { Loader } from '@alumni-ventures-group/react-shared/lib/components';
import Toggle from '../common/Toggle';
import AmountCard from './components/AmountCard';
import ReinvestCard from './components/ReinvestCard';
import ReinvestDialog from './components/ReinvestDialog';
import FundFilter from './components/FundFilter';
import DistributionsDataDisplay from './components/DistributionsDataDisplay';
import { getComparator, sortAlphanumerically, sortByDate } from '../../../shared/hooks/useSort';
import useUserDistributions from '../common/useUserDistributions';
import TotalDistributionTooltip from './components/TotalDistributionTooltip';
import Search from '../common/Search';

// const currentDistributionsSubtitle = `* Distributions for funds that total less than $250 will
// be held by AV Until either a) your total distribution for that fund exceeds $250 or
// b) November, which is the last distribution of each calendar year, at which point
// all fund distributions will be processed no matter the size of the check.`;

// eslint-disable-next-line max-len
const currentDistributionsSubtitle = 'This page is updated 1x per quarter, ~25 days following a quarter close. AV will hold distributions until the total amount exceeds $250, or until December at which point we will pay out any remaining unpaid distributions, no matter the amount.';

const Distribution = () => {
  // TODO: Remove managed user check
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  const [showCurrentQuarterDistributions, setShowCurrentQuarterDistributions] = useState(true);
  const [searchOptions, setSearchOptions] = useState([]);
  const [sortBy, setSortBy] = useState('datePaid-desc');
  const [displayedData, setDisplayedData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [displayedQuarterData, setDisplayedQuarterData] = useState([]);
  const [fundFilter, setFundFilter] = useState('all');
  const [reinvestDialogOpen, setReinvestDialogOpen] = useState(false);
  const mobileReinvestCard = useRef();

  const {
    userDistributions,
    isLoading,
    isError,
  } = useUserDistributions(currentManagedUser ? `?userid=${currentManagedUser}` : '');

  const { inViewport: reinvestCardInViewport } = useInViewport(mobileReinvestCard);

  const quarterDistributionsExist = !!(
    userDistributions.currentQuarterTotalDistributions
    && userDistributions.currentQuarterDistributions
    && userDistributions.currentQuarterDistributions.length
  );
  const allDistributionsExist = !!(
    userDistributions.lifeTimeDistribution
    && userDistributions.allDistributions
    && userDistributions.allDistributions.length
  );

  /**
   * Adds the year dividers into the array of distributions data, if the user is currently
   * sorting the data by date. Also allows for a forced override to add the dividers. This
   * is used on the sort change handler, because the sortBy value is batched with the setDisplayedData
   * change so we can't reliably check it within this method
   */
  const addDividersIfSortingByDate = (newData, forceDividers = false) => {
    if (sortBy.includes('date') || forceDividers) {
      let previousYear;
      return newData.reduce((updatedData, distribution) => {
        const currentYear = distribution.datePaid ? new Date(distribution.datePaid).getFullYear() : null;
        if (currentYear !== previousYear) {
          // Show current year as divider before that years data
          // Add 'Pending' divider in between Paid and Pending data
          updatedData.push({
            divider: currentYear || 'Pending',
          });
        }
        previousYear = currentYear;
        updatedData.push(distribution);
        return updatedData;
      }, []);
    }
    return newData;
  };

  /**
   * Handles a change in the sort value.
   * Also saves a copy of all of the distributions data sorted by that value in the sortedData state, this can be used
   * by the filter function to reset the data back to its full list
   */
  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
    const [orderBy, order] = event.target.value.split('-');
    if (orderBy === 'datePaid') {
      const allDistributionsSorted = userDistributions.allDistributions.sort(getComparator(order, orderBy, sortByDate));
      setSortedData(allDistributionsSorted);
      setDisplayedData((prev) => {
        const sorted = [...prev].filter((distribution) => !distribution.divider)
          .sort(getComparator(order, orderBy, sortByDate));
        return addDividersIfSortingByDate(sorted, true);
      });
    } else {
      const allDistributionsSorted = userDistributions.allDistributions.sort(
        getComparator(order, orderBy, sortAlphanumerically),
      );
      setSortedData(allDistributionsSorted);
      setDisplayedData((prev) => [...prev].filter((distribution) => !distribution.divider)
        .sort(getComparator(order, orderBy, sortAlphanumerically)));
    }
  };

  useEffect(() => {
    if (!userDistributions.allDistributions) {
      return;
    }
    // pull unique funds and companies from all distributions to create search options
    const funds = new Set();
    const companies = new Set();
    userDistributions.allDistributions.forEach((distribution) => {
      funds.add(distribution.fundName);
      companies.add(distribution.companyName);
    });
    const options = [];
    Array.from(funds).sort(sortAlphanumerically)
      .forEach((fund) => options.push({
        type: 'Fund',
        field: 'fundName',
        label: fund,
      }));
    Array.from(companies).sort(sortAlphanumerically)
      .forEach((company) => options.push({
        type: 'Company',
        field: 'companyName',
        label: company,
      }));
    setSearchOptions(options);

    // Check the sort direction and run an initial sort of the newly loaded/changed distributions data
    // Then update the displayed data with this newly sorted data
    const [orderBy, order] = sortBy.split('-');
    if (orderBy === 'datePaid') {
      const allDistributionsSorted = userDistributions.allDistributions.sort(getComparator(order, orderBy, sortByDate));
      setSortedData(allDistributionsSorted);
      setDisplayedData(addDividersIfSortingByDate(allDistributionsSorted, true));
    } else {
      const allDistributionsSorted = userDistributions.allDistributions.sort(
        getComparator(order, orderBy, sortAlphanumerically),
      );
      setSortedData(allDistributionsSorted);
      setDisplayedData(allDistributionsSorted);
    }
  }, [userDistributions.allDistributions]);

  const onSearchClear = () => {
    setDisplayedData(addDividersIfSortingByDate(sortedData));
  };

  const onSearch = (filtered) => {
    setDisplayedData(addDividersIfSortingByDate(filtered));
  };

  // filter current quarter distributions by fund and set displayed quarter data
  useEffect(() => {
    if (!userDistributions.currentQuarterDistributions) {
      setDisplayedQuarterData([]);
      return;
    }
    if (!fundFilter || !showCurrentQuarterDistributions || fundFilter === 'all') {
      setDisplayedQuarterData(userDistributions.currentQuarterDistributions);
    } else {
      setDisplayedQuarterData(userDistributions.currentQuarterDistributions.filter((distribution) => (
        distribution.fundName === fundFilter
      )));
    }
  }, [showCurrentQuarterDistributions, fundFilter, userDistributions.currentQuarterDistributions]);

  return (
    <>
      <Helmet>
        <title>Distributions - Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <Container maxWidth="xl">
        <Box pt={4}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            alignItems="center"
            justifyContent={{ xs: 'center', sm: 'start' }}
          >
            <Typography variant="h1">Distributions</Typography>
            <Toggle
              value={showCurrentQuarterDistributions}
              setValue={setShowCurrentQuarterDistributions}
              toggleOptions={['Current Distributions', 'All Distributions']}
            />
          </Stack>
          <Divider variant="fullWidth" sx={{ my: 2, display: { xs: 'none', sm: 'block' } }} />
          <Grid
            container
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            spacing={3}
            justifyContent={{ xs: 'center', sm: 'center', md: 'start' }}
            alignItems={{ xs: 'center', sm: 'center', md: 'start' }}
            pt={{ xs: 2, sm: 1 }}
          >
            <Grid
              container
              direction="column"
              md="auto"
              rowSpacing={{ xs: 1, sm: 1, md: 2 }}
              columnSpacing={0}
            >
              {(!isError && showCurrentQuarterDistributions) && (
                <Grid>
                  <AmountCard
                    label="TOTAL QUARTER DISTRIBUTIONS"
                    amount={userDistributions.currentQuarterTotalDistributions}
                    paletteColor="lightGreen"
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {(!isError && !showCurrentQuarterDistributions) && (
                <Grid>
                  <AmountCard
                    label="TOTAL DISTRIBUTIONS PRIOR TO 2022"
                    amount={userDistributions.distributionTotalBefore2022}
                    paletteColor="lightGreen"
                    isLoading={isLoading}
                    toolTip={<TotalDistributionTooltip />}
                  />
                </Grid>
              )}
              {(!isError && !showCurrentQuarterDistributions) && (
                <Grid>
                  <AmountCard
                    label="TOTAL LIFETIME DISTRIBUTIONS"
                    amount={userDistributions.lifeTimeDistribution}
                    paletteColor="beige"
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {showCurrentQuarterDistributions && (
                <Grid sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  {/* <ReinvestCard setReinvestDialogOpen={setReinvestDialogOpen} /> */}
                </Grid>
              )}
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md
              alignItems={{ xs: 'center', sm: 'center', md: 'start' }}
              pt={{ xs: 3, md: 1 }}
            >
              <Stack spacing={2}>
                <Stack
                  direction={{ xs: 'column', sm: showCurrentQuarterDistributions ? 'column' : 'row' }}
                  justifyContent="space-between"
                  alignItems={{ xs: 'center', sm: 'center', md: showCurrentQuarterDistributions ? 'start' : 'center' }}
                  spacing={3}
                >
                  <Typography variant="h2">
                    { showCurrentQuarterDistributions ? 'Q2 2024 Distributions' : 'All Distributions' }
                  </Typography>
                  <Typography variant="body2" sx={{ maxWidth: '1000px' }}>
                    {!!showCurrentQuarterDistributions && currentDistributionsSubtitle }
                  </Typography>
                  { (showCurrentQuarterDistributions && quarterDistributionsExist) && (
                    <ToggleButtonGroup
                      value={fundFilter}
                      exclusive
                      onChange={(event, newFund) => {
                        if (newFund !== null) {
                          setFundFilter(newFund);
                        }
                      }}
                      aria-label="Filter by Fund"
                      sx={{
                        rowGap: 1.8,
                        columnGap: 1.5,
                        flexWrap: 'wrap',
                        display: { xs: 'none', sm: 'none', md: 'flex' },
                      }}
                    >
                      <FundFilter
                        label="All funds"
                        value="all"
                        amount={userDistributions.currentQuarterTotalDistributions}
                        // The built-in selected functionality of MUI's ToggleButtonGroup appears to be
                        // bugged in some manner with this custom component,
                        // manually setting the selected property is an easy solution
                        selected={fundFilter === 'all'}
                      />
                      { userDistributions.currentQuarterDistributions.map((fundDistributions) => (
                        <FundFilter
                          key={fundDistributions.fundName}
                          label={fundDistributions.fundName}
                          value={fundDistributions.fundName}
                          amount={fundDistributions.fundTotalDistributions}
                          fundCode={fundDistributions.fundFamily}
                          // The built-in selected functionality of MUI's ToggleButtonGroup appears to be
                          // bugged in some manner with this custom component,
                          // manually setting the selected property is an easy solution
                          selected={fundFilter === fundDistributions.fundName}
                        />
                      ))}
                      {/* *
                        * Other distributions are not being released yet */}
                      {/* <FundFilter
                        label="Other"
                        value="other"
                        amount={otherQuarterDistributions}
                        // The built-in selected functionality of MUI's ToggleButtonGroup appears to be
                        // bugged in some manner with this custom component,
                        // manually setting the selected property is an easy solution
                        selected={fundFilter === 'other'}
                      /> */}

                    </ToggleButtonGroup>
                  )}
                  { (!showCurrentQuarterDistributions && allDistributionsExist) && (
                    <Search
                      label="Search fund or company"
                      data={sortedData}
                      fieldsToSearchOn={['companyName', 'fundName']}
                      searchOptions={searchOptions}
                      onClear={onSearchClear}
                      onSearch={onSearch}
                    />
                  )}
                </Stack>
                <Divider variant="fullWidth" sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} />
                { (!showCurrentQuarterDistributions && allDistributionsExist) && (
                  <Box sx={{ alignSelf: 'flex-end', minWidth: 145 }}>
                    <FormControl size="small" fullWidth sx={{ mt: { xs: 1, sm: 1, md: 0 } }}>
                      <InputLabel id="sortBy-label">Sort by</InputLabel>
                      <Select
                        labelId="sortBy-label"
                        id="sortBy"
                        value={sortBy}
                        onChange={handleSortByChange}
                        label="Sort by"
                      >
                        <MenuItem value="datePaid-desc" comparator="date">Date: Newest</MenuItem>
                        <MenuItem value="datePaid-asc" comparator="date">Date: Oldest</MenuItem>
                        <MenuItem value="fundName-asc">Fund: A - Z</MenuItem>
                        <MenuItem value="fundName-desc">Fund: Z - A</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {isLoading ? (
                  <Box sx={{ '&&': { my: 5, mx: 'auto' } }}>
                    <Loader />
                  </Box>
                ) : (
                  <DistributionsDataDisplay
                    isError={isError}
                    showCurrentQuarterDistributions={showCurrentQuarterDistributions}
                    quarterDistributionsExist={quarterDistributionsExist}
                    allDistributionsExist={allDistributionsExist}
                    displayedData={displayedData}
                    displayedQuarterData={displayedQuarterData}
                  />
                )}
                {/* <ReinvestCard
                  ref={mobileReinvestCard}
                  setReinvestDialogOpen={setReinvestDialogOpen}
                  size="wide"
                  sx={{
                    alignSelf: 'center',
                    // can't conditionally render component based on showCurrentQuarterDistributions
                    // because it will break the in viewport ref functionality, so we have to
                    // conditionally change the display css property instead
                    display: {
                      xs: showCurrentQuarterDistributions ? 'block' : 'none',
                      sm: showCurrentQuarterDistributions ? 'block' : 'none',
                      md: 'none',
                    },
                  }}
                /> */}
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Drawer
          anchor="bottom"
          variant="persistent"
          open={!reinvestCardInViewport && showCurrentQuarterDistributions}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
        >
          <Box p={2} m="auto">
            {/* <Button
              type="button"
              variant="contained"
              endIcon={<NavigateNextIcon />}
              onClick={() => setReinvestDialogOpen(true)}
            >
              I want to re-invest
            </Button> */}
          </Box>
        </Drawer>
        <ReinvestDialog
          open={reinvestDialogOpen}
          handleClose={() => setReinvestDialogOpen(false)}
        />
      </Container>
    </>
  );
};

Distribution.propTypes = {};

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
});

export default withRouter((connect(mapStateToProps)(Distribution)));
