import React from 'react';
import PropType from 'prop-types';
import {
  Card, CardBody,
} from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';

const defaultLogo = 'https://s3.amazonaws.com/la-company-logos/avg_logo2.png';

const useStyles = makeStyles(() => ({
  logo: {
    display: 'inline-block',
    width: 200,
    height: 50,
    borderRadius: '0',
    background: 'white',
  },
}));

const BasecampHeader = (props) => {
  const classes = useStyles();
  const { logoFilePath, name } = props;
  return (
    <div className={classes.root}>
      <Card className="pb-0" style={{ padding: '.25rem' }}>
        <CardBody
          style={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            padding: '0px',
            verticalAlign: 'middle',
            display: 'inline-flex',
            flexWrap: 'wrap',
          }}
          className="py-3 d-flex align-items-center"
        >
          <a href="https://www.av.vc/funds/focused-funds" target="_blank" rel="noreferrer">
            <img className={classes.logo} src={logoFilePath || defaultLogo} alt="basecamp logo" />
          </a>

          <h4
            className="bold-text align-middle"
            style={{ display: 'inline-block', marginLeft: '0px', marginTop: '12px' }}
          >
            <a href="https://www.av.vc/funds/focused-funds" target="_blank" rel="noreferrer">{name}</a>
          </h4>
        </CardBody>
      </Card>
    </div>
  );
};

BasecampHeader.propTypes = {
  name: PropType.string.isRequired,
  logoFilePath: PropType.string.isRequired,
};

export default BasecampHeader;
