import React from 'react';
import PropTypes from 'prop-types';
import isTransactionComplete from '../../../../shared/helpers/isTransactionComplete';

const TopbarNavInvestments = ({ userPortfolio = {} }) => {
  const investmentsInProgress = userPortfolio
    && userPortfolio.transactions
    && userPortfolio.transactions.filter((transaction) => !isTransactionComplete(transaction));

  return (
    <>
      {investmentsInProgress && investmentsInProgress.length > 0 && (
        <a className="topbar__nav-link" href={`${process.env.APP_AV_VC_PATH}/investment-summary`}>
          Investments In Progress
        </a>
      )}
    </>
  );
};

TopbarNavInvestments.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  userPortfolio: PropTypes.any.isRequired,
};

export default TopbarNavInvestments;
