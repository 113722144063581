/* eslint-disable no-restricted-syntax */
function getMatch(obj, value) {
  if (!obj || typeof obj !== 'object') {
    return null;
  }

  for (const key of Object.keys(obj)) {
    if (typeof obj[key] !== 'object') {
      if (`${obj[key]}`.toLowerCase() === (`${value}`.toLowerCase())) {
        return obj;
      }
    }
  }

  for (let i = 0, len = Object.keys(obj).length; i < len; i++) {
    if (typeof obj[i] === 'object') {
      const found = getMatch(obj[i], value);
      if (found) {
        return found;
      }
    }
  }
}

export default function findBasecampInvestment(fundInvestmentData) {
  const basecampMetaLookup = [
    {
      id: 136,
      name: 'AVG Basecamp 2020 AF',
      fundCode: 'BCMP1',
    },
    {
      id: 162,
      name: 'AVG Basecamp 2021 AF',
      fundCode: 'FFBCAF1220',
    },
    {
      id: 227,
      name: 'AVG Basecamp 2021',
      fundCode: 'FFBC1220',
    },
  ];

  for (const entry of basecampMetaLookup) {
    const match = getMatch(fundInvestmentData, entry.name);
    if (match) {
      return entry;
    }
  }
  return null;
}
