import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Col, Container, Row,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import LoginCard from './components/LoginCard';
import ForgotPasswordCard from './components/ForgotPasswordCard';

const InvestorLogin = ({ auth }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  useEffect(() => {
    if (auth && auth.user !== null) {
      setIsLoggedIn(true);
      const queryParams = new URLSearchParams(window.location.search);
      const redirectUrl = queryParams.get('redirect');

      if (redirectUrl) {
        window.location.replace(decodeURIComponent(redirectUrl));
      }
    }
  }, [auth]);

  return (
    <>
      {isLoggedIn && !window.location.search.includes('redirect') && <Redirect to="/" />}
      <Helmet>
        <title>Investor Login - Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <div className="container__wrap" style={{ paddingTop: '30px' }}>
        <Container className="dashboard">
          <Row className="justify-content-center">
            <Col sm="12" md="8">
              { !forgotPassword && (
                <LoginCard switchScreen={setForgotPassword} />
              )}
              { forgotPassword && (
                <ForgotPasswordCard switchScreen={setForgotPassword} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

InvestorLogin.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default withRouter(connect(mapStateToProps)(InvestorLogin));
