/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React from 'react';
import { withRouter } from 'react-router-dom';

const ForgotPasswordCard = (managedLogin = { managedLogin: false }) => {
  const loginRedirect = encodeURIComponent(window.location.href);
  window.location = `${
    process.env.APP_AV_VC_PATH
  }/reset-password-request?login=${loginRedirect}&isManager=${managedLogin.managedLogin || false}`;
  return null;
};

export default withRouter(ForgotPasswordCard);
