import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { Alert, AlertTitle } from '@material-ui/lab';

const errorMessage = (
  <p>
    Our account reporting system is temporarily unavailable due to planned maintenance.
    Please check back in 2-4 hours. We apologize for the inconvenience. Please
    contact Alumni Ventures Investor Relations
    at <a href="investor.relations@avgfunds.com">investor.relations@avgfunds.com</a> for further assistance.
  </p>
);

const ErrorNotification = ({ title, body, severity }) => (
  <Row className="px-3 py-2 mt-3 pb-5">
    <Alert severity={severity}>
      {title && (
        <AlertTitle>
          {title}
        </AlertTitle>
      )}
      {body}
    </Alert>
  </Row>
);

ErrorNotification.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  body: PropTypes.any,
  severity: PropTypes.string,
};

ErrorNotification.defaultProps = {
  title: 'Note to All Investors:',
  body: errorMessage,
  severity: 'warning',
};

export default ErrorNotification;
