import React from 'react';
import PropTypes from 'prop-types';
import {
  ToggleButtonGroup, ToggleButton, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&&&': {
    fontSize: 14,
    fontWeight: 700,
    borderRadius: 25,
    border: 'none',
    color: theme.palette.secondary.main,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 18,
    paddingRight: 18,
    lineHeight: 1.2,
    textTransform: 'none',
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
}));

const Toggle = ({ value, setValue, toggleOptions }) => (
  <Box
    p={0.5}
    bgcolor="white"
    border={1}
    borderRadius={25}
    borderColor="secondary.main"
  >
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(event, newValue) => {
        if (newValue !== null) {
          setValue(newValue);
        }
      }}
      aria-label={`View ${toggleOptions[0]} or ${toggleOptions[1]}`}
    >
      <CustomToggleButton
        value
        sx={{ mr: 0.5 }}
      >
        {toggleOptions[0]}
      </CustomToggleButton>
      <CustomToggleButton
        value={false}
      >
        {toggleOptions[1]}
      </CustomToggleButton>
    </ToggleButtonGroup>
  </Box>
);

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  toggleOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Toggle;
