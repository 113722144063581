/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const TopbarMenuLinks = ({
  title, icon, path, onClick,
}) => (path ? (
  <Link className="topbar__link" to={path}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <p className="topbar__link-title">{title}</p>
  </Link>
) : (
  <a className="topbar__link" role="menuitem" tabIndex="-1" onClick={onClick}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <p className="topbar__link-title">{title}</p>
  </a>
));

TopbarMenuLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string,
  onClick: PropTypes.func,
};
TopbarMenuLinks.defaultProps = {
  path: null,
  onClick: undefined,
};

export default TopbarMenuLinks;
