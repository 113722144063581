import React from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MobileHeadCells } from '../PortfolioNewsHeadCells';
import { MobileNewsItemBox } from './shared';

const MobileTableRowSkeleton = styled(Skeleton)(({ theme }) => ({
  height: 52,
  transform: 'none',
  borderRadius: 0,
  backgroundColor: theme.palette.mobileTable.dark,
  '&:nth-of-type(odd)': { backgroundColor: theme.palette.mobileTable.main },
}));

const MobileSkeleton = () => (
  <MobileNewsItemBox sx={{ backgroundColor: 'white' }}>
    <MobileTableRowSkeleton animation="wave" />
    {MobileHeadCells.map((headCell) => (
      <MobileTableRowSkeleton
        key={headCell.id}
        animation="wave"
      />
    ))}
  </MobileNewsItemBox>
);

export default MobileSkeleton;
