import { useState, useEffect } from 'react';
import { getK1Metadata } from '../../../shared/services/apiGateway';

function useK1Metadata(userid = '', year = 2023) {
  const [k1Metadata, setK1Metadata] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getK1Metadata(userid, year, abortController.signal);
      if (!ignore && response !== 'aborted' && response && response.data) {
        setK1Metadata(response.data);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [userid]);

  return k1Metadata;
}

export default useK1Metadata;
