import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { actions } from '@alumni-ventures-group/react-shared';
import useUserTokens from '../common/useUserTokens';
import { formatTokenAmount } from '../common/utils';
import {
  avgTokenIconUrl, errorMessage, toolTipText,
} from '../common/tokenHelper';
import checkApiErrorResponse from '../../../shared/services/checkApiErrorResponse';

const useStyles = makeStyles({
  mobileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    paddingLeft: 15,
    paddingBottom: 5,
  },
  mobileTokenAmount: {
    marginLeft: 5,
    color: '#104866',
    fontSize: '14px',
    fontFamily: '"Inter", "Roboto", sans-serif',
    fontWeight: 'bold',
  },
  toolTipText: {
    padding: 5,
    fontSize: '12px',
  },
});

const SidebarAVT = ({ dispatch }) => {
  const classes = useStyles();
  const returnedTokenAmount = useUserTokens();
  const [userTokenAmount, setUserTokenAmount] = useState(0);
  const [toolTipToggle, setToolTipToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { showSnackbarWarning } = actions.snackbarActions;

  useEffect(() => {
    if (returnedTokenAmount) {
      setUserTokenAmount(returnedTokenAmount.tokensTotal);
      setIsLoading(false);
      if (checkApiErrorResponse(returnedTokenAmount)) {
        dispatch(showSnackbarWarning(errorMessage));
      }
    }
  }, [returnedTokenAmount]);

  const openMoreInfo = () => {
    if (toolTipToggle) {
      window.open('https://www.av.vc/blog/avt', '_blank');
    }
    setToolTipToggle(!toolTipToggle);
  };

  return (
    <>
      <Tooltip
        arrow
        interactive
        placement="bottom"
        onOpen={() => setToolTipToggle(true)}
        onClose={() => setToolTipToggle(false)}
        open={toolTipToggle}
        title={toolTipText(userTokenAmount, classes)}
        disableTouchListener
      >
        <div
          className={classes.mobileContainer}
          onClick={openMoreInfo}
          onKeyPress={openMoreInfo}
          role="button"
          tabIndex={0}
        >
          <img
            src={avgTokenIconUrl}
            style={{ height: 25, width: 25 }}
            alt="Token Icon"
          />
          <div className={classes.mobileTokenAmount}>
            {isLoading ? (<Skeleton variant="text" width={33} />) : formatTokenAmount(userTokenAmount)}
          </div>
        </div>
      </Tooltip>
    </>
  );
};

SidebarAVT.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dispatch: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
});

export default withRouter((connect(mapStateToProps)(SidebarAVT)));
