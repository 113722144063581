import React, { useState, useContext } from 'react';
import {
  Table, TableBody, TableContainer, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@alumni-ventures-group/react-shared/lib/components';
import TableHead from '../../../common/TableHead';
import PortfolioNewsHeadCells from '../PortfolioNewsHeadCells';
import PortfolioNewsTableRow from './PortfolioNewsTableRow';
import ManagingPartnerTableRow from './ManagingPartnerTableRow';
import { HorizontalCenteredContent } from '../styles';
import { PortfolioNewsContext } from '../PortfolioNews';
import SortSelect from '../SortSelect';
import TablePagination from '../../../common/TablePagination';

const StyledTable = styled(Table)({
  position: 'relative',
  'tr:last-child td:first-of-type': { borderBottomLeftRadius: '7px' },
  'tr:last-child td:last-child': { borderBottomRightRadius: '7px' },
});

const PortfolioNewsTable = () => {
  const {
    filteredNews,
    fetchPortfolioNews,
    isLoading,
    page,
    setPage,
  } = useContext(PortfolioNewsContext);
  const [visibleRows, setVisibleRows] = useState(filteredNews);

  return (
    <TableContainer sx={{ overflowX: 'initial' }}>
      <StyledTable stickyHeader>
        <TableHead
          headCells={PortfolioNewsHeadCells}
          stickyHeader
          sortSelect={<SortSelect />}
          pagination={(
            <TablePagination
              page={page}
              setPage={setPage}
              rows={filteredNews}
              count={filteredNews.length}
              setVisibleRows={setVisibleRows}
              fetchNewRows={fetchPortfolioNews}
            />
          )}
        />
        {!isLoading && (
          <TableBody>
            {visibleRows.map((row, index) => (
              row.source !== 'MP Update' ? (
                <PortfolioNewsTableRow key={`${row.source}-${row.headline}-${row.date}`} row={row} index={index} />
              ) : (
                <ManagingPartnerTableRow key={`${row.content}-${row.date}`} row={row} index={index} />
              )
            ))}
          </TableBody>
        )}
      </StyledTable>
      {isLoading && (
        <HorizontalCenteredContent>
          <Box sx={{ mt: 4 }}>
            <Loader />
          </Box>
        </HorizontalCenteredContent>
      )}
    </TableContainer>
  );
};

export default PortfolioNewsTable;
