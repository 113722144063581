import { useState, useEffect } from 'react';
// TODO: Replace call with cache get request for investments data
import { getFundInvestments } from '../../../shared/services/apiGateway';

function useFundInvestments(baseFundCode, fundCode, userid = '') {
  const [fundInvestments, setFundInvestments] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getFundInvestments(baseFundCode, fundCode, userid, abortController.signal);
      if (!ignore && response !== 'aborted' && response) {
        setFundInvestments(response);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [baseFundCode, fundCode, userid]);
  return fundInvestments;
}

export default useFundInvestments;
