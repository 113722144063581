import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Select from 'react-select';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  IconButton, Box, Button, Divider, Link, TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { NumericFormat } from 'react-number-format';
import { useTheme } from '@mui/material/styles';
import { sendEmailToIR } from '../../../../shared/services/apiGateway';
import avFundOptions from './avFundOptions';

const useStyles = makeStyles({
  selectors: {
    zIndex: 9999,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

const ReinvestDialog = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [avFund, setAvFund] = useState([]);
  const [distribution, setDistribution] = useState();
  const classes = useStyles();
  const theme = useTheme();

  const subject = 'Discuss options with an Investor Relations Manager';

  const selectCustomStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: theme.palette.purpleLink.main,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
    }),
  };

  const clearAndClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    handleClose(event, reason);
    setAvFund([]);
    setDistribution();
    setLoading(false);
  };

  const handleSubmit = async () => {
    const investorSelectedFunds = avFund.map((fund) => fund.value).join(', ');
    try {
      setLoading(true);
      const body = {
        subject,
        body: `<ul><li> Investor Selected Fund = ${investorSelectedFunds}</li><li> Investor $ Allocation = ${distribution}</li></ul>`,
      };
      const result = await sendEmailToIR(body);
      // actually an error, but a misnamed response
      if (result === 'aborted') {
        handleClose();
      } else {
        setSuccess(true);
      }
    } catch (error) {
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  // Using this firstInput ref and scrollIntoView solves a very specific bug
  // where on iOS on mobile, when the dialog is opened MUI's autoFocus property
  // was not scrolling the input into view, this hook manually scrolls it into view
  // after a short delay for the component and keyboard to render
  const firstInput = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (!open) {
        setSuccess(false); // reset dialog state so a new message can be sent
      }
      if (open && firstInput.current) {
        firstInput.current.scrollIntoView();
      }
    }, 100);
  }, [open]);

  const onBlurDistributionAmount = useCallback(
    (event) => {
      const inputAmount = event.target.value;
      setDistribution(inputAmount ?? '');
    },
    [setDistribution],
  );

  return (
    <Dialog
      open={open}
      onClose={clearAndClose}
      scroll="body"
      PaperProps={{
        sx: {
          paddingTop: { xs: 4.5, sm: 6 },
          paddingBottom: { xs: 4.5, sm: 6 },
          paddingLeft: { xs: 3, sm: 3 },
          paddingRight: { xs: 3, sm: 3 },
        },
      }}
      aria-labelledby="reinvest-dialog-title"
      aria-describedby="reinvest-dialog-text"
    >
      <IconButton
        aria-label="close"
        onClick={clearAndClose}
        sx={{
          position: 'absolute',
          right: '3%',
          top: '3%',
          color: (theme) => theme.palette.lightGrey.main,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle id="reinvest-dialog-title" sx={{ py: 0, px: 2 }}>
        Distribution Allocation Request {success && 'Submitted'}
      </DialogTitle>
      <DialogContent sx={{ padding: 0, marginTop: 1.5 }}>
        { success ? (
          <DialogContentText
            id="reinvest-dialog-text"
            variant="body2"
            textAlign="center"
            sx={{
              '&&': { margin: 'auto' },
              maxWidth: { xs: '30ch', sm: '45ch' },
              opacity: 0.8,
            }}
          >
            Thank you for submitting your request.
            One of our Investor Relations Managers will reach out to you soon.
          </DialogContentText>
        )
          : (
            <Box m={{ xs: 3, sm: 3 }}>
              <DialogContentText
                id="reinvest-dialog-text"
                variant="body1"
                textAlign="center"
              >
                Allocate your AV distribution to an open AV fund at a reduced minimum investment size.
              </DialogContentText>
              <DialogContentText
                id="reinvest-dialog"
                variant="body1"
                textAlign="center"
              >
                Complete and submit the below form. Your dedicated Investor Relations Manager will be in touch with you shortly.
              </DialogContentText>
            </Box>
          )}
        { !success && (
          <>
            <Divider variant="fullWidth" sx={{ display: { xs: 'block' } }} />
            <Box mt={{ xs: 3 }}>
              <DialogContentText variant="label">Which fund(s) would you like to allocate into?*</DialogContentText>
              <Link
                href="https://www.av.vc/funds"
                target="_blank"
                sx={{ fontWeight: '600', marginBottom: '30px' }}
                rel="noreferrer"
              >
                (Click here to view open AV Funds)
              </Link>
              <Select
                id="avFunds"
                className={classes.selectors}
                value={avFund}
                options={avFundOptions}
                onChange={setAvFund}
                placeholder="Select fund(s) to allocate to..."
                isMulti
                menuPosition="fixed"
                styles={selectCustomStyles}
              />
              <DialogContentText variant="label">What total dollar ($) amount of your distribution will you allocate to the new investment(s)?*</DialogContentText>
              <TextField
                value={distribution}
                onChange={onBlurDistributionAmount}
                variant="standard"
                InputProps={{
                  inputComponent: NumericFormat, // Use NumericFormat as the input component
                  inputProps: {
                    prefix: '$',
                    type: 'text',
                    thousandsGroupStyle: 'thousand',
                    thousandSeparator: ',',
                    placeholder: '$0.00',
                    maxLength: 50,
                    decimalScale: 2,
                  },
                }}
              />
              <DialogContentText variant="disclaimer" mt={{ xs: 1 }}>
                *If you have selected multiple funds, just let us know your total intended re-allocation here, and an IRM will follow up to help split the funds as you direct
              </DialogContentText>
              <DialogContentText variant="body1" mt={{ xs: 3 }}>If you would like more information, please review our
                <Link
                  href="https://3925488.fs1.hubspotusercontent-na1.net/hubfs/3925488/_SoT_Mirror/22_Investor_Engagement/FAQs_for_Re-Invest.pdf"
                  target="_blank"
                  sx={{ fontWeight: '600', padding: '4px' }}
                  rel="noreferrer"
                >
                  FAQ
                </Link>
              </DialogContentText>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          marginTop: { xs: 3, sm: 4.5 },
          padding: 0,
        }}
      >
        { success ? (
          <Button
            type="button"
            variant="contained"
            onClick={clearAndClose}
            sx={{
              m: 'auto',
            }}
          >
            Dismiss
          </Button>
        ) : (
          <LoadingButton
            type="button"
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !distribution
              || parseInt(distribution.toString().replace(/[$,]/g, ''), 10) === 0
              || !avFund
              || avFund.length === 0
            }
            loading={loading}
            sx={{
              m: 'auto',
            }}
          >
            Submit request
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

ReinvestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ReinvestDialog;
