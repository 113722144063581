import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../../../ManagedUser/Login';
import ManagedInvestorDashboard from '../../../ManagedUser/InvestorDashboard';
import ManagedFundDetails from '../../../ManagedUser/FundDetails';
import ManagedInvestorStatements from '../../../ManagedUser/InvestorStatements';
// TODO: Remove mananged urls
export default () => (
  <Switch>
    <Route path="/managed/funds/details/:fundCode" component={ManagedFundDetails} />
    <Route path="/managed/funds/statements" component={ManagedInvestorStatements} />
    <Route path="/managed/login" component={Login} />
    <Route exact path="/managed" component={ManagedInvestorDashboard} />
  </Switch>
);
