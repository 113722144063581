import React from 'react';
import { useSelector } from 'react-redux';
import {
  Container, Card, CardBody, Row, Col,
} from 'reactstrap';

import FundDetails from '../../Investor/FundDetails';

const ManagedFundDetails = () => {
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  return (
    <>
      {!currentManagedUser && (
        <Container className="dashboard" style={{ paddingTop: '30px' }}>
          <Card className="pb-0" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
            <CardBody
              style={{
                backgroundColor: '#104866',
                // borderTopWidth: '0px', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                padding: '8px, 10px',
              }}
              className="py-3"
            >
              <Row>
                <Col>
                  <h3>Select a User From the Dropdown Above</h3>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}
      {currentManagedUser && (
        <div style={{ paddingTop: '30px' }}>
          <FundDetails />
        </div>
      )}
    </>
  );
};

export default ManagedFundDetails;
