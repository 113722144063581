import dotenv from 'dotenv';
import React from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import 'abortcontroller-polyfill';
import App from './containers/App/App';

dotenv.config();
console.log('process.env', process.env.AVG_API_PATH);

ReactGA.initialize('UA-112414020-2');
ReactGA.pageview(window.location.pathname + window.location.search);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID,
    context: {
      kind: 'user',
      anonymous: true,
      // Use the same key for every non-logged-in user
      // Because investor portal is entirely blocked behind login, we don't care to track individual logged out users
      // We won't ever need to do % rollouts across logged out users or target individual logged out users
      // This keeps our monthly MAU usage significantly lower
      // https://docs.launchdarkly.com/home/contexts/anonymous-contexts#using-a-shared-key-between-anonymous-contexts
      key: 'shared-anon-user-key',
    },
  });
  render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
})();
