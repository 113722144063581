import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Col, Container, Row,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import ForgotPasswordCard from '../../Investor/InvestorLogin/components/ForgotPasswordCard';
import LoginCard from './components/LoginCard';

const InvestorLogin = ({ auth }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  useEffect(() => {
    if (auth && auth.user !== null) {
      setIsLoggedIn(true);
    }
  }, [auth]);

  // dispatch({ user: data.admin.data, authCode: data.admin.authCode, type: INIT_SESSION_SUCCESS });

  return (
    <>
      {isLoggedIn && <Redirect to="/managed" />}
      <Helmet>
        <title>Authorized Account Login - Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <Container className="dashboard">
        <Row className="justify-content-center">
          <Col sm="12" md="8">
            {!forgotPassword && (
              <LoginCard switchScreen={setForgotPassword} showCreateAccount={false} />
            )}
            {forgotPassword && (
              <ForgotPasswordCard switchScreen={setForgotPassword} managedLogin />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

InvestorLogin.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default withRouter(connect(mapStateToProps)(InvestorLogin));
