import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getCellBackgroundColor } from '../styles';
import PortfolioNewsFundCell from '../PortfolioNewsFundCell';

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})(({ source, isExpanded, theme }) => ({
  cursor: 'pointer',
  paddingTop: 10,
  paddingBottom: 10,
  fontWeight: 400,
  letterSpacing: '-0.42px',
  backgroundColor: getCellBackgroundColor(source, isExpanded, theme),
  borderRadius: source === 'MP Update' ? '0 2px 2px 0' : 0,
  transition: source === 'MP Update' ? 'background-color 0.3s ease, color 0.3s ease' : 'unset',
  '&&': {
    color: source === 'MP Update' && isExpanded ? 'white' : '#191919',
  },
  '&.bold': {
    fontWeight: 600,
  },
}));

const getCellContents = (id, row) => {
  if (id === 'fund') {
    return <PortfolioNewsFundCell funds={row[id]} />;
  }
  if (id === 'headline' && row.url) {
    return (
      <Link
        href={row.url}
        target="_blank"
        rel="noopener noreferrer"
        variant="body2"
        sx={{
          fontWeight: 'inherit',
          '&:hover': {
            color: '#191919',
          },
        }}
      >
        {row[id]}
      </Link>
    );
  }
  if (id === 'source' && row[id] && row[id].length > 46) {
    return `${row[id].slice(0, 46)}...`;
  }
  return row[id];
};

const PortfolioNewsTableCell = ({ headCell, row, isExpanded }) => (
  <StyledTableCell
    className={headCell.bold && 'bold'}
    source={headCell.id === 'source' ? row[headCell.id] : undefined}
    isExpanded={isExpanded}
  >
    {getCellContents(headCell.id, row)}
  </StyledTableCell>
);

PortfolioNewsTableCell.propTypes = {
  headCell: PropTypes.shape({
    id: PropTypes.string.isRequired,
    numeric: PropTypes.bool,
    label: PropTypes.string,
    tooltip: PropTypes.element,
    width: PropTypes.string,
    bold: PropTypes.bool,
  }).isRequired,
  row: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default PortfolioNewsTableCell;
