// given param API Response, checks if API Response has returned an error message or actual Response

export default function checkApiErrorResponse(response) {
  if (response) {
    if (response[0] && response[0].message && response[0].statusCode) {
      if (response[0].statusCode >= 300 || response[0].statusCode < 200) {
        return true;
      }
    }
    if (response.message && response.statusCode) {
      if (response.statusCode >= 300 || response.statusCode < 200) {
        return true;
      }
    }
    if (!response.message || !response.statusCode) {
      return false;
    }
  }
  return true;
}
