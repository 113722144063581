import React, { PureComponent } from 'react';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LogInForm from './components/LogInForm';
import { doSignIn, doLogOut } from '../../shared/services/authActions'

const logo = 'https://investor.avgfunds.com/images/logo_new.png';
class LogIn extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

    signIn = async (values) => {
      const { doSignIn: signInPromise } = this.props;

      const { email, password } = values;
      if (email && password) {
        const signInRes = await signInPromise({
          email,
          password,
        });
        if (!signInRes) {
          throw new SubmissionError({
            password: "Wrong password",
            _error: "Login failed!",
          });
        }
        return signInRes;
      }

      return false;
    };

    render() {
      const { application } = this.props;

      return (
        <div className="account">
          <div className="account__wrapper">
            <div className="account__card">
              <div>
                <img src={logo} alt="logo" style={{ width: '100%', padding: '30px 0' }} />
                <h1 style={{ textAlign: 'center', color: 'black', paddingBottom: '30px' }}>
                  {application.windowTitle}
                </h1>
              </div>
              <LogInForm onSubmit={this.signIn} />
            </div>
          </div>
        </div>
      );
    }
}

LogIn.propTypes = {
  doSignIn: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  application: PropTypes.any.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  application: state.application,
});

const mapDispatchToProps = (dispatch) => ({
  doSignIn: (credentials) => doSignIn(dispatch, credentials),
  doLogOut: () => { doLogOut(dispatch); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
