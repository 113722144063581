/* eslint-disable max-len */
import React from 'react';
import {
  Row, Col,
} from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '.25rem',
    paddingTop: '20px',
  },
  title: {
    fontWeight: 'bold',
  },
}));

const AboutBasecamp = () => {
  const classes = useStyles();
  return (
    <Row>
      <Col>
        <div className={classes.container}>
          <h5 className={classes.title}>ABOUT BASECAMP</h5>
          <p>Basecamp is a dedicated AV fund that invests in 50-80 very small pre-seed and seed deals per year on your behalf and on behalf of all our Alumni Funds. The fund is run by specialists in early deals, who have the network and expertise to get into promising investments at this stage.</p>
          <p>We are providing this to give you better representation in pre-seed and seed deals, delivering even greater diversity to you. It’s investing at the earliest stages that can provide the highest multiples in returns for those investments that do thrive and take off.</p>
          <p>Your Basecamp investment is the equivalent of approximately one normal sized investment (3-5% of a fund). There are no additional fees or carry charged on these investments. Explore each deal below by clicking on them.</p>
        </div>
      </Col>
    </Row>
  );
};

export default AboutBasecamp;
