const PortfolioNewsHeadCells = [
  {
    id: 'source',
    numeric: false,
    label: 'Source',
  },
  {
    id: 'expand',
    numeric: false,
    label: null,
    width: '1%',
  },
  {
    id: 'headline',
    numeric: false,
    label: 'Headline',
    width: '50%',
    bold: true,
  },
  {
    id: 'company',
    numeric: false,
    label: 'Company',
  },
  {
    id: 'fund',
    numeric: false,
    label: 'Fund',
  },
  {
    id: 'date',
    numeric: false,
    label: 'Date',
  },
];

export default PortfolioNewsHeadCells;

export const MobileHeadCells = PortfolioNewsHeadCells.filter((cell) => cell.id !== 'expand');
