import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import TotalAmountTable from './TotalAmountTable';

const DataText = styled(Typography)({
  '&&': {
    maxWidth: '50ch',
    marginTop: 40,
    marginBottom: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

DataText.defaultProps = {
  variant: 'body1',
  align: 'center',
};

const DistributionsDataDisplay = ({
  isError,
  showCurrentQuarterDistributions,
  quarterDistributionsExist,
  allDistributionsExist,
  displayedData,
  displayedQuarterData,
  showOtherDistributions,
}) => {
  if (isError) {
    return (
      <DataText>
        <ErrorIcon color="error" sx={{ mr: 1 }} />
        Oops, something went wrong. Please try again.
      </DataText>
    );
  }

  // If there is no data
  if ((showCurrentQuarterDistributions && !quarterDistributionsExist)
    || (!showCurrentQuarterDistributions && !allDistributionsExist)) {
    return (
      <DataText>
        There are no current distributions as of yet.
        Please come visit later when amounts have been distributed.
      </DataText>
    );
  }

  // If the results have been filtered to nothing
  if ((showCurrentQuarterDistributions && !displayedQuarterData.length)
    || (!showCurrentQuarterDistributions && !displayedData.length)) {
    return (
      <DataText>
        No results found
      </DataText>
    );
  }

  if (showCurrentQuarterDistributions) {
    const tables = displayedQuarterData.map((fund) => (
      <TotalAmountTable
        distributionData={fund.distribution}
        showCurrentQuarterDistributions
        fundName={fund.fundName}
        fundCode={fund.fundFamily}
        key={`${fund.fundName}-${fund.fundFamily}`}
        fundTotalDistributions={fund.fundTotalDistributions}
      />
    ));

    return <>{tables}</>;
  }

  if (showOtherDistributions) {
    return (
      <TotalAmountTable
        distributionData={displayedData}
        showOtherDistributions
        fundName="Other Distributions"
      />
    );
  }

  return (
    <TotalAmountTable
      distributionData={displayedData}
      showCurrentQuarterDistributions={false}
    />
  );
};

DistributionsDataDisplay.propTypes = {
  isError: PropTypes.bool.isRequired,
  showCurrentQuarterDistributions: PropTypes.bool.isRequired,
  quarterDistributionsExist: PropTypes.bool.isRequired,
  allDistributionsExist: PropTypes.bool.isRequired,
  displayedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayedQuarterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  showOtherDistributions: PropTypes.bool,
};

DistributionsDataDisplay.defaultProps = {
  showOtherDistributions: false,
};

export default DistributionsDataDisplay;
