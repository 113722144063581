export default class WebsocketService {
  ws;

  /**
  * singleton.
  * reconnect if there is no existing connection
  * or connection is closed
  */
  constructor(url, onOpenCallback, onCloseCallback, onErrorCallback, dataCallback) {
    if (this.ws === undefined
      || this.ws.readyState === WebSocket.CLOSED) {
      this.ws = new WebSocket(url);
      this.removeMessageEventListener(dataCallback);
      this.addMessageEventListener(dataCallback);
      this.removeErrorEventListener(onErrorCallback);
      this.addErrorEventListener(onErrorCallback);
      this.removeOpenEventListener(onOpenCallback);
      this.addOpenEventListener(onOpenCallback);
      this.removeCloseEventListener(onCloseCallback);
      this.addCloseEventListener(onCloseCallback);
    }
  }

  get isConnected() {
    return this.ws !== undefined && this.ws.readyState === WebSocket.OPEN;
  }

  sendMessage = (message) => {
    this.ws.send(message);
  }

  addCloseEventListener = (onCloseCallback) => {
    this.ws.addEventListener('close', onCloseCallback);
  };

  removeCloseEventListener = (onCloseCallback) => {
    this.ws.removeEventListener('close', onCloseCallback);
  };

  addMessageEventListener = (dataCallback) => {
    this.ws.addEventListener('message', dataCallback);
  };

  removeMessageEventListener = (dataCallback) => {
    this.ws.removeEventListener('message', dataCallback);
  };

  addErrorEventListener = (onErrorCallback) => {
    this.ws.addEventListener('error', onErrorCallback);
  };

  removeErrorEventListener = (onErrorCallback) => {
    this.ws.removeEventListener('error', onErrorCallback);
  };

  addOpenEventListener = (onOpenCallback) => {
    this.ws.addEventListener('open', onOpenCallback);
  };

  removeOpenEventListener = (onOpenCallback) => {
    this.ws.removeEventListener('open', onOpenCallback);
  };

  disconnect = () => {
    if (this.isConnected) {
      this.ws.close();
    }
  };
}
