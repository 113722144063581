import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { archivoRobotoFontFamily, interRobotoFontFamily } from '../../../shared/style/fontFamilies';

export const ArticleBox = styled(Box)(({ theme }) => ({
  minHeight: '120px',
  position: 'relative',
  overflow: 'hidden',
  paddingTop: 24,
  paddingBottom: 24,
  paddingLeft: 10,
  paddingRight: 10,
  [theme.breakpoints.up(350)]: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 40,
    paddingRight: 64,
  },
  '&::before': {
    content: '""',
    display: 'inline-block',
    height: '155px',
    width: '155px',
    backgroundColor: '#104866',
    borderRadius: '100%',
    position: 'absolute',
    top: '-51px',
    left: '-32px',
  },
}));

export const ArticleHeader = styled(Typography)({
  fontSize: 26,
  fontWeight: 600,
  fontFamily: archivoRobotoFontFamily,
  position: 'relative',
  zIndex: 2,
  '&&': {
    color: 'white',
    lineHeight: '18px',
  },
});

export const ArticleHeaderSupertext = styled(Typography)({
  fontSize: 13,
  fontFamily: interRobotoFontFamily,
  display: 'block',
  position: 'absolute',
  left: '39px',
  '&&': {
    color: 'white',
  },
});

export const ArticleHeaderNumber = styled(Typography)({
  fontSize: 50,
  fontWeight: 700,
  fontFamily: archivoRobotoFontFamily,
  lineHeight: 1,
  marginRight: 8,
  '&&': {
    color: 'white',
  },
});

export const ArticleBannerText = styled(Typography)({
  fontSize: 15,
  fontFamily: interRobotoFontFamily,
  '&&': {
    color: 'white',
  },
});
