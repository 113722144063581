import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loader } from '@alumni-ventures-group/react-shared/lib/components';
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import useUserDocuments from '../common/useUserDocuments';
import ReportTable from './components/ReportTable';
import ExitTable from './components/ExitTable';
import TaxTable from './components/TaxTable';
// import ValuationTable from './components/ValuationTable';
import K1Table from './components/K1Table';
import useK1Metadata from '../common/useK1Metadata';

const InvestorStatements = ({ isFetching }) => {
  const [isLoading, setIsLoading] = useState(true);
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  const currentUser = useSelector((state) => {
    if (state.auth && state.auth.user && state.auth.authType === 'USER') {
      return state.auth.user;
    }
    if (state.auth && state.auth.authType === 'MANAGED' && state.currentManagedUser) {
      return state.auth.user.users.find((user) => user.id === state.currentManagedUser);
    }
    return undefined;
  });
  // const dashboardLabels = useSelector((state) => state.dashboardLabels);
  const userDocumentData = useUserDocuments(currentManagedUser ? `?userid=${currentManagedUser}` : '');
  const userK1Data23 = useK1Metadata(currentManagedUser, 2023);
  const userK1Data22 = useK1Metadata(currentManagedUser, 2022);

  useEffect(() => {
    if (userDocumentData && !isFetching) {
      setIsLoading(!isLoading);
    }
  }, [userDocumentData, currentManagedUser, isFetching]);

  return (
    <>
      <Helmet>
        <title>Investor Documents & Statments - Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <Container className="dashboard">
        <Card className="pb-0" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <CardBody
            style={{
              backgroundColor: '#104866',
              // borderTopWidth: '0px',
              // boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
              padding: '8px, 10px',
            }}
            className="py-3"
          >
            <Row>
              {(currentUser && (currentUser.first_name || currentUser.firstName)) && (
                <Col>
                  <h4 className="bold-text align-middle text-white">
                    {
                      `Documents & Statements - 
                      ${currentUser.first_name ? currentUser.first_name : currentUser.firstName} 
                      ${currentUser.last_name ? currentUser.last_name : currentUser.lastName}`.toUpperCase()
                    }
                  </h4>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
        <>
          {(isLoading) && (
            <Row>
              <Col>
                <Card>
                  <CardBody style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: '8px, 10px' }}>
                    <Row>
                      <Loader />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {(!isLoading && userK1Data23) && (
            <K1Table year={2023} k1Reports={userK1Data23} currentManagedUserId={currentManagedUser || ''} />
          )}
          {(!isLoading && userDocumentData && userDocumentData.reports) && (
            <ReportTable reports={userDocumentData.reports} />
          )}
          {(!isLoading && userDocumentData && userDocumentData.exit) && (
            <ExitTable exits={userDocumentData.exit} />
          )}
          {/** (!isLoading && userDocumentData && userDocumentData.valuations) && (
            <ValuationTable
              valuations={userDocumentData.valuations}
              managedUser={!!currentManagedUser}
            />
          ) */}
          {(!isLoading && userK1Data22) && (
            <K1Table year={2022} k1Reports={userK1Data22} currentManagedUserId={currentManagedUser || ''} />
          )}
          {(!isLoading && userDocumentData && userDocumentData.taxes) && (
            <TaxTable taxes={userDocumentData.taxes} />
          )}
        </>
      </Container>
    </>
  );
};

InvestorStatements.propTypes = {
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  dispatch: state.dispatch,
  isFetching: state.companies.isFetching,
});

export default withRouter((connect(mapStateToProps)(InvestorStatements)));
