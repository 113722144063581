import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Loader, MaterialTable } from '@alumni-ventures-group/react-shared/lib/components';
import { ClubResources } from '@alumni-ventures-group/react-shared/lib/services';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoCircleIcon from 'mdi-react/InfoCircleIcon';
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import Avatar from '@material-ui/core/Avatar';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Use new GridV2
import { Helmet } from 'react-helmet';
import crosshatchHorizontal from 'url:../../../images/crosshatch-horizontal.svg';
import crosshatchVertical from 'url:../../../images/crosshatch-vertical.svg';
import useUserInvestments from '../common/useUserInvestments';
import useLoyaltyRewards from '../common/useLoyaltyRewards';
import MessageCenter from '../common/MessageCenter';
import getToolTipTextForTableProperty from '../common/getToolTipTextForTableProperty';
import sortByNumberValue from '../helpers/sortByNumberValue';
import formatAndRoundPercentage from '../helpers/formatAndRoundPercentage';
import ErrorNotification from '../../../shared/components/ErrorNotification';
import checkApiErrorResponse from '../../../shared/services/checkApiErrorResponse';
import NewsBanner from '../common/NewsBanner';
import ArticlesBanner from '../common/ArticlesBanner';
import usePortfolioNews from '../common/usePortfolioNews';
import FeeDetailsModal from './FeeDetailsModal';
import { createInvestmentDataToLoyaltyRewardsMap, keyFormatter } from './helpers';

const { blueColor } = ClubResources;

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const TableHeaderComponent = ({ keyValue }) => {
  switch (keyValue) {
    case 0:
      return (
        <>
          <h4>Your Fund Investments</h4>
          {/* <h6><i>- Valued as of {PeriodEndDate()} -</i></h6> */}
          <h6><i>- Valued as of June 30, 2024 -</i></h6>
        </>
      );
    case 1:
      return (
        <>
          <h4>Your Syndication Fund Investments</h4>
          {/* <h6><i>- Valued as of {PeriodEndDate()} -</i></h6> */}
          <h6><i>- Valued as of June 30, 2024 -</i></h6>
        </>
      );
    default:
      return (<h4>Your Investments</h4>);
  }
};

TableHeaderComponent.propTypes = {
  keyValue: PropTypes.number.isRequired,
};

const headerExceptionList = [
  'fundCode', 'fund', 'baseFundCode', 'sortField -DontShow',
  'userId', 'Name', 'EndDate', 'dId', 'dType', 'id', 'dateInvested',
];

const generateColumnsRecent = (dashboardLabels, showLogo = false) => {
  const headers = [];
  const recentColumnNames = [
    'fundName',
    'capitalCommitment',
    'investableCapital',
    'totalManagementFeesPaidForLifeOfFund',
    'totalManagementFeesPaidForLifeOfFundPct',
    'feeReductionDetails',
    'currentInvestmentValue',
    // 'remainingCashToBeInvested',
    'distributionsToDate',
    'lifetimeTotalPositionValue',
  ];

  recentColumnNames.forEach((key) => {
    let formattedKey = keyFormatter(key);

    if (formattedKey === 'Total Management Fees Paid For Life Of Fund Pct') {
      formattedKey = 'Total Management Fees Paid for Life of Fund (%)';
    } else if (formattedKey === 'Total Management Fees Paid For Life Of Fund') {
      formattedKey = 'Total Management Fees Paid for Life of Fund ($)';
    }

    if (key === 'baseFundCode' && showLogo) {
      headers.unshift({ id: 'logo', label: '', alignment: true });
      return;
    }
    if (key === 'fundName') {
      headers.unshift({
        id: 'name',
        label: 'Fund Name',
        alignment: false,
        toolTip: getToolTipTextForTableProperty(formattedKey, dashboardLabels),
      });
      return;
    }
    if (headerExceptionList.includes(key)) {
      return;
    }

    headers.push({
      id: key,
      label: formattedKey,
      toolTip: getToolTipTextForTableProperty(formattedKey, dashboardLabels),
    });
  });

  // Change the label from "Investable Capital" to "Total Investable Capital"
  const investableCapitalHeader = headers.find((header) => header.id === 'investableCapital');
  if (investableCapitalHeader) {
    investableCapitalHeader.label = 'Total Investable Capital';
  }
  return headers;
};

const generateColumns = (investments, dashboardLabels, showLogo = false) => {
  let headers = [];
  const fundInvestments = investments[0];
  if (fundInvestments) {
    Object.keys(fundInvestments).forEach((key) => {
      let formattedKey = keyFormatter(key);
      if (formattedKey === 'Total Management Fees Paid For Life Of Fund Pct') {
        formattedKey = 'Total Management Fees Paid for Life of Fund (%)';
      } else if (formattedKey === 'Total Management Fees Paid For Life Of Fund') {
        formattedKey = 'Total Management Fees Paid for Life of Fund ($)';
      }

      if (key === 'baseFundCode' && showLogo) {
        headers.unshift({ id: 'logo', label: '', alignment: true });
        return;
      }
      if (key === 'fundName') {
        headers.unshift({
          id: 'name',
          label: 'Fund Name',
          alignment: false,
          toolTip: getToolTipTextForTableProperty(formattedKey, dashboardLabels),
        });
        return;
      }
      if (headerExceptionList.includes(key)) {
        return;
      }

      headers.push({
        id: key,
        label: formattedKey,
        toolTip: getToolTipTextForTableProperty(formattedKey, dashboardLabels),
      });
    });

    const reorderHeaders = (headers) => {
      const headerOrder = [
        'name',
        'capitalCommitment',
        'investableCapital',
        'totalManagementFeesPaidForLifeOfFund',
        'totalManagementFeesPaidForLifeOfFundPct',
        'feeReductionDetails',
        'currentInvestmentValue',
        // 'remainingCashToBeInvested',
        'distributionsToDate',
        'lifetimeTotalPositionValue',
      ];

      headers.push({
        id: 'feeReductionDetails',
        label: 'Fee Reduction Details',
        toolTip: 'Fee Reduction details',
      });

      const orderedHeaders = [];
      headerOrder.forEach((headerId) => {
        const item = headers.find((header) => header.id === headerId);
        if (item) {
          orderedHeaders.push(item);
        }
      });
      return orderedHeaders;
    };

    headers = reorderHeaders(headers);

    // Change the label from "Investable Capital" to "Total Investable Capital"
    const investableCapitalHeader = headers.find((header) => header.id === 'investableCapital');
    if (investableCapitalHeader) {
      investableCapitalHeader.label = 'Total Investable Capital';
    }

    return headers;
  }

  return headers;
};

const getLogo = (companies, investment) => {
  const company = companies.find((co) => co.code === investment.baseFundCode);
  return company ? (
    <Avatar
      style={{ width: '140px', borderRadius: 0, display: 'none' }}
      src={company.logoFilePath || 'https://s3.amazonaws.com/la-company-logos/avg_logo2.png'}
    />
  ) : '';
};

const formatLabelFromKey = (key) => key.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + key.replace(/([A-Z])/g, ' $1').slice(1);

const generateAvUnitsColumns = (investments, dashboardLabels) => {
  const headers = [];
  if (investments[0]) {
    Object.keys(investments[0]).forEach((key) => {
      const formattedKey = formatLabelFromKey(key);

      headers.push({
        id: key,
        label: formattedKey,
        toolTip: getToolTipTextForTableProperty(formattedKey, dashboardLabels),
      });
    });
  }
  return headers;
};


const InvestorDashboard = ({ isFetching, companies }) => {
  const [isLoading, setIsLoading] = useState(true);
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  const { portfolioNews } = usePortfolioNews(currentManagedUser ? `?userid=${currentManagedUser}` : '');
  const currentUser = useSelector((state) => {
    if (state.auth && state.auth.user && state.auth.authType === 'USER') {
      return state.auth.user;
    }
    if (state.auth && state.auth.authType === 'MANAGED' && state.currentManagedUser) {
      return state.auth.user.users.find((user) => user.id === state.currentManagedUser);
    }
    return undefined;
  });
  const dashboardLabels = useSelector((state) => state.dashboardLabels);
  const userInvestmentData = useUserInvestments(currentManagedUser ? `?userid=${currentManagedUser}` : '');
  const loyaltyRewardsData = useLoyaltyRewards(currentManagedUser ? `?userid=${currentManagedUser}` : '');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const { newsPage } = useFlags();

  useEffect(() => {
    if (userInvestmentData && loyaltyRewardsData && !isFetching) {
      setIsLoading(!isLoading);
    }
  }, [userInvestmentData, currentManagedUser, loyaltyRewardsData, isFetching]);

  const loyaltyInvestmentDataMap = createInvestmentDataToLoyaltyRewardsMap(userInvestmentData, loyaltyRewardsData);

  const SeeLoyaltyRewardsButton = ({ investment, setModalData, setModalOpen }) => (
    <div>
      <button
        type="button"
        style={{
          color: '#7B569B',
          hover: 'pointer',
          background: 'none',
          border: 'none',
        }}
        onClick={() => {
          setModalData(loyaltyInvestmentDataMap[investment.fundCode]);
          setModalOpen(true);
        }}
      >
        See details
      </button>
    </div>
  );

  const investmentDataMap = (investment) => ({
    name: investment.fundName,
    link: {
      pathname:
        currentManagedUser
          ? `/managed/funds/details/${investment.baseFundCode}-${investment.fundCode}`
          : `/funds/details/${investment.baseFundCode}-${investment.fundCode}`,
      state: investment,
    },
    logo: getLogo(companies, investment),
    feeReductionDetails: (loyaltyInvestmentDataMap[investment.fundCode] ? (
      <SeeLoyaltyRewardsButton investment={investment} setModalData={setModalData} setModalOpen={setModalOpen} />
    ) : null),
    ...investment,
    totalManagementFeesPaidForLifeOfFundPct: (formatAndRoundPercentage(investment.totalManagementFeesPaidForLifeOfFundPct)),
  });

  const recentInvestmentDataMap = (investment) => ({
    capitalCommitment: investment.capitalCommitment,
    currentInvestmentValue: 'Pending',
    distributionsToDate: 'Pending',
    fundName: investment.fundName,
    investableCapital: currencyFormatter.format(Number(investment.capitalCommitment.replace(/[^0-9.-]+/g, ''))
    - Number(investment.totalManagementFeedsPaidForLifeOfFund.replace(/[^0-9.-]+/g, ''))),
    lifetimeTotalPositionValue: 'Pending',
    // link: { pathname: '', state: { investment } },
    name: investment.fundName,
    remainingCashToBeInvested: 'Pending',
    totalManagementFeesPaidForLifeOfFund: investment.totalManagementFeedsPaidForLifeOfFund,
    totalManagementFeesPaidForLifeOfFundPct: (formatAndRoundPercentage(investment.totalManagementFeesPaidForLifeOfFundPct)),
    feeReductionDetails: (loyaltyInvestmentDataMap[investment.fundCode] ? (
      <SeeLoyaltyRewardsButton investment={investment} setModalData={setModalData} setModalOpen={setModalOpen} />
    ) : null),
  });

  let recentInvestmentsData = [];
  if (userInvestmentData && userInvestmentData[1] && userInvestmentData[1].length !== 0) {
    recentInvestmentsData = userInvestmentData.slice(1, 2);
  }

  return (
    <>
      <Helmet>
        <title>Investor Dashboard - Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <Container className="dashboard">
        <FeeDetailsModal openModal={modalOpen} data={modalData} closeModal={() => setModalOpen(false)} />
        <Card className="pb-0" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <CardBody
            style={{
              backgroundColor: blueColor.main,
              // borderTopWidth: '0px',
              // boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
              padding: '8px, 10px',
            }}
            className="py-3"
          >
            <Row>
              {(currentUser && (currentUser.first_name || currentUser.firstName)) && (
                <Col>
                  <h4 className="bold-text align-middle text-white">
                    {
                      `Investor Dashboard - 
                      ${currentUser.first_name ? currentUser.first_name : currentUser.firstName} 
                      ${currentUser.last_name ? currentUser.last_name : currentUser.lastName}`.toUpperCase()
                    }
                  </h4>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
        <>
          {(isLoading) && (
            <Row>
              <Col>
                <Card>
                  <CardBody style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: '8px, 10px' }}>
                    <Row>
                      <Loader />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {(!isLoading) && (
            <Row>
              <Col>
                <Card>
                  <CardBody className="pb-0 pt-3" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                    <Row>
                      {checkApiErrorResponse(userInvestmentData)
                        && (
                          <Col className="pb-4 pt-2">
                            <ErrorNotification />
                          </Col>
                        )}
                      {
                        !checkApiErrorResponse(userInvestmentData) && userInvestmentData && userInvestmentData[0]
                        && (
                          userInvestmentData[0].slice(2).map((total) => {
                            const totalLabel = formatLabelFromKey(total.key);
                            const toolTip = getToolTipTextForTableProperty(totalLabel, dashboardLabels);
                            return (
                              <Col key={total.key} className="text-center pb-4" sm={12} md>
                                <h3 style={{ color: '#646777' }}>
                                  {total.value}
                                </h3>
                                <div style={{ lineHeight: '30px' }}>
                                  <h5 style={{ display: 'inline' }}>{totalLabel}</h5>
                                  {totalLabel !== toolTip && (
                                  <Tooltip title={toolTip}>
                                    <IconButton aria-label={toolTip} size="small">
                                      <InfoCircleIcon />
                                    </IconButton>
                                  </Tooltip>
                                  )}
                                </div>
                              </Col>
                            );
                          })
                        )
                      }
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          { !newsPage && (
            <MessageCenter />
          )}
          { newsPage && (
            <Box
              sx={{
                backgroundImage: { xs: `url(${crosshatchVertical})`, md: `url(${crosshatchHorizontal})` },
                border: '1px solid',
                borderColor: 'darkGreen.main',
                borderRadius: 1,
                mb: '30px',
              }}
            >
              <Grid
                container
                direction={{ xs: 'column', md: 'row' }}
                spacing={0}
              >
                <Grid xs={12} md={7}>
                  <NewsBanner />
                </Grid>
                <Grid xs={12} md={5}>
                  <ArticlesBanner portfolioNews={portfolioNews} />
                </Grid>
              </Grid>
            </Box>
          )}
          {
            (!isLoading && userInvestmentData && companies
            && !checkApiErrorResponse(userInvestmentData))
            && recentInvestmentsData && recentInvestmentsData.length > 0
            && (
              recentInvestmentsData.map((investments, index) => (
                <Row key={uid('id', index)}>
                  <Col>
                    <Card>
                      <CardBody
                        className="pb-1"
                        style={{
                          // boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                          padding: '8px, 10px',
                        }}
                      >
                        <Row>
                          <Col>
                            <h4>Your Recent Investments</h4>
                          </Col>
                        </Row>
                        {!checkApiErrorResponse(userInvestmentData)
                        && (
                          <Row>
                            <Col>
                              <MaterialTable
                                columns={generateColumnsRecent(
                                  dashboardLabels,
                                  index === 0,
                                )}
                                dataArray={investments.map(recentInvestmentDataMap)}
                                preSort={{ field: 'fund', order: 'desc' }}
                                edit={null}
                                rowSize={25}
                                selectable={false}
                                showToolbar={false}
                                wrapHeaders
                                customSort={sortByNumberValue}
                              />
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )))
          }
          {
            (!isLoading && userInvestmentData && companies && !checkApiErrorResponse(userInvestmentData)) && (
              userInvestmentData.slice(2, 4).map((investments, index) => (
                <Row key={uid('id', index)}>
                  <Col>
                    <Card>
                      <CardBody
                        className="pb-1"
                        style={{
                          // boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                          padding: '8px, 10px',
                        }}
                      >
                        <Row>
                          <Col>
                            <TableHeaderComponent keyValue={index} />
                          </Col>
                        </Row>
                        {!checkApiErrorResponse(userInvestmentData)
                        && (
                          <Row>
                            <Col>
                              <MaterialTable
                                columns={generateColumns(
                                  investments.map((investment) => ({ ...investment })),
                                  dashboardLabels,
                                  index === 0,
                                )}
                                dataArray={investments.map(investmentDataMap)}
                                preSort={{ field: 'fund', order: 'desc' }}
                                edit={null}
                                rowSize={25}
                                selectable={false}
                                showToolbar={false}
                                wrapHeaders
                                customSort={sortByNumberValue}
                              />
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )))
          }
          {(!isLoading && userInvestmentData
          && userInvestmentData[4] && userInvestmentData[4].length > 0 && companies) && (
          <Row>
            <Col>
              <Card>
                <CardBody
                  className="pb-1"
                  style={{
                    // boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                    padding: '8px, 10px',
                  }}
                >
                  <Row>
                    <Col>
                      <h4>AV Unit Holder Details</h4>
                    </Col>
                  </Row>
                  {checkApiErrorResponse(userInvestmentData) && <ErrorNotification />}
                  {!checkApiErrorResponse(userInvestmentData)
                  && (
                    <Row>
                      <Col>
                        <MaterialTable
                          columns={generateAvUnitsColumns(
                            userInvestmentData[4],
                            dashboardLabels,
                          )}
                          dataArray={userInvestmentData[4]}
                          preSort={{ field: 'nameOfUnitHolder', order: 'asc' }}
                          edit={null}
                          rowSize={25}
                          selectable={false}
                          showToolbar={false}
                          wrapHeaders
                          customSort={sortByNumberValue}
                        />
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          )}
        </>
      </Container>
    </>
  );
};

InvestorDashboard.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  dispatch: state.dispatch,
  isFetching: state.companies.isFetching,
  companies: state.companies.companiesArray,
});

export default withRouter((connect(mapStateToProps)(InvestorDashboard)));
