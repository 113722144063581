import { loginInvestor, logoutInvestor, me } from './apiGateway';

export const INIT_SESSION = 'INIT_SESSION';
export const INIT_SESSION_SUCCESS = 'INIT_SESSION_SUCCESS';
export const INIT_SESSION_FAILURE = 'INIT_SESSION_FAILURE';

export const DO_SIGN_IN = 'DO_SIGN_IN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const DO_LOG_OUT = 'DO_LOG_OUT';
export const SIGNEDOUT = 'SIGNEDOUT';

export const AUTH_TYPE = { USER: 'USER', ADMIN: 'ADMIN', MANAGED: 'MANAGED' };

export function authenticated(user) {
  return {
    type: AUTHENTICATED,
    user,
  };
}

export async function initSession(dispatch, authType = AUTH_TYPE.USER) {
  try {
    dispatch({
      type: INIT_SESSION,
      apiPath: process.env.AVG_API_PATH,
      authType,
    });

    const authState = await me();

    if (authState === 'aborted') {
      throw new Error('Failed to validate session');
    }

    if (authState.isLoggedIn) {
      if (authType === AUTH_TYPE.MANAGED && !authState.managerUserEmail) {
        dispatch({ type: INIT_SESSION_FAILURE });
        return false;
      }
      if (authType === AUTH_TYPE.USER && authState.managerUserEmail) {
        dispatch({ type: INIT_SESSION_FAILURE });
        return false;
      }
      dispatch({
        ...authState,
        user: {
          ...authState.user,
          users: authState.user.managedUsers ? Object.values(authState.user.managedUsers) : [],
          isInvestor: 1,
          adminEmail: authState.adminEmail,
        },
        type: INIT_SESSION_SUCCESS,
      });
      return authState;
    }

    // TODO: determine how to do managed logins
    if (authType === AUTH_TYPE.MANAGED) {
      dispatch({ type: INIT_SESSION_FAILURE });
      return authState;
    }

    if (authState.user && authType === AUTH_TYPE.USER) {
      dispatch({
        user: {
          ...authState.user,
          isInvestor: 1,
          adminEmail: authState.adminEmail,
        },
        type: INIT_SESSION_SUCCESS,
      });
    }

    return authState;
  } catch (error) {
    dispatch({ type: INIT_SESSION_FAILURE });
    return false;
  }
}

export async function doSignIn(dispatch, credentials) {
  try {
    dispatch({ type: DO_SIGN_IN });

    const loginResponse = await loginInvestor(credentials);
    const userWithInvestorStatus = {
      ...loginResponse.user,
      isInvestor: 1,
    };
    dispatch(authenticated(userWithInvestorStatus));
    return userWithInvestorStatus;
  } catch (error) {
    dispatch({ type: SIGN_IN_ERROR });
    return false;
  }
}

export async function doLogOut(dispatch) {
  await logoutInvestor();
  dispatch({ type: DO_LOG_OUT });
}

export function signedout() {
  return { type: SIGNEDOUT };
}

export default {
  initSession,
  doSignIn,
  doLogOut,
  authenticated,
  signedout,
  AUTH_TYPE,
};
