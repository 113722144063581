/* eslint-disable import/prefer-default-export */
// Investor Portal Utility Functions

export const formatTokenAmount = (tokenAmount) => {
  // converts number to single decimal with k
  const formatedAmount = tokenAmount > 1000
    ? `${Math.floor(tokenAmount / 100) / 10}k`
    : tokenAmount;
  return formatedAmount;
};
