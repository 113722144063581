import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Card, Col,
} from 'reactstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import { makeStyles } from '@material-ui/core/styles';
// import logoFilePath from 'url:./../../../../images/basecamp.svg';
import BasecampHeader from './BasecampHeader';
import AboutBasecamp from './BasecampAboutSection';
import BasecampCompanies from './BasecampCompanies';
import findBasecampInvestment from '../helpers/findBasecampInvestment';
import useBasecampInvestments from '../../common/useBasecampInvestments';

const logoFilePath = 'https://la-company-logos.s3.amazonaws.com/1600699073_logo_company_30.png';
const useStyles = makeStyles(() => ({
  MuiAccordionDetails: {
    display: 'block',
  },
}));

const BasecampInvestments = ({ fundInvestmentData, companyDetails }) => {
  const classes = useStyles();
  const basecampInvestment = findBasecampInvestment(fundInvestmentData);
  const fundId = basecampInvestment ? basecampInvestment.id : '';
  const companies = useBasecampInvestments(fundId);

  return (basecampInvestment && fundInvestmentData && companies && (
    <Container className="dashboard" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
      <Row style={{ marginBottom: '30px' }}>
        <Col sm="12" md={12} xl={12}>
          <Card
            style={{
              backgroundColor: companyDetails.color || '#104866',
              paddingLeft: 0,
              paddingRight: 0,
              paddingBottom: 0,
              paddingTop: '20px',
              verticalAlign: 'middle',
            }}
            className="d-flex align-items-center"
          >
            <Accordion style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="custom-card-form-content"
                id="custom-card-form-header"
              >
                <Row>
                  <Col className="ml-2">
                    <h4>
                      Basecamp Investments
                    </h4>
                  </Col>
                </Row>
              </AccordionSummary>
              <AccordionDetails classes={{
                root: classes.MuiAccordionDetails,
              }}
              >
                <Card className="pb-0">
                  <BasecampHeader logoFilePath={logoFilePath} name={basecampInvestment.name} />
                  <AboutBasecamp />
                </Card>
                <BasecampCompanies companies={companies} />
              </AccordionDetails>
            </Accordion>
          </Card>
        </Col>
      </Row>
    </Container>
  ));
};

BasecampInvestments.propTypes = {
  fundInvestmentData: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  companyDetails: PropTypes.any.isRequired,
};

export default BasecampInvestments;
