/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col,
} from 'reactstrap';
import MatCard from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import useUserMessages from './useUserMessages';

const useStyles = makeStyles(() => ({
  mediaImage: {
    opacity: 0.33,
    // height: 150,
    // width: '30%',
    // marginLeft: '33%',
  },
  logo: {
    position: 'absolute',
    top: '45px',
    left: '5px',
    transition: '0.3s',
    width: 100,
    height: 100,
    boxShadow: '0 4px 12px 0 rgba(0,0,0,0.24)',
    borderRadius: '1rem',
    background: 'white',
  },
  cardAction: {
    padding: '4px 10px',
    color: '#fff',
    backgroundColor: '#7B569B',
    borderRadius: '4px',
  },
}));

const messageCard = (message, classes, userMessageData, setUserMessageData, type, path) => (
  !message.hide && (
    <Grid key={message.id} item xs={12} sm={6} md={4}>
      <MatCard
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <CardMedia
          className={classes.mediaImage}
          component="img"
          alt={message.headline_text}
          height="140"
          image={`${process.env.AVG_API_PATH}/file/preview-sign-file/${message.fk_image_resource_file_id}`}
          title={message.headline_text}
        />
        <IconButton
          size="small"
          style={{
            position: 'absolute', top: '5px', right: '5px',
          }}
          color="default"
          aria-label="upload picture"
          component="span"
          onClick={() => {
            setUserMessageData(
              { ...userMessageData, [type]: userMessageData[type].filter((m) => m.id !== message.id) },
            );
          }}
        />
        <Avatar
          className={classes.logo}
          src={`${process.env.AVG_API_PATH}/file/preview-sign-file/${message.fk_image_resource_file_id}`}
        />
        <CardContent style={{ height: 'auto' }}>
          <Typography gutterBottom variant="h5" component="h2">
            {message.headline_text}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {message.sub_text}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            variant="contained"
            style={{ marginLeft: 'auto' }}
            className={classes.cardAction}
            href={
              (message.action_url && message.action_url.indexOf('http') === -1)
                ? `${path}${message.action_url}` : message.action_url
            }
            target={(message.action_url && message.action_url.indexOf('http') === -1) ? '' : '_blank'}
          >
            {message.action_button_text}
          </Button>
        </CardActions>
      </MatCard>
    </Grid>
  ));

const mapForMessageCards = (
  messages, classes, userMessageData, setUserMessageData, type, displayAmount, path,
) => messages.slice(0, displayAmount).map((message) => messageCard(
  message, classes, userMessageData, setUserMessageData, type, path,
));

const checkIfMessagesExist = (userMessageData) => (userMessageData
  && ((userMessageData.messages && userMessageData.messages.length !== 0)
    || (userMessageData.liquidations && userMessageData.liquidations.length !== 0)));

const MessageCenter = () => {
  const defaultDisplayAmount = 3;
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [displayAmount, setDisplayAmount] = useState(defaultDisplayAmount);
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  const [userMessageData, setUserMessageData] = useUserMessages(
    currentManagedUser ? `?userid=${currentManagedUser}` : '',
  );

  const calculateDisplayAmount = () => (
    displayAmount === defaultDisplayAmount ? userMessageData.messages.length : defaultDisplayAmount
  );
  const classes = useStyles();

  useEffect(() => {
    if (userMessageData && isLoadingMessages) {
      setIsLoadingMessages(!isLoadingMessages);
    }
  }, [userMessageData]);

  return (
    <>
      {(!isLoadingMessages) && checkIfMessagesExist(userMessageData) && (
        <>
          <Row className="mb-4">
            <Col>
              <Grid container spacing={3}>
                {mapForMessageCards(
                  userMessageData.messages,
                  classes, userMessageData, setUserMessageData,
                  'messages', displayAmount, (currentManagedUser
                    ? 'https://investor.avgfunds.com/managed'
                    : 'https://investor.avgfunds.com/'),
                )}
                {/* {mapForMessageCards(
                  userMessageData.liquidations, classes, userMessageData, setUserMessageData, 'liquidations',
                )} */}
              </Grid>
            </Col>
          </Row>
          {
            userMessageData.messages.length > 3 && (
              <Row className="mb-4">
                <Col>
                  <Grid container>
                    <a href="#" onClick={() => setDisplayAmount(calculateDisplayAmount())}>
                      {displayAmount === defaultDisplayAmount
                        ? `See ${userMessageData.messages.length - 3} more`
                        : 'See Less'}
                    </a>
                  </Grid>
                </Col>
              </Row>
            )
          }
        </>
      )}
    </>
  );
};

export default MessageCenter;
