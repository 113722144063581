import * as React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import MuiTableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import StatusBox from './StatusBox';
import FundLogo from './FundLogo';
import TableHead from '../../common/TableHead';
import DistributionsHeadCells from './DistributionsHeadCells';

function DistributionsTableHead({ columnsToHide }) {
  const cells = (columnsToHide) ? DistributionsHeadCells.filter((cell) => (
    !columnsToHide.includes(cell.id)
  )) : DistributionsHeadCells;
  return cells && cells.length > 0 && <TableHead headCells={cells} />;
}

DistributionsTableHead.propTypes = {
  columnsToHide: PropTypes.arrayOf(PropTypes.string),
};

DistributionsTableHead.defaultProps = {
  columnsToHide: null,
};

function CurrentDistributionTableHead({ label, fundCode }) {
  return (
    <MuiTableHead>
      <TableRow
        sx={{
          height: '60px',
          pt: 0,
          pb: 0,
        }}
      >
        <TableCell
          key="table-cell1234"
          align="left"
          colSpan={12}
          sx={{ height: '20px', p: 0, verticalAlign: 'baseline' }}

        >
          <Stack
            flexDirection="row"
            alignItems="center"
            height="100%"
          >
            <FundLogo
              label={label}
              fundCode={fundCode}
              sx={{
                marginLeft: '20px', marginRight: '10px',
              }}
            />
            <span style={{ fontFamily: 'Archivo', fontSize: '20px', fontWeight: '600' }}>{label}</span>
          </Stack>

        </TableCell>
      </TableRow>
    </MuiTableHead>
  );
}

CurrentDistributionTableHead.propTypes = {
  label: PropTypes.string.isRequired,
  fundCode: PropTypes.string.isRequired,
};

const DistributionTableCell = styled(TableCell)({
  fontWeight: 500,
  height: 37,
  paddingTop: 0,
  paddingBottom: 0,
  margin: 0,
});

function TotalRow({ totalAmount }) {
  return (
    <TableRow
      sx={{
        bgcolor: 'table.light',
        '&&:last-child td, &:last-child th': { border: 0 },
      }}
      align="center"
    >
      <TableCell
        align="right"
        colSpan={12}
        sx={{
          height: '42px',
          p: 0,
          pr: '16px',
        }}
      >Total: {totalAmount }
      </TableCell>
    </TableRow>
  );
}

TotalRow.propTypes = {
  totalAmount: PropTypes.string.isRequired,
};

function OtherDistributionsTableHead() {
  return (
    <MuiTableHead>
      <TableRow
        sx={{
          height: '60px',
          pt: 0,
          pb: 0,
        }}
      >
        <TableCell
          key="table-cell1234"
          align="left"
          colSpan={6}
          sx={{ height: '20px', p: 0, verticalAlign: 'baseline' }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            height="100%"
          >
            <span style={{
              fontFamily: 'Archivo', fontSize: '20px', fontWeight: '600', marginLeft: '20px',
            }}
            >Other
            </span>
          </Stack>

        </TableCell>
      </TableRow>
    </MuiTableHead>
  );
}

DistributionTableCell.defaultProps = {
  align: 'left',
};

function OtherDistributionsTableRows({ data }) {
  return data.map((item) => (
    <TableRow
      key={`table-row${item.title}`}
      sx={{ bgcolor: '#E6E6E6' }}
      align="center"
    >
      <TableCell
        key={`table-cell${item.title}`}
        align="left"
        colSpan={6}
        sx={{ height: '20px', p: 0, pl: '20px' }}
      >{ item.title }
      </TableCell>
      <TableCell
        key={`table-cell${item.amount}`}
        align="right"
        colSpan={6}
        sx={{ height: '20px', p: 0, pr: '20px' }}
      >{ item.amount }
      </TableCell>
    </TableRow>
  ));
}

OtherDistributionsTableRows.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  })).isRequired,
};

const DistributionDataTableHead = ({
  showOtherDistributions,
  showCurrentQuarterDistributions,
  fundName,
  fundCode,
  distributionDataLength,
}) => {
  // Other table in current distributions page
  if (showOtherDistributions) {
    return <OtherDistributionsTableHead />;
  }

  // Fund tables in current distributions page
  if (showCurrentQuarterDistributions) {
    return (
      <CurrentDistributionTableHead
        label={fundName}
        fundCode={fundCode}
      />
    );
  }

  // Show all distributions page
  return (
    <DistributionsTableHead
      rowCount={distributionDataLength}
    />
  );
};

DistributionDataTableHead.propTypes = {
  showOtherDistributions: PropTypes.bool,
  showCurrentQuarterDistributions: PropTypes.bool,
  fundName: PropTypes.string.isRequired,
  fundCode: PropTypes.string.isRequired,
  distributionDataLength: PropTypes.number.isRequired,
};

DistributionDataTableHead.defaultProps = {
  showOtherDistributions: false,
  showCurrentQuarterDistributions: false,
};

const DistributionTableRows = ({
  distributionData,
  showCurrentQuarterDistributions,
}) => distributionData.map((row, index) => {
  if (row.divider) {
    return (
      <TableRow
        key={`table-row${row.divider}`}
        sx={{ bgcolor: '#E6E6E6' }}
        align="center"
      >
        <TableCell
          key={`table-cell${row.divider}`}
          align="center"
          colSpan={12}
          sx={{ height: '20px', p: 0 }}
        >{row.divider === 'Pending' ? null : row.divider}
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow
      hover
      tabIndex={-1}
      key={uid(row)}
      sx={{
        backgroundColor: index % 2 ? 'table.light' : 'table.main',
        '&&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      {!showCurrentQuarterDistributions && <DistributionTableCell>{row.fundName}</DistributionTableCell>}
      <DistributionTableCell>{row.companyName}</DistributionTableCell>
      <DistributionTableCell>{row.payee}</DistributionTableCell>
      <DistributionTableCell>{row.paymentMethod}</DistributionTableCell>
      <DistributionTableCell>{row.datePaid ? row.datePaid : ''}</DistributionTableCell>
      <DistributionTableCell>
        <Box>
          <StatusBox status={row.status} />
        </Box>
      </DistributionTableCell>
      <DistributionTableCell align="right">{row.amount}</DistributionTableCell>
    </TableRow>
  );
});

DistributionTableRows.propTypes = {
  distributionData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      divider: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.shape({
      companyName: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      payee: PropTypes.string,
      paymentMethod: PropTypes.string.isRequired,
      datePaid: PropTypes.string,
    }),
  ])).isRequired,
};

export default function DistributionsTable({
  distributionData,
  fundName,
  showCurrentQuarterDistributions,
  fundCode,
  otherDistributionsData,
  showOtherDistributions,
  fundTotalDistributions,
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'border.dark',
          borderRadius: 2,
          mb: 4,
          mt: 1.5,
        }}
      >
        <Table
          aria-labelledby="distributions-table"
          sx={{
            minWidth: 750,
          }}
        >
          <DistributionDataTableHead
            showOtherDistributions={showOtherDistributions}
            showCurrentQuarterDistributions={showCurrentQuarterDistributions}
            fundName={fundName}
            fundCode={fundCode}
            distributionDataLength={distributionData.length}
          />
          {showCurrentQuarterDistributions && <DistributionsTableHead columnsToHide={['fundName']} />}
          {showOtherDistributions && <OtherDistributionsTableRows data={otherDistributionsData} />}
          {(!showOtherDistributions && (
          <TableBody>
            <DistributionTableRows
              distributionData={distributionData}
              showCurrentQuarterDistributions={showCurrentQuarterDistributions}
            />
            {showCurrentQuarterDistributions && (
              <TotalRow
                totalAmount={fundTotalDistributions}
              />
            )}
          </TableBody>
          ))}
        </Table>
      </TableContainer>
    </Box>
  );
}

DistributionsTable.propTypes = {
  distributionData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      divider: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.shape({
      companyName: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      payee: PropTypes.string,
      paymentMethod: PropTypes.string.isRequired,
      datePaid: PropTypes.string,
    }),
  ])).isRequired,
  fundName: PropTypes.string,
  showCurrentQuarterDistributions: PropTypes.bool.isRequired,
  fundCode: PropTypes.string,
  otherDistributionsData: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  })),
  showOtherDistributions: PropTypes.bool,
  fundTotalDistributions: PropTypes.string,
};

DistributionsTable.defaultProps = {
  fundCode: null,
  fundName: null,
  otherDistributionsData: [],
  showOtherDistributions: false,
  fundTotalDistributions: '',
};
