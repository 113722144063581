import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Card, CardBody,
} from 'reactstrap';
import {
  Button, Tooltip, DialogActions, Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import moment from 'moment';
import SearchBar from 'material-ui-search-bar';
import { Loader, MaterialTable } from '@alumni-ventures-group/react-shared/lib/components';
import { debounce } from 'lodash';
import WebsocketService from '../../../../shared/services/websocketService';
import sortK1Table from '../../helpers/sortK1Table';
import StatementAlert from './StatementAlert';
import { exportK1s, exportK1 } from '../../../../shared/services/apiGateway';

const columnTitlesReports = [
  {
    alignment: 'left',
    id: 'k1FileLink',
    label: 'Status',
  },
  {
    alignment: 'left',
    id: 'k1FundName',
    label: 'Fund Name',
  },
  {
    alignment: 'left',
    id: 'k1ReportName',
    label: 'K-1 Prepared for Investor',
  },
  {
    alignment: 'left',
    id: 'k1UploadDate',
    label: 'Date Uploaded',
  },
];

const Modal = ({
  title, description, showModal, closeModal, errorState, showModalLoading,
}) => (
  <Dialog
    open={showModal}
    onClose={() => { }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {description}
      </DialogContentText>
      {showModalLoading && (
        <Row className="m-4">
          <Loader />
        </Row>
      )}
    </DialogContent>
    <DialogActions>
      {errorState && (
        <Button autoFocus onClick={closeModal}>
          Close
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

const downloadK1File = debounce(async (fileKey, currentManagedUserId) => {
  // open new tab first to prevent popup blocker
  const newTab = window.open('', '_blank');
  newTab.document.write('Preparing K-1.');
  const response = await exportK1(currentManagedUserId ? `?userid=${currentManagedUserId}` : '', fileKey);
  if (response && response.fileUrl) {
    // open file url in the new tab once loaded
    newTab.location.href = response.fileUrl;
  } else {
    // response error, close tab
    newTab.close();
  }
}, 375);

function returnReportsDataObject(reports, currentManagedUserId) {
  return reports.map((report) => ({
    id: report.fileKey || report.id,
    k1Status: report.fileKey ? 'Available' : 'Pending',
    k1FileLink: {
      renderCustomCell: () => (report.fileKey
        ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            onClick={(event) => {
              downloadK1File(report.fileKey, currentManagedUserId);
              event.stopPropagation();
              return false;
            }}
          >Available
          </a>
        )
        : 'Pending'),
    },
    k1FundName: report.fundName,
    k1ReportName: report.entityName,
    k1UploadDate: report.lastModified ? moment(report.lastModified).format('MMMM DD, YYYY') : null,
    k1FileKey: report.fileKey,
  }));
}

const searchRows = (reports, searchText) => {
  let searchResults = [...reports];
  if (searchText) {
    const lowercaseSearchText = searchText.toLowerCase();
    searchResults = reports.filter((report) => (
      report.k1ReportName.toLowerCase().includes(lowercaseSearchText)
      || report.k1FundName.toLowerCase().includes(lowercaseSearchText)
      || (report.k1UploadDate ? report.k1UploadDate.toLowerCase().includes(lowercaseSearchText) : false)
      || report.k1Status.toLowerCase().includes(lowercaseSearchText)
    ));
  }
  return searchResults;
};

const presortK1Reports = (reports) => {
  const availableReports = reports.filter((report) => report.k1Status === 'Available');
  const pendingReports = reports.filter((report) => report.k1Status === 'Pending');

  const data1 = availableReports.sort((a, b) => Date.parse(b.k1UploadDate) - Date.parse(a.k1UploadDate));
  const data2 = pendingReports.sort((a, b) => {
    if (!a.k1UploadDate) {
      return 1;
    }
    if (!b.k1UploadDate) {
      return -1;
    }
    return Date.parse(b.k1UploadDate) - Date.parse(a.k1UploadDate);
  });
  return data1.concat(data2);
};

const errorTitle = 'An error occured';

const errorMessage = (
  <>We were unable to create your download. Please refresh the page and try again.
    If the issue persists please contact <a href="mailto:investorrelations@av.vc">investorrelations@av.vc</a>
  </>
);

const K1Table = ({ year, k1Reports, currentManagedUserId }) => {
  const websocketRef = useRef();
  const timeoutRef = useRef();
  const websocketTimeout = 60000; // 1 minute

  const processedK1Reports = returnReportsDataObject(k1Reports, currentManagedUserId);
  const presortedK1Reports = presortK1Reports(processedK1Reports);

  const [searchValue, setSearchValue] = useState('');
  const [rowsSelected, setRowsSelected] = useState(
    processedK1Reports.filter((report) => report.k1Status === 'Available').map((report) => [report.id, true]),
  );
  const [downloadButtonText, setDownloadButtonText] = useState('Download K-1');
  const [isWebsocketConnected, setIsWebsocketConnected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState([]);
  const [showModalLoading, setShowModalLoading] = useState(false);

  const disabledRows = processedK1Reports.filter(
    (report) => report.k1Status !== 'Available',
  ).map((report) => report.id);

  const showWhatsSelected = (rows) => {
    const temp = [];
    rows.forEach((value, key) => {
      if (value && !disabledRows.includes(key)) {
        temp.push([key, true]);
      }
    });
    setRowsSelected(temp);
  };

  const getSelectedK1sFileKeys = () => {
    const selectedRowIds = rowsSelected.map((row) => row[0]);
    return searchRows(processedK1Reports, searchValue)
      .filter((report) => selectedRowIds.includes(report.id)).map((report) => report.k1FileKey);
  };

  const downloadZip = (fileUrl) => {
    if (fileUrl) {
      // using _self will download the zip file without triggering the browser's popup blocker
      window.open(fileUrl, '_self');
    }
    setShowModal(false);
    setShowModalLoading(false);
    setModalText([]);
    setIsWebsocketConnected(false);
    websocketRef.current.disconnect();
  };

  const setErrorStatus = () => {
    setModalText([errorTitle,
      errorMessage]);
    setShowModalLoading(false);
    setShowModal(true);
    websocketRef.current.disconnect();
    setIsWebsocketConnected(false);
  };

  const handleGetConnectionIdResponse = async (connectionId) => {
    let k1exports;

    try {
      k1exports = await exportK1s(currentManagedUserId
        ? `?userid=${currentManagedUserId}` : '', connectionId, getSelectedK1sFileKeys(), year);
    } catch (e) {
      setErrorStatus();
    }

    if (k1exports === false) {
      setErrorStatus();
    }
  };

  const handleWebsocketResponse = async (response) => {
    const message = JSON.parse(response.data);
    setIsWebsocketConnected(true);
    if (message.action === 'getConnectionId') {
      await handleGetConnectionIdResponse(message.connectionId);
    } else if (message.action === 'k1ExportComplete') {
      const { data } = message;
      if (data.fileUrl) {
        downloadZip(data.fileUrl);
      } else {
        setErrorStatus();
      }
    }
  };

  const requestDownload = async () => {
    if (getSelectedK1sFileKeys().length === 0) {
      return null;
    }
    setShowModal(true);
    setShowModalLoading(true);
    setModalText(['Preparing K-1s for download.',
      'We are preparing K-1s for download, please do not navigate away from or refresh page.']);
    if (isWebsocketConnected) {
      return null;
    }

    // Show error if file hasn't been downloaded after 1 minute
    timeoutRef.current = setTimeout(() => {
      setErrorStatus();
    }, websocketTimeout);
    websocketRef.current = new WebsocketService(
      process.env.K1_EXPORT_WEBSOCKET_URL,
      () => {
        websocketRef.current.sendMessage(JSON.stringify({ action: 'getConnectionId' }));
      },
      () => {
        // clear error timeout when websocket closes
        clearTimeout(timeoutRef.current);
      },
      setErrorStatus,
      handleWebsocketResponse,
    );
  };

  useEffect(() => {
    if (rowsSelected.length > 1) {
      setDownloadButtonText('Download K-1s');
    } else {
      setDownloadButtonText('Download K-1');
    }
  }, [rowsSelected]);

  useEffect(() => () => {
    // cancel timer on component unmount
    clearTimeout(timeoutRef.current);
  }, []);

  return (
    <Row>
      <Col>
        <Card
          style={{
            marginTop: 20,
          }}
        >
          <CardBody
            className="pb-1"
            style={{
              // boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
              padding: '8px, 10px',
            }}
          >
            <Row>
              <Col>
                <h4>{`${year} K-1 Availability Center ${year === 2023 ? '(NEW)' : ''}`}</h4>
              </Col>
            </Row>
            {year === 2023 && (
              <StatementAlert
                title={(
                  <>
                    {`We have improved our K-1 delivery process for ${year}. READ FAQ`}{' '}
                    <a href="https://www.av.vc/blog/k1-faqs#faqs" target="_blank" rel="noopener noreferrer">HERE</a>.
                  </>
                )}
                body={(
                  <>
                    <ul>
                      <li>
                        {`You are now able to view all K-1s prepared on your behalf for the ${year} tax year.`}
                      </li>
                      <li>
                        We will update the page daily during the K-1 preparation
                        period on the availability of your specific AV Fund K-1.
                      </li>
                      <li>
                        You are now able to download selected K-1s individually
                        or grouped for easier sharing with your tax professional.
                      </li>
                    </ul>
                  </>
                )}
              />
            )}
            <Row>
              <Col>
                <SearchBar
                  placeholder="Search K-1s"
                  value={searchValue}
                  onChange={(value) => setSearchValue(value)}
                  onCancelSearch={() => setSearchValue('')}
                  style={{ marginTop: 20 }}
                />
                <MaterialTable
                  columns={columnTitlesReports}
                  dataArray={searchRows(presortedK1Reports, searchValue)}
                  edit={null}
                  selectable
                  rowSize={k1Reports.length}
                  rowsPerPage={k1Reports.length}
                  showToolbar={false}
                  customSort={sortK1Table}
                  showWhatsSelected={showWhatsSelected}
                  hidePagination
                  preselectedRows={rowsSelected}
                  disabledCheckboxRows={disabledRows}
                  doNotSort
                  customStyling={{ header: { checkboxColor: '#a9a9a9' } }}
                />
                <Tooltip
                  title="Select from available K-1s then click this button to download a compressed zip file."
                  placement="right"
                >
                  <span>
                    <Button
                      id="k1DownloadButton"
                      variant="contained"
                      color="primary"
                      disabled={rowsSelected.length === 0}
                      style={{
                        marginTop: 20,
                        paddingTop: 16,
                        paddingBottom: 16,
                        float: 'left',
                      }}
                      onClick={
                        () => {
                          requestDownload();
                        }
                      }
                    >
                      {downloadButtonText}
                    </Button>
                  </span>
                </Tooltip>
              </Col>
              {(showModal)
                && (
                  <Modal
                    title={modalText[0]}
                    description={modalText[1]}
                    showModal={showModal}
                    closeModal={() => {
                      setShowModalLoading(false);
                      setShowModal(false);
                      setModalText([]);
                    }}
                    // Check if modal's title is error message
                    // to determine error vs download modal
                    errorState={modalText[0] === errorTitle}
                    showModalLoading={showModalLoading}
                  />
                )}
            </Row>
          </CardBody>
        </Card>
      </Col>

    </Row>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  errorState: PropTypes.bool.isRequired,
  showModalLoading: PropTypes.bool.isRequired,
};

K1Table.propTypes = {
  year: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  k1Reports: PropTypes.array.isRequired,
  currentManagedUserId: PropTypes.string.isRequired,
};

export default K1Table;
