/* eslint-disable react/destructuring-assignment, react/prop-types */
import React, { useEffect } from 'react';
import {
  Route, Switch, Redirect, useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import ReactGA from 'react-ga';
import PrivateWrapper from '../PrivateWrapper';
// import MainWrapper from '../MainWrapper';
import WrappedRoutes from './WrappedRoutes';
import Layout from '../../Layout/index';
import LogIn from '../../LogIn/index';
import InvestorLogin from '../../Investor/InvestorLogin';
import Distributions from '../../Investor/Distributions';
import News from '../../Investor/News';
import GoogleAnalytics from './GoogleAnalytics';

const Logout = () => {
  window.location.replace(`${process.env.AVG_API_PATH}/admin/default/pre-logout`);
  return null;
};

const RedirectToLogin = () => {
  window.location.replace('/login');
  return null;
};

// const InvestorHome = (user) => {
//   console.log(user);
//   let path = `${window.location.origin}/`;
//   if (!user) {
//     path = `${window.location.origin}/login`;
//   }
//   window.location.replace(path);
//   return null;
// };

const QcpRedirect = () => {
  const { urlSlug } = useParams();
  window.location.replace(`${process.env.AVG_API_PATH}/fund/${urlSlug}`);
  return null;
};

const Router = () => {
  const user = useSelector((state) => state.auth.user);
  const ldClient = useLDClient();
  const { distributionsPage } = useFlags();
  const { newsPage } = useFlags();

  useEffect(() => {
    if (user) {
      // Set user in launch darkly
      ldClient.identify({
        kind: 'user',
        key: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email1,
      });
      // set user in google analytics
      ReactGA.set({ userId: `${user.id}` });
      // set user in google tag manager
      window.dataLayer.push({ userId: `${user.id}` });
    }
  }, [user]);

  return (
    <PrivateWrapper>
      <main>
        <div>
          <Layout />
          <Switch>
            {/* <Route exact path="/" component={Home} /> */}
            <Route exact path="/login" component={InvestorLogin} />
            <Route exact path="/sign-up" component={RedirectToLogin} />
            <Route exact path="/log_in" component={LogIn} />
            {/* <Route
              exact
              path="/"
              component={() => InvestorHome(user)}
            /> */}
            <Route exact path="/redirect">
              <Redirect to="/" />
            </Route>
            <Route path="/investment-in-progress/:urlSlug" component={QcpRedirect} />
            { distributionsPage && <Route exact path="/distributions" component={Distributions} /> }
            { newsPage && <Route exact path="/news" component={News} /> }
            <Route path="/" component={WrappedRoutes} />
          </Switch>
          <GoogleAnalytics />
        </div>
      </main>
    </PrivateWrapper>
  );
};

export default Router;
