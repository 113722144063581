import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import { MobileHeadCells } from '../PortfolioNewsHeadCells';
import MobileTableRow from '../../../common/MobileTableRow';
import { getCellContents, MobileNewsItemBox } from './shared';

const MobilePortfolioNewsItem = ({ row }) => (
  <Link
    href={row.url}
    target="_blank"
    rel="noopener noreferrer"
    variant="body1"
    sx={{
      '&:hover': {
        color: '#191919',
      },
    }}
  >
    <MobileNewsItemBox source={row.source}>
      <MobileTableRow />
      {MobileHeadCells.map((headCell) => (
        <MobileTableRow
          key={headCell.id}
          title={headCell.label}
          contents={getCellContents(headCell.id, row, false)}
          direction={headCell.id === 'headline' ? 'column' : 'row'}
          bold={headCell.bold}
        />
      ))}
    </MobileNewsItemBox>
  </Link>
);

MobilePortfolioNewsItem.propTypes = {
  row: PropTypes.object.isRequired,
};

export default MobilePortfolioNewsItem;
