import React from 'react';
import {
  Box, Collapse, Typography, Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PortfolioNewsFundCell from '../PortfolioNewsFundCell';
import ManagingPartnerAuthorCard from '../ManagingPartnerAuthorCard';

export const getCellContents = (id, row, isExpanded) => {
  if (id === 'fund') {
    return <PortfolioNewsFundCell funds={row[id]} isMobile />;
  }
  if (id === 'headline' && row.source === 'MP Update') {
    return (
      <Stack direction="column" spacing={0}>
        <Typography variant="body2" sx={{ fontWeight: 600 }}>{row[id]}</Typography>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
            <Typography
              onClick={(e) => e.stopPropagation()}
              variant="body2Inter"
              dangerouslySetInnerHTML={{
                __html: row.content,
              }}
            />
            {row.author && <ManagingPartnerAuthorCard author={row.author} isMobile />}
          </Stack>
        </Collapse>
      </Stack>
    );
  }
  return row[id];
};

export const MobileNewsItemBox = styled(Box)(({ theme, source }) => ({
  marginTop: '3px',
  marginBottom: '3px',
  borderRadius: '4px 8px 8px 4px',
  border: `2px solid ${theme.palette.border.dark}`,
  overflow: 'hidden',
  borderLeft: source === 'MP Update'
    ? `3px solid ${theme.palette.secondary.main}`
    : `3px solid ${theme.palette.lightGrey.dark}`,
}));
