export default function sortByNumberValue(data, property, order) {
  // define list of properties this custom sort applies to
  // basically all the ones with $ plus totalFundDistributedValue, numberOfUnitsOwned, etc.
  // which doesn't have a dollar sign
  const applicableProperties = [
    'fundInvestmentAtCost', 'fundGain/loss', 'totalFundValuation',
    'totalFundDistributedValue', 'yourInvestmentAtCost', 'yourInvestmentDistribution',
    'yourInvestmentGain/loss', 'capitalCommitment', 'yourCurrentInvestmentValue',
    'remainingCashToBeInvested', '12MonthsManagementFees', 'totalManagementFeesPaidForLifeOfFund',
    'currentUnitPrice', 'currentValuation', 'numberOfUnitsOwned', 'investableCapital', 'lifetimeTotalValue',
  ];

  // just return data if this is a date column
  if (`${property}`.toLowerCase().includes('date')) {
    data.sort((a, b) => {
      if (order === 'desc') {
        return new Date(b[property]).getTime() - new Date(a[property]).getTime();
      }
      return new Date(a[property]).getTime() - new Date(b[property]).getTime();
    });
    return data;
  }

  // define indicators for negatives values, which include: -, (, and )
  const denotesNegativeNumber = (str) => str.includes('-') || str.includes('(') || str.includes(')');

  const getNumberValue = (str) => Number(`${str}`.replace(/[^0-9.]+/g, '')) * (denotesNegativeNumber(`${str}`) ? -1 : 1);

  const checkIfValueIsANumber = (value) => {
    const testValue = value.replace(/[-(),.$ ]/g, ''); // characters applicable to us
    return !Number.isNaN(testValue) && !Number.isNaN(parseFloat(testValue));
  };
  const guessIfColumnHasNumberValuesOnly = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      if (item[property] && !checkIfValueIsANumber(`${item[property]}`)) {
        return false;
      }
    }
    return true;
  };

  if (applicableProperties.includes(`${property}`.trim()) || guessIfColumnHasNumberValuesOnly()) {
    data.sort((a, b) => {
      if (order === 'asc') {
        return getNumberValue(a[property]) > getNumberValue(b[property]) ? 1 : -1;
      }
      return getNumberValue(a[property]) > getNumberValue(b[property]) ? -1 : 1;
    });
  }
  return data;
}
