import * as React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack, Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import MobileAccordion from './MobileAccordion';
import FundLogo from './FundLogo';

export default function CurrentDistributionAccordion({
  distributionData, fundCode, fundName, fundTotalDistributions,
}) {
  const [expanded, setExpanded] = React.useState('');
  const handleChange = (panel) => {
    if (expanded === panel) {
      setExpanded('');
    } else {
      setExpanded(panel);
    }
  };

  const accordions = distributionData.map((data) => {
    const key = uid(data);
    return (
      <MobileAccordion
        panelName={key}
        row={data}
        handleChange={handleChange}
        expanded={expanded}
        key={key}
        fundTotalDistributions={fundTotalDistributions}
        currentDistributionsOnly
      />
    );
  });

  return (
    <Accordion
      sx={{
        m: 0,
        mb: '8px',
        borderRadius: '8px',
        backgroundColor: 'mobileTable.light',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'border.dark',
        overflow: 'hidden',
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        '&:first-of-type': {
          borderRadius: '8px',
        },
      }}
    >
      <AccordionSummary
        sx={{
          '& > div': {
            fontFamily: 'Inter',
            fontWeight: '700',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            padding: 0,
            margin: 0,
          },
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Box>
            <FundLogo label={fundCode} fundCode={fundCode} />
            <span style={{ textTransform: 'capitalize' }}>{ fundName }</span>
          </Box>

          <Box
            alignItems="center"
          >
            {fundTotalDistributions}
          </Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        id="panel1a-content"
        sx={{
          p: 0,
        }}
      >
        { accordions }
      </AccordionDetails>
    </Accordion>
  );
}

CurrentDistributionAccordion.propTypes = {
  distributionData: PropTypes.arrayOf(PropTypes.object).isRequired,
  fundCode: PropTypes.string.isRequired,
  fundName: PropTypes.string.isRequired,
  fundTotalDistributions: PropTypes.string.isRequired,
};
