import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const AdminBannerBox = styled(Box)(({ isSticky }) => ({
  backgroundColor: 'yellow',
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '12px 50px',
  ...(isSticky ? {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 999,
    boxShadow: '0 2px 24px 0 rgb(0 0 0 / 15%)',
  } : {}),
}));
const AdminBanner = ({ adminEmail }) => {
  const [isSticky, setIsSticky] = useState(false);

  /* Method that will fix header after a specific scrollable */
  const checkSticky = () => {
    const scrollTop = window.scrollY;
    setIsSticky(scrollTop >= 58);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkSticky);
    return () => {
      window.removeEventListener('scroll', checkSticky);
    };
  }, []);

  return (
    <AdminBannerBox isSticky={isSticky}>
      <Typography sx={{ fontSize: 22, fontWeight: 600, fontFamily: 'Inter' }}>
        Logged in as admin: {adminEmail}
      </Typography>
    </AdminBannerBox>
  );
};

AdminBanner.propTypes = {
  adminEmail: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
});

export default withRouter(connect(mapStateToProps)(AdminBanner));
