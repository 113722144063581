import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TablePagination as MuiTablePagination,
} from '@mui/material';

const TablePagination = ({
  page = 0,
  setPage = () => {},
  rows = [],
  count = 0,
  setVisibleRows = () => {},
  fetchNewRows = () => {},
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    if ((newPage + 1) * rowsPerPage >= rows.length) {
      fetchNewRows({ start: (newPage + 1) * rowsPerPage, count: rowsPerPage });
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    if (newRowsPerPage > rows.length) {
      fetchNewRows({ start: 0, count: rowsPerPage });
    }
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  useEffect(() => {
    setVisibleRows(rows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    ));
  }, [rows, page, rowsPerPage]);

  return (
    <MuiTablePagination
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      colSpan={12}
      count={count ?? rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

TablePagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  count: PropTypes.number.isRequired,
  setVisibleRows: PropTypes.func.isRequired,
  fetchNewRows: PropTypes.func.isRequired,
};

export default TablePagination;
