import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell, TableHead as MuiTableHead, TableRow, Stack,
} from '@mui/material';
import Tooltip from './Tooltip';

const TableHead = ({
  headCells,
  sortSelect = null,
  pagination = null,
  stickyHeader = false,
}) => (
  <MuiTableHead
    sx={stickyHeader ? { position: 'sticky', top: 0, backgroundColor: 'white' } : {}}
  >
    <TableRow>
      {!!sortSelect && (
        <TableCell variant="head">
          {sortSelect}
        </TableCell>
      )}
      {!!pagination && (
        <>
          {pagination}
        </>
      )}
    </TableRow>
    <TableRow
      sx={{
        height: '37px',
        pt: 0,
        pb: 0,
      }}
    >
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? 'right' : 'left'}
          sx={{
            fontFamily: 'Inter',
            fontSize: 12,
            fontWeight: 700,
            textTransform: 'uppercase',
            p: '0 16px',
            ...(headCell.width ? {
              width: headCell.width,
            } : {
              width: '10%',
            }),
          }}
        >
          <Stack
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ display: 'inline-flex' }}
          >
            {headCell.tooltip && (
              <Tooltip title={headCell.tooltip} />
            )}
            {headCell.label}
          </Stack>
        </TableCell>
      ))}
    </TableRow>
  </MuiTableHead>
);

TableHead.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    numeric: PropTypes.bool,
    label: PropTypes.string,
    tooltip: PropTypes.element,
    width: PropTypes.string,
    bold: PropTypes.bool,
  })).isRequired,
  sortSelect: PropTypes.element,
  pagination: PropTypes.element,
  stickyHeader: PropTypes.bool,
};

TableHead.defaultProps = {
  sortSelect: null,
  pagination: null,
  stickyHeader: false,
};

export default TableHead;
