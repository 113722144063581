import { styled } from '@mui/material/styles';
import {
  Typography, Container, Box, Pagination, FormControl
} from '@mui/material';

export const VerticalCenteredContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flex: 1,
});

export const DataText = styled(Typography)({
  '&&': {
    marginTop: 40,
    marginBottom: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'gray',
    fontSize: '14px',
    textAlign: 'center',
  },
});

export const HorizontalCenteredContent = styled(Container)(() => ({
  padding: '10px',
  display: 'flex',
  direction: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const PortfolioNewsStyledBox = styled(Box)({
  border: '1px solid #404040',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  borderRadius: '8px',
  backgroundColor: 'white',
  minHeight: 500,
});

export const StyledFormControl = styled(FormControl)({
  backgroundColor: 'white',
})

export const AVNewsStyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #404040',
  padding: '20px 16px',
  borderRadius: '8px',
  backgroundColor: 'white',
  minHeight: 500,
});

export const getCellBackgroundColor = (source, isExpanded, theme) => {
  if (source === 'MP Update' && isExpanded) {
    return theme.palette.secondary.main;
  }
  if (source === 'MP Update') {
    return theme.palette.lighterPurple.main;
  }
  return 'inherit';
};

export const CustomPagination = styled(Pagination)(() => ({
  '& ul': {
    '& li': {
      display: 'none',
    },
    '& li:first-of-type, & li:last-child': {
      display: 'block',
    },
  },
}));
