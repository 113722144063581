export const createInvestmentDataToLoyaltyRewardsMap = (investmentData, loyaltyRewardsData) => {
  if (!(investmentData && investmentData.length > 0 && loyaltyRewardsData && loyaltyRewardsData.length > 0)) {
    return {};
  }

  const investmentDataToLoyaltyRewardsMap = {};

  const recentInvestments = investmentData[1] || [];
  const fundInvestments = investmentData[2] || [];
  const syndicationFundInvestments = investmentData[3] || [];

  [...recentInvestments, ...fundInvestments, ...syndicationFundInvestments].forEach((investment) => {
    loyaltyRewardsData.forEach((loyaltyReward) => {
      if (investment.fundCode === loyaltyReward.fundVintageCode) {
        if (investmentDataToLoyaltyRewardsMap[investment.fundCode]) {
          investmentDataToLoyaltyRewardsMap[investment.fundCode].push(loyaltyReward);
        } else {
          investmentDataToLoyaltyRewardsMap[investment.fundCode] = [loyaltyReward];
        }
      }
    });
  });

  return investmentDataToLoyaltyRewardsMap;
};

export const keyFormatter = (key) => key.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + key.replace(/([A-Z])/g, ' $1').slice(1);
