import React from 'react';
import { Link } from 'react-router-dom';
import {
  Typography, Box, Button, Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const NewsBox = styled(Box)(({ theme }) => ({
  paddingTop: 24,
  paddingBottom: 24,
  paddingLeft: 10,
  paddingRight: 10,
  [theme.breakpoints.up(350)]: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 40,
    paddingRight: 32,
  },
}));

const NewsBanner = () => (
  <NewsBox>
    <Stack
      direction="column"
      justifyContent="center"
      spacing={{ xs: 2, md: 5.5 }}
      sx={{
        height: '100%',
        pt: { xs: 1, md: 5 },
        pb: { xs: 4, md: 5 },
        borderRight: { xs: 'none', md: '1px solid #003349' },
        borderBottom: { xs: '1px solid #003349', md: 'none' },
      }}
    >
      <Typography component="h2" variant="h2Large">Visit Your<br />Personal Newsfeed</Typography>
      <Button
        type="button"
        variant="contained"
        component={Link}
        to="/news"
        sx={{
          maxWidth: '157px',
        }}
      >
        Go to Newsfeed
      </Button>
    </Stack>
  </NewsBox>
);

export default NewsBanner;
