import React from 'react';
import { useSelector } from 'react-redux';
import {
  Container, Card, CardBody, Row, Col,
} from 'reactstrap';

import InvestorStatements from '../../Investor/InvestorStatements';

const ManagedInvestorStatements = () => {
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  return (
    <>
      {!currentManagedUser && (
        <Container className="dashboard" style={{ paddingTop: '30px' }}>
          <Card className="pb-0" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
            <CardBody
              style={{
                padding: '8px, 10px',
              }}
              className="py-3"
            >
              <Row>
                <Col>
                  <h3>Select a User From the Dropdown Above</h3>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      )}
      {currentManagedUser && (
        <div style={{ paddingTop: '30px' }}>
          <InvestorStatements />
        </div>
      )}
    </>
  );
};

export default ManagedInvestorStatements;