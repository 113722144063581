import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { getFundLogo, defaultImg } from '../../../../shared/helpers/getFundLogo';

const FundLogo = ({ label, fundCode, sx }) => {
  const [imgSrc, setImgSrc] = useState(getFundLogo({ fund_code: fundCode }));
  const [errored, setErrored] = useState(false);

  const handleError = () => {
    // prevents infinite loops if the default image fails to load
    if (!errored) {
      setImgSrc(defaultImg);
      setErrored(true);
    }
  };

  return (
    <Box
      component="img"
      src={imgSrc}
      alt={`${label} Logo`}
      sx={{ width: 30, marginRight: 0.5, ...sx }}
      onError={handleError}
    />
  );
};

FundLogo.propTypes = {
  label: PropTypes.string.isRequired,
  fundCode: PropTypes.string.isRequired,
  sx: PropTypes.objectOf(PropTypes.string),
};

FundLogo.defaultProps = {
  sx: {},
};

export default FundLogo;
