import React from 'react';
// import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { managedUserActions } from '@alumni-ventures-group/react-shared/lib/actions';

const { selectManagedUser } = managedUserActions;

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '150px',
  },
}));

const TopbarManagedSelectUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const managedUsers = useSelector((state) => state.auth.user.users);
  const selectedUser = useSelector((state) => state.currentManagedUser);
  const dispatch = useDispatch();
  return (
    <FormControl size="small" variant="outlined" margin="dense" className={`${classes.root} mx-3`}>
      <InputLabel
        id="select-sort-order-label"
        style={{
          backgroundColor: 'white',
        }}
      >Select User
      </InputLabel>
      <Select
        labelId="select-sort-order-label"
        id="select-sort-order"
        value={selectedUser}
        onChange={(e) => {
          dispatch(selectManagedUser(e.target.value));
          history.push('/managed');
        }}
      >
        <MenuItem value="">
          Select User
        </MenuItem>
        {managedUsers.map((usr) => (
          <MenuItem key={usr.id} value={usr.id}>{usr.firstName} {usr.lastName}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TopbarManagedSelectUser;
