/* eslint-disable camelcase */
import fetch from 'cross-fetch';
import { actions } from '@alumni-ventures-group/react-shared';

const { addErrorMessage } = actions.errorActions;

export const getLogout = async (headers, dispatch, sessionTimeout = false) => {
  // remove session cookie
  document.cookie = `${process.env.SESSION_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${
    window.location.hostname.split('.').slice(-2).join('.')}`;
  try {
    await fetch(
      `${process.env.INVESTOR_PORTAL_API_PATH}/api/logout`, {
        method: 'GET',
        headers,
      },
    );
  } finally {
    // redirect to login page
    if (sessionTimeout) {
      window.location = '/login?session_timeout=1';
    } else {
    // redirect to login page
      window.location = '/login';
    }
  }
};

const logoutIfNecessary = async (response, requestHeaders, dispatch) => {
  if (response?.status === 401) {
    await getLogout(requestHeaders, dispatch, true);
  }
};

export const getRequest = async (url, headers, dispatch, abortSignal = null) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers,
      signal: abortSignal,
    });
    await logoutIfNecessary(response, headers, dispatch);
    const json = await response.json();
    if (!response.ok) {
      const errorMessage = {
        message: `Our account reporting system is temporarily unavailable due to planned maintenance. 
        Please check back in 2-4 hours. We apologize for the inconvenience. 
        Please contact Alumni Ventures Investor Relations at investor.relations@avgfunds.com for further assistance.`,
      };
      dispatch(addErrorMessage(errorMessage));
      return Promise.reject(json);
    }
    if (json.errors && json.errors.length > 0 && json.errors[0] === 'BadAuthToken') {
      // eslint-disable-next-line no-self-assign
      window.location = window.location; // force page refresh, so login process kicks in
    }
    return json;
  } catch (e) {
    console.error(`Download of ${url} : ${e.message}`);
    return 'aborted';
  }
};

export const getRequestNoErrorHandling = async (url, headers, dispatch, abortSignal = null) => {
  const response = await fetch(url, {
    method: 'GET',
    headers,
    signal: abortSignal,
  });
  await logoutIfNecessary(response, headers, dispatch);
  const json = await response.json();
  if (!response.ok) {
    return Promise.reject(json);
  }
  if (json.errors && json.errors.length > 0 && json.errors[0] === 'BadAuthToken') {
    // eslint-disable-next-line no-self-assign
    window.location = window.location; // force page refresh, so login process kicks in
  }
  return json;
};

export const postLogin = async (url, body, dispatch, abortSignal = null) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      signal: abortSignal,
    });
    if (!response.ok) {
      const json = await response.json();
      return Promise.reject(json);
    }
    const json = await response.json();
    document.cookie = json.sessionCookie;
    return json;
  } catch (e) {
    console.error(`failed login : ${e.message}`);
    return 'aborted';
  }
};

export const postRequest = async (url, body, headers, dispatch, abortSignal = null) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
      signal: abortSignal,
    });
    await logoutIfNecessary(response, headers, dispatch);
    if (!response.ok) {
      const json = await response.json();
      dispatch(addErrorMessage(json));
      return Promise.reject(json);
    }
    const json = await response.json();
    return json;
  } catch (e) {
    console.error(`post to ${url} : ${e.message}`);
    return 'aborted';
  }
};

export const postRequestFormData = async (url, body, headers, dispatch, abortSignal = null) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body,
      headers,
      signal: abortSignal,
    });
    await logoutIfNecessary(response, headers, dispatch);
    if (!response.ok) {
      const json = await response.json();
      dispatch(addErrorMessage(json));
      return Promise.reject(json);
    }
    const json = await response.json();
    return json;
  } catch (e) {
    console.error(`post to ${url} : ${e.message}`);
    return 'aborted';
  }
};
