// PortfolioNewsDisplay.jsx
import React, {
  useState, useEffect, useContext, createContext,
} from 'react';
import { useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useTheme, Stack, Typography, Divider, Box,
} from '@mui/material';
import {
  VerticalCenteredContainer, DataText, PortfolioNewsStyledBox,
} from './styles';
import PortfolioNewsTable from './Desktop/PortfolioNewsTable';
import MobilePortfolioNewsTable from './Mobile/MobilePortfolioNewsTable';
import Search from '../../common/Search';
import { sortAlphanumerically } from '../../../../shared/hooks/useSort';
import usePortfolioNews from '../../common/usePortfolioNews';
import SortSelect from './SortSelect';

export const PortfolioNewsContext = createContext({
  portfolioNews: [],
  filteredNews: [],
  fetchPortfolioNews: () => {},
  isLoading: true,
  isError: false,
  isFiltered: false,
  setIsFiltered: () => {},
  setFilteredNews: () => {},
  searchOptions: [],
  page: 0,
  setPage: () => {},
});

const PortfolioNewsSearch = () => {
  const {
    portfolioNews,
    setIsFiltered,
    setFilteredNews,
    searchOptions,
    setPage,
  } = useContext(PortfolioNewsContext);

  const onClear = () => {
    setIsFiltered(false);
    setFilteredNews(portfolioNews);
    setPage(0);
  };
  const onSearch = (filteredData) => {
    setIsFiltered(true);
    setFilteredNews(filteredData);
    setPage(0);
  };

  return (
    <Search
      label="Search fund or company"
      data={portfolioNews}
      fieldsToSearchOn={['fund', 'company']}
      searchOptions={searchOptions}
      onClear={onClear}
      onSearch={onSearch}
    />
  );
};

const NoNewsMessage = () => {
  const { isFiltered } = useContext(PortfolioNewsContext);

  return (
    <VerticalCenteredContainer>
      <DataText>
        {!isFiltered ? 'No news articles are available at this time' : 'No results found'}
      </DataText>
    </VerticalCenteredContainer>
  );
};

const DesktopPortfolioNews = () => {
  const {
    filteredNews,
    isLoading,
  } = useContext(PortfolioNewsContext);
  return (
    <PortfolioNewsStyledBox>
      <Stack spacing={1} sx={{ flex: 1 }}>
        <Box sx={{ pt: 2.5, px: 2.5 }}>
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography component="h2" variant="h1">
              Portfolio News
            </Typography>
            <PortfolioNewsSearch />
          </Stack>
          <Divider variant="fullWidth" sx={{ display: { xs: 'none', md: 'block' } }} />
        </Box>
        {!isLoading && filteredNews.length === 0 ? (
          <NoNewsMessage />
        ) : (
          <PortfolioNewsTable />
        )}
      </Stack>
    </PortfolioNewsStyledBox>
  );
};

const MobilePortfolioNews = () => {
  const {
    filteredNews,
    isLoading,
  } = useContext(PortfolioNewsContext);

  return (
    !isLoading && !filteredNews.length ? (
      <NoNewsMessage />
    ) : (
      <Stack direction="column" alignItems="center" spacing={2.5}>
        <PortfolioNewsSearch />
        {!!isLoading && (<SortSelect />)}
        <MobilePortfolioNewsTable />
      </Stack>
    )
  );
};

const PortfolioNewsDisplay = () => {
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  const {
    portfolioNews,
    fetchPortfolioNews,
    isLoading,
    isError,
  } = usePortfolioNews(currentManagedUser ? `?userid=${currentManagedUser}` : '');
  const [filteredNews, setFilteredNews] = useState(portfolioNews);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const isDesktopSize = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    if (!portfolioNews) {
      return;
    }
    setFilteredNews(portfolioNews);
    // pull unique funds and companies from all news items to create search options
    const funds = new Set(portfolioNews.reduce((acc, news) => [...acc, ...news.fund], []));
    const companies = new Set(portfolioNews.map((news) => news.company));
    const options = [
      ...Array.from(funds).sort(sortAlphanumerically),
      ...Array.from(companies).sort(sortAlphanumerically),
    ].map((elem) => {
      if (funds.has(elem)) {
        return {
          type: 'Fund',
          field: 'fund',
          label: elem,
        };
      }
      if (companies.has(elem)) {
        return {
          type: 'Company',
          field: 'company',
          label: elem,
        };
      }
    });
    setSearchOptions(options);
  }, [portfolioNews]);

  return (
    <PortfolioNewsContext.Provider
      value={{
        portfolioNews,
        filteredNews,
        fetchPortfolioNews,
        isLoading,
        isError,
        isFiltered,
        setIsFiltered,
        setFilteredNews,
        searchOptions,
        page,
        setPage,
      }}
    >
      {isDesktopSize
        ? <DesktopPortfolioNews />
        : <MobilePortfolioNews />}
    </PortfolioNewsContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
});

export default withRouter((connect(mapStateToProps)(PortfolioNewsDisplay)));
