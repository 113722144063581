import * as React from 'react';
import PropTypes from 'prop-types';
import { Stack, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRow = styled(Stack)(({ theme, direction }) => ({
  alignItems: direction === 'row' ? 'center' : 'flex-start',
  padding: '8px 17px',
  fontFamily: 'Archivo',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  backgroundColor: theme.palette.mobileTable.dark,
  '&:nth-of-type(odd)': { backgroundColor: theme.palette.mobileTable.main },
}));

StyledRow.defaultProps = {
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const ContentTitle = styled(Stack)({}, {
  fontFamily: 'Inter',
  fontWeight: '700',
  textTransform: 'uppercase',
  display: 'inline-flex',
});

ContentTitle.defaultProps = {
  direction: 'row-reverse',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const Contents = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bold',
})(({ bold, direction }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: direction === 'row' ? '8px 17px' : '8px 0',
  fontFamily: 'Archivo',
  fontStyle: 'normal',
  fontWeight: bold ? 600 : 500,
  fontSize: '14px',
}));

Contents.defaultProps = {
  align: 'left',
  component: 'span',
};

const MobileTableRow = ({
  title = null, contents = null, direction = 'row', bold = false, sx = {}, titleSx = {}, contentsSx = {},
}) => (
  <StyledRow direction={direction} sx={sx}>
    <ContentTitle sx={titleSx}>
      {title}
    </ContentTitle>
    <Contents bold={bold} direction={direction} sx={contentsSx}>
      {contents}
    </Contents>
  </StyledRow>
);

MobileTableRow.propTypes = {
  title: PropTypes.node,
  contents: PropTypes.node,
  direction: PropTypes.oneOf(['row', 'column']),
  bold: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // eslint-disable-line react/forbid-prop-types
  titleSx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // eslint-disable-line react/forbid-prop-types
  contentsSx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // eslint-disable-line react/forbid-prop-types
};

MobileTableRow.defaultProps = {
  title: null,
  contents: null,
  direction: 'row',
  bold: false,
  sx: {},
  titleSx: {},
  contentsSx: {},
};

export default MobileTableRow;
