import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import {
  createGenerateClassName, StylesProvider, ThemeProvider as ThemeProviderV4, createTheme as createThemeV4,
} from '@material-ui/core/styles';
import {
  ThemeProvider as ThemeProviderV5, createTheme as createThemeV5,
} from '@mui/material/styles';
import { store as getStore, services } from '@alumni-ventures-group/react-shared';
import { Loader, ModalRoot as ModalContainer } from '@alumni-ventures-group/react-shared/lib/components';
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.css';
import '@alumni-ventures-group/react-shared/lib/index.css';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import initializationService from '../../shared/services/initializationService';
import { archivoRobotoFontFamily, interRobotoFontFamily } from '../../shared/style/fontFamilies';

const { blueColor, maroonColor } = services.ClubResources;

const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: 'mui-jss',
});

const createTypographyOverrides = (objValue, properties = []) => {
  const overrides = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const property of properties) {
    overrides[property] = objValue;
  }
  return overrides;
};

const baseFontFamily = "'Inter', 'Roboto', sans-serif";
const baseThemeV4 = createThemeV4();
const themeV4 = createThemeV4({
  palette: {
    primary: blueColor,
    secondary: maroonColor,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          color: 'white',
          backgroundColor: maroonColor.dark,
        },
        backgroundColor: maroonColor.main,
      },
      outlinedPrimary: {
        color: maroonColor.main,
        borderColor: maroonColor.main,
      },
      textPrimary: {
        color: maroonColor.main,
      },
      root: {
        fontFamily: baseFontFamily,
      },
    },
    MuiTypography: {
      ...createTypographyOverrides({
        fontFamily: "'Archivo', 'Roboto', sans-serif",
      }, ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      ...createTypographyOverrides({
        fontFamily: baseFontFamily,
      }, ['root', 'body2', 'body1', 'caption', 'button', 'subtitle1', 'subtitle2', 'overline']),
    },
    MuiAvatar: { colorDefault: { backgroundColor: blueColor.main } },
    MuiTable: {
      root: {
        [baseThemeV4.breakpoints.down('sm')]: {
          display: 'block',
        },
        borderRadius: '5px',
        maxWidth: '100% !important',
      },
    },
    MuiTableHead: {
      root: {
        [baseThemeV4.breakpoints.down('sm')]: {
          display: 'block',
        },
      },
    },
    MuiTableBody: {
      root: {
        [baseThemeV4.breakpoints.down('sm')]: {
          display: 'block',
        },
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: baseFontFamily,
      },
      head: {
        [baseThemeV4.breakpoints.down('sm')]: {
          display: 'block',
          color: '',
        },
        color: 'white',
      },
      body: {
        [baseThemeV4.breakpoints.down('sm')]: {
          display: 'block',
          border: 'none',
          borderBottom: '1px solid #eee',
          position: 'relative',
          paddingLeft: '50%',
          whiteSpace: 'normal',
          textAlign: 'left',
          '&::before': {
            position: 'absolute',
            top: '6px',
            left: '6px',
            width: '65%',
            paddingRight: '10px',
            textAlign: 'left',
            fontWeight: 'bold',
            content: 'attr(data-title)',
          },
        },
      },
    },
    MuiTableRow: {
      head: {
        [baseThemeV4.breakpoints.down('sm')]: {
          position: 'absolute !important',
          top: '-9999px !important',
          left: '-9999px !important',
        },
        backgroundColor: baseThemeV4.palette.action.focus,
      },
      root: {
        [baseThemeV4.breakpoints.down('sm')]: {
          display: 'block',
          border: '1px solid #ccc',
        },
        '&:nth-of-type(even)': {
          backgroundColor: baseThemeV4.palette.action.hover,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 13,
        fontWeight: 'normal',
      },
    },
    MuiSelect: {
      root: {
        fontSize: 13,
        fontFamily: baseFontFamily,
      },
    },
    MuiAlert: {
      root: {
        fontFamily: baseFontFamily,
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: baseFontFamily,
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: baseFontFamily,
      },
    },
  },
  zIndex: {
    modal: 1450,
  },
});

const baseThemeV5 = createThemeV5();
const themeV5 = createThemeV5({
  palette: {
    primary: blueColor,
    secondary: {
      light: '#8866a3',
      main: '#7b5599',
    },
    lightGreen: {
      main: '#E3F3F3',
      dark: '#C2E8E8',
    },
    darkGreen: {
      main: '#C2DAE7',
      dark: '#1B9390',
    },
    beige: {
      main: '#F5F5F0',
      dark: '#E4E4D1',
    },
    lightBlue: {
      light: '#F6F9FB',
      main: '#DDE9F0',
    },
    purpleLink: {
      light: '#9454C7',
      main: '#7b5599',
    },
    border: {
      light: '#EFEFEF',
      main: '#D8D8D8',
      dark: '#E7E7E2',
    },
    lighterPurple: {
      main: '#F5F1F9',
    },
    lightGrey: {
      main: '#CACED8',
      dark: '#9A9A96',
    },
    iconGrey: {
      main: '#757575',
    },
    mobileTable: {
      light: '#FCFCFC',
      main: '#F5F5F5',
      dark: '#EEEEEE',
    },
    table: {
      light: 'white',
      main: '#FAFAFA',
    },
  },
  typography: {
    fontFamily: archivoRobotoFontFamily,
    allVariants: {
      '&&': { // specificity increase needed to override react-shared theme styles on text
        color: '#191919',
      },
    },
    h1: {
      fontSize: 30,
      fontWeight: 600,
    },
    h1Large: {
      fontSize: 28,
      fontWeight: 600,
      fontFamily: archivoRobotoFontFamily,
      '&&': {
        color: '#191919',
      },
      [baseThemeV5.breakpoints.up('sm')]: {
        fontSize: 46,
      },
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
    },
    h2Large: {
      fontSize: 30,
      fontWeight: 600,
      fontFamily: archivoRobotoFontFamily,
      '&&': {
        color: '#191919',
      },
      [baseThemeV5.breakpoints.up('md')]: {
        fontSize: 40,
      },
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontSize: 12,
      fontWeight: 600,
      fontFamily: interRobotoFontFamily,
      [baseThemeV5.breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
    body2Inter: {
      fontSize: 14,
      fontWeight: 400,
      fontFamily: interRobotoFontFamily,
      '&&': {
        color: '#191919',
      },
    },
    body2: {
      fontSize: 14,
      fontWeight: 500,
    },
    dollarAmount: {
      fontSize: 25,
      fontWeight: 700,
      fontFamily: archivoRobotoFontFamily,
      lineHeight: '40px',
      display: 'inline-block',
      [baseThemeV5.breakpoints.up('md')]: {
        fontSize: 32,
      },
    },
    button: {
      fontSize: 14,
      [baseThemeV5.breakpoints.up('md')]: {
        fontSize: 16,
      },
    },
    dialogTitle: {
      fontSize: 30,
      fontWeight: 600,
      fontFamily: interRobotoFontFamily,
      '&&': {
        color: '#3B3B3B',
      },
    },
    label: {
      fontSize: 16,
      fontWeight: 600,
      fontFamily: interRobotoFontFamily,
      '&&': {
        color: '#191919',
      },
    },
    field: {
      fontSize: 16,
      fontWeight: 400,
      fontFamily: interRobotoFontFamily,
      '&&': {
        color: '#191919',
      },
    },
    tooltip: {
      fontSize: 13,
      fontWeight: 'normal',
      '&&': {
        color: 'white',
      },
    },
    subtitle: {
      fontSize: 12,
      fontWeight: 700,
      fontFamily: interRobotoFontFamily,
      textTransform: 'uppercase',
      [baseThemeV5.breakpoints.up('sm')]: {
        fontSize: 14,
      },
    },
    subtext: {
      fontSize: 10,
      fontWeight: 600,
      fontFamily: interRobotoFontFamily,
    },
    disclaimer: {
      fontSize: 10,
      fontWeight: 400,
      fontStyle: 'italic',
      '&&': {
        color: '#191919',
      },
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          a: {
            color: theme.palette.purpleLink.main,
            '&:hover': {
              color: theme.palette.purpleLink.light,
            },
          },
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          '&&': {
            color: 'white',
          },
          // av-frontend uses a lighter color on button hover
          // instead of mui's default darker
          '&.MuiButton-containedPrimary:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '&.MuiButton-containedSecondary:hover': {
            backgroundColor: theme.palette.secondary.light,
          },
          borderRadius: '4px',
          height: 40,
          minWidth: '117px',
          pl: '16px',
          pr: '16px',
          [baseThemeV5.breakpoints.up('md')]: {
            height: 48,
            pl: '18px',
            pr: '18px',
          },
        }),
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'medium',
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '&&': {
            paddingLeft: 16,
            paddingRight: 12,
          },
          '& p': {
            marginTop: 0,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiPaginationItem-root': {
            '&:hover': {
              color: theme.palette.purpleLink.main,
              backgroundColor: theme.palette.lighterPurple.main,
            },
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.border.main,
          borderRadius: 10,
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.purpleLink.main,
          // av-frontend uses a lighter color on link hover
          // instead of mui's default darker
          '&:hover': {
            color: theme.palette.purpleLink.light,
          },
        }),
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'sm',
      },
      styleOverrides: {
        paper: {
          margin: 20,
          marginTop: 70, // needed so that the dialog doesn't get hidden behind the navbar
          // on very small screens, make the dialog take up most of the screen with just a
          // small 10px margin
          [baseThemeV5.breakpoints.down('sm')]: {
            margin: 10,
            marginTop: 70, // needed so that the dialog doesn't get hidden behind the navbar
            width: 'auto',
            '&&': {
              maxWidth: 'none',
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'dialogTitle',
        textAlign: 'center',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          fontWeight: 'normal',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.iconGrey.main,
          // change color on hover to purple with a light purple background
          '&:hover': {
            color: theme.palette.purpleLink.main,
            backgroundColor: theme.palette.lighterPurple.main,
          },
        }),
      },
    },
  },
});

const { store, persistor } = getStore();
class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    initializationService(store, this);
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {({
            // eslint-disable-next-line no-unused-vars
            factory, isReady, isTimedout, lastUpdate,
          }) => (
            <StylesProvider generateClassName={generateClassName}>
              <ThemeProviderV4 theme={themeV4}>
                <ThemeProviderV5 theme={themeV5}>
                  <BrowserRouter>
                    <ScrollToTop>
                      <>
                        {!loaded
                      && (
                      <div className={`load${loading ? '' : ' loaded'}`}>
                        <Loader />
                      </div>
                      )}
                        <div>
                          { loaded && <Router /> }
                          <ModalContainer />
                        </div>
                      </>
                    </ScrollToTop>
                  </BrowserRouter>
                </ThemeProviderV5>
              </ThemeProviderV4>
            </StylesProvider>
          )}
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
