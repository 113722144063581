import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip as MuiTooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

/**
 * Hover effects work poorly on mobile devices because touch triggers hover
 * and scrolling or dragging does not clear it
 * So here we use normal styling on desktop devices using media queries
 * and on devices with touch screens we instead control the hover styling
 * using the open state of the tooltip
 * See https://medium.com/@mezoistvan/finally-a-css-only-solution-to-hover-on-touchscreens-c498af39c31c
 */
const TooltipIconButton = styled(IconButton)(({ open, theme }) => ({
  '@media(hover: hover) and (pointer: fine)': {
    '&:hover': {
      color: theme.palette.purpleLink.main,
      backgroundColor: theme.palette.lighterPurple.main,
    },
  },
  '@media not all and (hover: hover) and (pointer: fine)': {
    '&:hover': {
      color: open ? theme.palette.purpleLink.main : theme.palette.iconGrey.main,
      background: open ? theme.palette.lighterPurple.main : 'none',
    },
  },
}));

export default function Tooltip({ title, placement = 'top-start' }) {
  // need to control the open state, because the default mobile functionality is to require
  // a long press to show the tooltip which is not intuitive
  const [open, setOpen] = useState(false);

  const handleScroll = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = (shouldOpen) => {
    if (shouldOpen) {
      setOpen(true);
      // add event listener to close tooltip on scroll
      // only runs once before being removed
      window.addEventListener('scroll', handleScroll, { once: true });
    } else {
      setOpen(false);
    }
  };

  useEffect(() => { // eslint-disable-line arrow-body-style
    // remove scroll listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <MuiTooltip
      title={title}
      placement={placement}
      open={open}
      onOpen={() => handleOpen(true)}
      onClose={() => handleOpen(false)}
      leaveTouchDelay={0}
      disableTouchListener
    >
      <TooltipIconButton open={open} size="small" sx={{ ml: 0.5 }} onClick={() => handleOpen(true)}>
        <InfoIcon />
      </TooltipIconButton>
    </MuiTooltip>
  );
}

Tooltip.propTypes = {
  title: PropTypes.node.isRequired,
  placement: PropTypes.string,
};

Tooltip.defaultProps = {
  placement: 'top-start',
};
