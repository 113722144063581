import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { connect, useSelector } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { Loader, MaterialTable } from '@alumni-ventures-group/react-shared/lib/components';
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import InfoIcon from '@mui/icons-material/Info';
import useFundInvestments from '../common/useFundInvestments';
import useUserInvestments from '../common/useUserInvestments';
import getToolTipTextForTableProperty from '../common/getToolTipTextForTableProperty';
import BasecampInvestments from './components/BasecampInvestments';
import sortByNumberValue from '../helpers/sortByNumberValue';
import checkApiErrorResponse from '../../../shared/services/checkApiErrorResponse';
import ErrorNotification from '../../../shared/components/ErrorNotification';
// import K1NotificationBanner from '../common/K1NotificationBanner';
// import PeriodEndDate from '../common/PeriodEndDate';
import formatAndRoundPercentage from '../helpers/formatAndRoundPercentage';

const headerExceptionListInvestments = ['Name', 'EndDate', 'dId'];

const generateColumns = (investments, dashboardLabels) => {
  const headers = [];
  if (investments[0]) {
    Object.keys(investments[0]).forEach((key) => {
      const formattedKey = key.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + key.replace(/([A-Z])/g, ' $1').slice(1);

      if (key === 'investmentName') {
        headers.unshift({
          id: 'investmentName',
          label: 'Name',
          alignment: false,
          toolTip: getToolTipTextForTableProperty('Investment Name', dashboardLabels),
        });
        return;
      }
      if (headerExceptionListInvestments.includes(key)) {
        return;
      }

      headers.push({
        id: key,
        label: formattedKey,
        toolTip: getToolTipTextForTableProperty(formattedKey, dashboardLabels),
      });
    });
  }
  return headers;
};

const generateColumnsForTotal = (investments, dashboardLabels) => {
  let headers = [];
  if (investments[0]) {
    Object.keys(investments[0]).forEach((key) => {
      if (key === 'fundCode'
        || key === 'fund'
        || key === 'baseFundCode'
        || key === 'sortField -DontShow'
        || key === 'userId'
        || key === 'Name'
        || key === 'EndDate'
        || key === 'fundName'
        || key === 'dType'
        || key === 'id'
        || key === 'dId'
      ) {
        return;
      }
      let formattedKey = key.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() + key.replace(/([A-Z])/g, ' $1').slice(1);
      if (formattedKey === 'Total Management Fees Paid For Life Of Fund Pct') {
        formattedKey = 'Total Management Fees Paid for Life of Fund (%)';
      } else if (formattedKey === 'Total Management Fees Paid For Life Of Fund') {
        formattedKey = 'Total Management Fees Paid for Life of Fund ($)';
      }
      headers.push({
        id: key,
        label: formattedKey,
        toolTip: getToolTipTextForTableProperty(formattedKey, dashboardLabels),
      });
    });
  }

  const reorderHeaders = (headers) => {
    const headerOrder = [
      'capitalCommitment',
      'investableCapital',
      'totalManagementFeesPaidForLifeOfFund',
      'totalManagementFeesPaidForLifeOfFundPct',
      'currentInvestmentValue',
      'remainingCashToBeInvested',
      'distributionsToDate',
      'lifetimeTotalPositionValue',
    ];
    const orderedHeaders = [];
    headerOrder.forEach((headerId) => {
      const item = headers.find((header) => header.id === headerId);
      if (item) {
        orderedHeaders.push(item);
      }
    });
    return orderedHeaders;
  };

  headers = reorderHeaders(headers);

  // Change the label from "Investable Capital" to "Total Investable Capital"
  const investableCapitalHeader = headers.find((header) => header.id === 'investableCapital');
  if (investableCapitalHeader) {
    investableCapitalHeader.label = 'Total Investable Capital';
  }

  return headers;
};

const splitFundCode = (string, base = true) => string.split('-')[base ? 0 : 1];

const getFundInvestmentsSection = (investments, index, dashboardLabels) => {
  // clone the investments to remap the name property
  // this is to prevent the table from causing the page not to load because
  // the name property automatically generates links by design. in this case we want to call
  // a colomn "Name", but we do not want it to automatically generate links.
  const investmentsCopy = investments ? JSON.parse(JSON.stringify(investments)) : [];
  if (!checkApiErrorResponse(investments)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const investment of investmentsCopy) {
      investment.investmentName = investment.name;
      delete investment.name;
    }
  }
  return (
    <>
      <Row key={uid('id', index)}>
        <Col>
          <Card>
            <CardBody
              className="pb-1"
              style={{
                // boxShadow: 'rgba(0, 0, 0, 0.04) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
                padding: '8px, 10px',
              }}
            >
              <Row>
                <Col>
                  <h4>
                    {index === 0
                      ? 'Current Investments'
                      : 'Exited Investments'}
                  </h4>
                  {/* <h6><i>- Valued as of {PeriodEndDate()} -</i></h6> */}
                  <h6><i>- Valued as of June 30, 2024 -</i></h6>
                </Col>
              </Row>
              {checkApiErrorResponse(investments) && <ErrorNotification />}
              {!checkApiErrorResponse(investments)
                && (
                  <Row>
                    <Col>
                      <MaterialTable
                        columns={index === 0 ? generateColumns(
                          investmentsCopy.map((investment) => ({ ...investment })),
                          dashboardLabels,
                        ) : generateColumns(investmentsCopy, dashboardLabels)}
                        dataArray={investmentsCopy}
                        preSort={index === 0 ? { field: 'investmentName', order: 'asc' }
                          : { field: 'dateExited', order: 'desc' }}
                        edit={null}
                        rowSize={25}
                        selectable={false}
                        showToolbar={false}
                        wrapHeaders
                        customSort={sortByNumberValue}
                      />
                    </Col>
                  </Row>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const FundDetails = ({
  location, companies, isFetching,
}) => {
  const { fundCode: combinedFundCode } = useParams();
  // TODO: Remove managed user check
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  const baseFundCode = splitFundCode(combinedFundCode, true);
  const fundCode = splitFundCode(combinedFundCode, false);
  let investmentSummary = (location && location.state) ? [location.state] : null;

  if (!investmentSummary) {
    const userInvestments = useUserInvestments(
      currentManagedUser ? `?userid=${currentManagedUser}` : '',
    );

    if (userInvestments && userInvestments[2]) {
      investmentSummary = userInvestments[2].slice().filter((investment) => investment.fundCode === fundCode);
      if (userInvestments[3] && (!investmentSummary || investmentSummary.length === 0)) {
        investmentSummary = userInvestments[3].slice().filter((investment) => investment.fundCode === fundCode);
      }
    } else {
      investmentSummary = [];
    }
  }
  const [isLoading, setIsLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState();
  const fundInvestmentData = useFundInvestments(
    baseFundCode, fundCode, currentManagedUser ? `&userid=${currentManagedUser}` : '',
  );

  useEffect(() => {
    if (fundInvestmentData && !isFetching) {
      setIsLoading(!isLoading);
    }
  }, [fundInvestmentData, isFetching]);
  useEffect(() => {
    if (companies && companies.length !== 0 && !companyDetails) {
      const company = companies.find((comp) => (comp.code === baseFundCode)) || {};
      if (company.hexColor && company.hexColor.charAt(0) !== '#') {
        company.hexColor = `#${company.hexColor}`;
      }
      setCompanyDetails(company);
    }
  }, [companies]);
  const dashboardLabels = useSelector((state) => state.dashboardLabels);

  const showDisclaimerText = fundCode === 'SYNELIY';
  return (
    <>
      <Helmet>
        <title>Fund Details - Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <Container className="dashboard">
        {(investmentSummary[0]) && (
          <Card className="pb-0">
            <CardBody
              style={{
                backgroundColor: companyDetails && companyDetails.hexColor ? companyDetails.hexColor : '#104866',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                padding: '8px, 10px',
                verticalAlign: 'middle',
              }}
              className="py-3 d-flex align-items-center"
            >
              <h4 className="bold-text align-middle" style={{ color: 'white', display: 'inline-block' }}>
                {
                  `Fund Details - ${investmentSummary[0].fundName}`.toUpperCase()
                }
              </h4>
            </CardBody>
          </Card>
        )}
        <>
          {(isLoading) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Loader />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {(!isLoading && showDisclaimerText) && (
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <p style={{ fontSize: '15px' }}>
                    You have completed the required operational steps for your investment in the Fund, and the investment is shown in your portfolio. In unusual circumstances, other factors arising later in the investment process may lead to changes in your investment. In general, these are factors beyond Alumni Ventures' control and your investor dashboard may need to be updated to reflect the newer investment terms.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          )}
          {(!isLoading && investmentSummary) && (
            <Row>
              <Col>
                <Card>
                  <CardBody
                    className="pb-1 pt-4"
                    style={{
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <Row>
                      <Col>
                        <h4>Investment Totals</h4>
                        {/* <h6><i>- Valued as of {PeriodEndDate()} -</i></h6> */}
                        <h6><i>- Valued as of June 30, 2024 -</i></h6>
                      </Col>
                    </Row>
                    {checkApiErrorResponse(investmentSummary) && <ErrorNotification />}
                    {!checkApiErrorResponse(investmentSummary)
                      && (
                        <Row>
                          <Col>
                            <MaterialTable
                              columns={generateColumnsForTotal(investmentSummary, dashboardLabels)}
                              dataArray={investmentSummary.map((investment) => {
                                if (investment && typeof investment === 'object') {
                                  const investmentCopy = { ...investment };
                                  if (investmentCopy.totalManagementFeesPaidForLifeOfFundPct) {
                                    // eslint-disable-next-line max-len
                                    investmentCopy.totalManagementFeesPaidForLifeOfFundPct = formatAndRoundPercentage(investment.totalManagementFeesPaidForLifeOfFundPct);
                                  }
                                  return ({
                                    ...investmentCopy,
                                  });
                                }
                                return investment;
                              })}
                              preSort={{ field: 'fund', order: 'desc' }}
                              edit={null}
                              rowSize={25}
                              selectable={false}
                              showToolbar={false}
                              wrapHeaders
                              customSort={sortByNumberValue}
                            />
                          </Col>
                        </Row>
                      )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {(!isLoading && fundInvestmentData && fundInvestmentData[0]) && (
            getFundInvestmentsSection(
              fundInvestmentData[0], 0, dashboardLabels,
            )
          )}
          {(!isLoading && companies && companies.length !== 0 && fundInvestmentData) && (
            <BasecampInvestments fundInvestmentData={fundInvestmentData} companyDetails={companyDetails} />
          )}
          {(!isLoading && fundInvestmentData && fundInvestmentData[1]) && (
            getFundInvestmentsSection(
              fundInvestmentData[1], 1, dashboardLabels,
            )
          )}
        </>
      </Container>
    </>
  );
};

FundDetails.propTypes = {
  location: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    state: PropTypes.any.isRequired,
  }).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  dispatch: state.dispatch,
  isFetching: state.companies.isFetching,
  companies: state.companies.companiesArray,
});

export default withRouter((connect(mapStateToProps)(FundDetails)));
