/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Container, Box, Typography, Stack,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Use new GridV2
import { Helmet } from 'react-helmet';
import useMediaQuery from '@mui/material/useMediaQuery';
import newsBackground from 'url:../../../images/news-background.svg';
import PortfolioNewsDisplay from './components/PortfolioNews';
import AVNewsDisplay from './components/AVNews';
import Toggle from '../common/Toggle';

const News = () => {
  const [showPortfolioNews, setShowPortfolioNews] = useState(true);
  const theme = useTheme();
  const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        minHeight: '80vh',
        backgroundImage: `url(${newsBackground})`,
        backgroundSize: desktopSize ? '100% 330px' : '100% 500px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Helmet>
        <title>News - Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <Container maxWidth="xl">
        <Box pt={8}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              textAlign: 'center',
            }}
          >
            <Typography component="h1" variant="h1Large">Personalized News</Typography>
            <Typography variant="subtitle" color="secondary">
              Updates below are exclusively related to companies you own
            </Typography>
          </Stack>
          <Grid
            container
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            spacing={4}
            justifyContent={{ xs: 'center', sm: 'center', lg: 'start' }}
            alignItems={{ xs: 'center', sm: 'center', lg: 'start' }}
            pt={4}
          >
            { !desktopSize && (
            <Toggle
              value={showPortfolioNews}
              setValue={setShowPortfolioNews}
              toggleOptions={['Portfolio News', 'AV News']}
            />
            )}
            { (desktopSize || showPortfolioNews) && (
            <Grid
              xs={12}
              md={9.6}
            >
              <PortfolioNewsDisplay />
            </Grid>
            )}
            { (desktopSize || !showPortfolioNews) && (
            <Grid
              xs={12}
              md={2.4}
            >
              <AVNewsDisplay />
            </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  dispatch: state.dispatch,
});

export default withRouter((connect(mapStateToProps)(News)));
