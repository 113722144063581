import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

class TopbarWithNavigation extends PureComponent {
  render() {
    const { changeMobileSidebarVisibility, user } = this.props;

    return (
      <div className="topbar topbar--navigation">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton changeMobileSidebarVisibility={changeMobileSidebarVisibility} />
            <Link className="topbar__logo" to="/fdo" />
          </div>
          <div className="topbar__right">
            {user && <TopbarProfile user={user} />}
          </div>
        </div>
      </div>
    );
  }
}

TopbarWithNavigation.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { currentUser } = state;
  const { user } = currentUser || { user: {} };

  return { user };
}

export default connect(mapStateToProps)(TopbarWithNavigation);
