import { useState, useEffect } from 'react';
// TODO: Replace get basecamp data call with netsuite related data
import { getBasecampInvestments } from '../../../shared/services/apiGateway';

function useBasecampInvestments(fundId) {
  const [basecampInvesments, setBasecampInvestments] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      let response = await getBasecampInvestments(fundId, abortController.signal);
      if (!ignore && response !== 'aborted' && response) {
        // remove duplicate companies, if any
        response = response.filter((item, index, self) => index === self.findIndex((t) => (
          t.website === item.website && t.name === item.name
        )));
        setBasecampInvestments(response);
      }
    }

    if (fundId) {
      fetchData();
    }

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, []);

  return basecampInvesments;
}

export default useBasecampInvestments;
