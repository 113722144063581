import moment from 'moment';

export default function sortK1Table(data, property, sortOrder) {
  const sortAlphanumerically = (arr, prop, order) => {
    console.log(arr, prop, order);
    if (Object.prototype.toString.call(arr) === '[object Array]') {
      // sort the k1FileLink column by the k1Status field
      const comparatorProp = prop === 'k1FileLink' ? 'k1Status' : prop;
      arr.sort((item1, item2) => {
        if (item1[comparatorProp] && item2[comparatorProp]) {
          return item1[comparatorProp].localeCompare(item2[comparatorProp], 'en', { numeric: true }) * order;
        }
        return item1[comparatorProp] > item2[comparatorProp] ? order : order * -1;
      });
    }
    return arr;
  };

  const sortByDate = (arr, prop, order) => arr.sort((a, b) => {
    console.log(a[prop], moment(a[prop]));
    if (moment(a[prop]) < moment(b[prop])) { return order; }
    if (moment(a[prop]) > moment(b[prop])) { return -1 * order; }
    return 0;
  });

  const sortTable = (arr, prop, order) => {
    const sortDirection = order === 'asc' ? 1 : -1;
    if (prop === 'id') {
      return arr;
    }
    if (prop.toLowerCase().includes('date')) {
      return sortByDate(arr, prop, sortDirection);
    }
    return sortAlphanumerically(arr, prop, sortDirection);
  };

  return sortTable(data, property, sortOrder);
}
