/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from 'mdi-react/MenuIcon';
import isTransactionComplete from '../../../../shared/helpers/isTransactionComplete';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  text: {
    color: '#104866',
    fontSize: '14px',
    fontFamily: '"Inter", "Roboto", sans-serif',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  nested: {
    paddingLeft: 24,
  },
});

const SwipeableTemporaryDrawer = ({ user, userPortfolio = {} }) => {
  const { distributionsPage } = useFlags();
  const { newsPage } = useFlags();
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    open: false,
  });
  const investmentsInProgress = userPortfolio
    && userPortfolio.transactions
    && userPortfolio.transactions.filter((transaction) => !isTransactionComplete(transaction));

  const navigate = (link) => {
    console.log(link);
    if (link.indexOf('http') !== -1) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
  };

  const toggleDrawer = (openNewVal) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, open: openNewVal });
  };

  const makeMenuItem = (title, icon, path) => ({
    title,
    icon,
    path,
  });

  const menuList = [
    makeMenuItem('Investor Dashboard', 'list', '/'),
    makeMenuItem('Documents', 'list', '/funds/statements'),
  ];

  if (distributionsPage && user.isInvestor === 1) {
    menuList.splice(1, 0, makeMenuItem('Distributions', 'list', '/distributions'));
  }

  if (newsPage && user.isInvestor === 1) {
    menuList.push(makeMenuItem('News', 'list', '/news'));
  }

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuList.map((obj) => (
          <React.Fragment key={obj.title}>
            <ListItem
              className={classes.text}
              button
              key={obj.title}
              onClick={(e) => {
                e.preventDefault();
                navigate(obj.path);
              }}
            >
              {obj.icon && (<span className={`topbar__link-icon lnr lnr-${obj.icon}`} />)}
              <ListItemText classes={{ primary: classes.text }} primary={obj.title} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
        {investmentsInProgress && investmentsInProgress.length > 0 && (
          <ListItem
            className={classes.text}
            button
            key="Investments In Progress"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `${process.env.APP_AV_VC_PATH}/investment-summary`;
            }}
          >
            <span className="topbar__link-icon lnr lnr-list" />
            <ListItemText classes={{ primary: classes.text }} primary="Investments In Progress" />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <React.Fragment key="left">
      <IconButton onClick={toggleDrawer(!state.open)}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={state.open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{
          paper: classes.root,
        }}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
};

SwipeableTemporaryDrawer.propTypes = {
  user: PropTypes.any.isRequired,
  userPortfolio: PropTypes.any.isRequired,
};

export default SwipeableTemporaryDrawer;
