import React from 'react';
import PropTypes from 'prop-types';

import {
  Row, Col,
} from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';

const defaultLogo = 'https://s3.amazonaws.com/la-company-logos/avg_logo_w_bg.png';

const deviceHasTouchScreen = () => (window.navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);

const prependHttpIfNeeded = (url) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    return `http://${url}`;
  }
  return url;
};

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '18px',
  },
  item: {
    padding: '15px 5px 15px 5px',
  },
  title: {
    margin: '0px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  thumbnail: {
    width: '248px',
    margin: '0 auto',
  },
  logoBox: {
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'center',
    backgroundColor: '#fff',
    border: '1px solid #E8EAEE',
    height: '162px',
    position: 'relative',
    boxShadow: '0 0 50px #eaeaea',
  },
  img: {
    maxWidth: '80%',
    maxHeight: '55%',
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  contentBox: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '5px 10px',
    textAlign: 'center',
    position: 'absolute',
    bottom: 5,
    color: '#fff',
  },
}));

const makeKey = (company) => `${company.id}_${company.name}_${company.website}`;
const makeNameElementId = (company) => `${makeKey(company)}_logo`;

const toggleCompanyNameVisbility = (makeVisible, id) => {
  const companyNameElement = document.getElementById(id);
  if (companyNameElement) {
    companyNameElement.style.visibility = makeVisible || deviceHasTouchScreen() ? 'inherit' : 'hidden';
  }
};

const BasecampCompanies = ({ companies }) => {
  const classes = useStyles();
  return (
    <Row className={classes.container}>
      {companies.map((company) => (
        <Col sm={12} md={6} lg={4} xl={4} className={classes.item} key={makeKey(company)}>
          <a href={prependHttpIfNeeded(company.website)} target="_blank" rel="noreferrer">
            <div className={classes.thumbnail}>
              <div
                className={classes.logoBox}
                onMouseEnter={() => toggleCompanyNameVisbility(true, makeNameElementId(company))}
                onMouseLeave={() => toggleCompanyNameVisbility(!company.logoFilePath, makeNameElementId(company))}
              >
                <img src={company.logoFilePath || defaultLogo} className={classes.img} alt="logo" />
                <div
                  id={makeNameElementId(company)}
                  className={classes.contentBox}
                  style={{ visibility: company.logoFilePath && !deviceHasTouchScreen() ? 'hidden' : 'inherit' }}
                >
                  <h5 className={classes.title}>{company.name}</h5>
                </div>
              </div>
            </div>
          </a>
        </Col>
      ))}
    </Row>
  );
};

BasecampCompanies.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BasecampCompanies;
