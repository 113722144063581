/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import {
  TextField,
  Autocomplete, InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

/**
 * A reusable Search bar component built using MUI Autcomplete
 * Takes in a list of preset search options that are grouped by type and displayed
 * inside the search autocomplete dropdown.
 * Filters the given data when the user either selects a preset option or types in a search
 * query. Queries are only filtered against the provided fieldsToSearchOn which can either
 * be a string or an array of strings.
 */
const Search = ({
  label, // label inside of search input
  data, // array of data to search through
  fieldsToSearchOn, // fields in each data object to search on
  searchOptions, // pre generated search options to select
  onClear, // runs when user clears the search input
  onSearch, // runs when user selects an option or types a search input
}) => {
  const [searchFilter, setSearchFilter] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchLabelShouldShrink, setSearchLabelShouldShrink] = useState(false);

  const handleSearchFilterChange = (event, newValue, reason) => {
    setSearchFilter(newValue);
    if (reason === 'clear') {
      onClear();
    } else if (reason === 'selectOption') {
      const filtered = [...data].filter((datum) => {
        if (Array.isArray(datum[newValue.field])) {
          return datum[newValue.field].includes(newValue.label);
        }
        return datum[newValue.field] === newValue.label;
      });
      onSearch(filtered);
    }
  };

  const debouncedSearchInputChangeHandler = useCallback(
    debounce((event, newValue, reason) => {
      setSearchInput(newValue);
      if (reason === 'clear') {
        onClear();
      } else if (reason === 'input') {
        // convert all fields to lower case when comparing
        // to do a case insensitive search of the data
        const caseInsensitiveSearchFilter = newValue.toLowerCase();
        const filtered = [...data].filter((datum) => (
          fieldsToSearchOn.some((field) => {
            if (datum[field] && Array.isArray(datum[field])) {
              return datum[field].some((arrayItem) => arrayItem.toLowerCase().includes(caseInsensitiveSearchFilter));
            }
            return datum[field] && datum[field].toLowerCase().includes(caseInsensitiveSearchFilter);
          })
        ));
        onSearch(filtered);
      }
    }, 300), [onClear, onSearch],
  );

  useEffect(() => { // eslint-disable-line arrow-body-style
    // cancel debounced handler on unmount
    return () => {
      debouncedSearchInputChangeHandler.cancel();
    };
  }, []);

  return (
    <Autocomplete
      value={searchFilter}
      onChange={handleSearchFilterChange}
      input={searchInput}
      onInputChange={debouncedSearchInputChangeHandler}
      freeSolo
      groupBy={(option) => option.type}
      options={searchOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          onFocus={() => setSearchLabelShouldShrink(true)}
          onBlur={(e) => !e.target.value && setSearchLabelShouldShrink(false)}
          sx={{
            backgroundColor: 'white',
            width: '25ch',
            '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
              transform: 'translate(34px, 9px)',
            },
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: searchLabelShouldShrink,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

Search.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line react/forbid-prop-types
  fieldsToSearchOn: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
  })).isRequired,
  onClear: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default Search;
