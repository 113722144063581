import React from 'react';

export const avgTokenIconUrl = 'https://avg-wordpress-prod.s3.amazonaws.com/uploads/avwa_coin_v002_transparent.png';

export const errorMessage = () => (
  <p>
    <strong>Note to All Investors: </strong>
    Our account reporting system is temporarily unavailable due to planned maintenance.
    Please check back in 2-4 hours. We apologize for the inconvenience.
    <br /> Please contact Alumni Ventures Investor Relations
    at <a href="mailto:investor.relations@av.vc">investor.relations@avgfunds.com</a> for further assistance.
  </p>
);

export const openMoreInfo = () => window.open('https://www.av.vc/blog/avt', '_blank');

export const toolTipText = (tokenAmount, classes) => (
  <>
    <div className={classes.toolTipText}>
      <h5 className="mb-1">You have {(tokenAmount || 0).toLocaleString()} tokens</h5>
      <ul>
        <li>Currently in Beta Launch</li>
        <li>
          See&nbsp;
          <a
            href="https://www.av.vc/blog/avt"
            target="_blank"
            rel="noreferrer"
            className="text-white"
            style={{ textDecoration: 'underline' }}
          >
            here
          </a>
          &nbsp;for full AVT explanation
        </li>
      </ul>
    </div>
  </>
);
