import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

export const StatusTooltip = () => {
  // need to control the open state, because the default mobile functionality is to require
  // a long press to show the tooltip which is not intuitive
  const [open, setOpen] = useState(false);
  return (
    <Tooltip
      title={(
        <>
          <Typography variant="tooltip">
            What does Pending mean?
            <ul>
              <li>Distribution amounts under $250 will remain open until amount passes $250</li>
              <li>Returned checks, incorrect ACH information</li>
              <li>Investor requested not to be sent out</li>
              <li>Investor information needs to be updated</li>
            </ul>
          </Typography>
        </>
      )}
      placement="top-start"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <IconButton size="small" sx={{ ml: 0.5 }} onClick={() => setOpen(true)}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

const StatusContainer = styled(Box)(({ status }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: '26px',
  width: '96px',
  gap: '10px',
  padding: '7px 12px',
  borderRadius: '8px',
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Archivo',
  color: (status !== 'Pending') ? '#1c827e' : '#bb701c',
  backgroundColor: status !== 'Pending' ? '#e1eceb' : '#f0e8dd',
}));

export default function StatusBox({ status }) {
  return (
    <StatusContainer status={status} component="span">
      {status !== 'Pending'
        ? <CheckCircleOutlineIcon sx={{ fontSize: 15 }} />
        : <AccessTimeIcon sx={{ fontSize: 15 }} />}
      {status}
    </StatusContainer>
  );
}

StatusBox.propTypes = {
  status: PropTypes.string.isRequired,
};
