import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import MD5 from 'md5.js';
import { connect } from 'react-redux';
import { doLogOut } from '../../../shared/services/authActions'
import TopbarMenuLink from './TopbarMenuLink';
import ava from 'url:../../../images/ava.png';

class TopbarProfile extends PureComponent {
  static propTypes = {
    logOut: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    auth: PropTypes.object,
  };

  static defaultProps = {
    auth: null,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;
    const { auth, logOut } = this.props;

    let username = '';
    let avatar = ava;
    if (auth && auth.user && auth.user.email) {
      username = auth.user.email;
      const gravatarHash = new MD5().update(auth.user.email.trim().toLowerCase()).digest('hex');
      // ('' + this.props.email).trim().toLowerCase()
      avatar = `https://www.gravatar.com/avatar/${gravatarHash}`;
    }

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={avatar} alt="avatar" />
          <p className="topbar__avatar-name">{username}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="AV Referral Engine" icon="list" path="/fdo" />
            <TopbarMenuLink
              title="New Admin"
              icon="inbox"
              onClick={() => {
                window.location.replace(`${window.location.origin}/admin/new`);
                return null;
              }}
            />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title="Log Out" icon="exit" onClick={logOut} />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.theme,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  logOut: () => { doLogOut(dispatch); },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopbarProfile);
