import { store as getStore } from '@alumni-ventures-group/react-shared';
import {
  getRequest, getRequestNoErrorHandling, postRequest, postRequestFormData, postLogin, getLogout,
} from './request';

const getHeaders = () => {
  const { store } = getStore();
  const { dispatch } = store;
  const sessionCookie = document.cookie.split(';').find((cookie) => {
    const [cookieName] = cookie.split('=');
    return cookieName.trim() === process.env.SESSION_COOKIE_NAME;
  });
  // Session cookie will be automatically sent with request if it exists
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  if (sessionCookie) {
    headers.Authorization = sessionCookie.trim();
  }
  return { headers, dispatch };
};

export const loginInvestor = async (body) => {
  const { dispatch } = getHeaders();
  const res = await postLogin(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/login-user`,
    body,
    dispatch,
  );
  return res;
};

export const logoutInvestor = async () => {
  const { headers, dispatch } = getHeaders();
  const res = await getLogout(
    headers,
    dispatch,
  );
  return res;
};

export const me = async () => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(`${process.env.INVESTOR_PORTAL_API_PATH}/api/me`, headers, dispatch);
  return res;
};

export const getUserInvestments = async (userid = '', abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/user-investments${userid}`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const getLoyaltyRewards = async (userid = '', abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/loyalty-rewards${userid}`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const getFundInvestments = async (baseFundCode, fundCode, userid, abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(
    // eslint-disable-next-line max-len
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/fund-investments?base-fund-code=${baseFundCode}&fund-code=${fundCode}${userid}`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const getUserDocuments = async (userid = '', abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/user-documents${userid}`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const getUserMessages = async (userid = '', abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/user-messages${userid}`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const getUserNews = async (userid = '', abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/user-news${userid}`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const exportK1 = async (userId, fileKey, abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const body = {
    fileKey,
  };

  return postRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/export-k1${userId}`,
    body,
    headers,
    dispatch,
    abortSignal,
  );
};

export const exportK1s = async (userId, connectionId, fileKeys, year, abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const body = {
    connectionId,
    fileKeys,
    year,
  };

  return postRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/export-k1s${userId}`,
    body,
    headers,
    dispatch,
    abortSignal,
  );
};

export const getK1Metadata = async (userid, year = 2023, abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const url = new URL(`${process.env.INVESTOR_PORTAL_API_PATH}/api/k1`);

  const params = new URLSearchParams();
  if (userid) { params.append('userid', userid); }
  if (year) { params.append('year', year); }
  url.search = params.toString();

  const res = await getRequest(
    url.toString(),
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};
// TODO: Swap or remove endpoint
export const verifyRecaptcha = async (token) => {
  const res = await getRequest(
    `${process.env.AVG_API_PATH}/api/v1.0/external/investor/get-recaptcha?token=${token}`,
  );
  return res;
};

export const getUserTokens = async (abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/user-tokens`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const getBasecampInvestments = async (fundId, abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/basecamp-investments?fund-id=${fundId}`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const getDashboardLabels = async (abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/dashboard-labels`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const updateUser = async (body) => {
  const { headers, dispatch } = getHeaders();
  const res = await postRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/update-user`,
    body,
    headers,
    dispatch,
  );
  return res;
};

export const sendEmailToIR = async (body) => {
  const { headers, dispatch } = getHeaders();
  const res = await postRequest(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/email-investor-relations`,
    body,
    headers,
    dispatch,
  );
  return res;
};

export const sendContactEmail = async (body) => {
  const { store } = getStore();
  const { dispatch } = store;
  const res = await postRequestFormData(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/send-contact-email`,
    body,
    {
      Accept: 'application/json',
    },
    dispatch,
  );
  return res;
};

export const getUserDistributions = async (userid = '', abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequestNoErrorHandling(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/user-distributions${userid}`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const getUserPortfolio = async (abortSignal = null) => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequestNoErrorHandling(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/portfolio`,
    headers,
    dispatch,
    abortSignal,
  );
  return res;
};

export const getFundFamilies = async () => {
  const { headers, dispatch } = getHeaders();
  const res = await getRequestNoErrorHandling(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/fund-families`,
    headers,
    dispatch,
  );
  return res;
};

export const loginManagedUser = async (body) => {
  const { dispatch } = getHeaders();
  const res = await postLogin(
    `${process.env.INVESTOR_PORTAL_API_PATH}/api/login-managed-user`,
    body,
    dispatch,
  );
  return res;
};
