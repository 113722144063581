import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useInput } from '@alumni-ventures-group/react-shared/lib/hooks';
import { sendContactEmail } from '../../../shared/services/apiGateway';
import ContactModalForm from './ContactModalForm';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: '#646777',
    '& h2': {
      marginTop: 20,
    },
    '& p': {
      fontSize: 16,
      marginBottom: 20,
      marginTop: 20,
    },
  },
}));

const ContactModal = ({ openModal, closeModal, auth }) => {
  const open = openModal;
  const close = closeModal;
  const confirmText = 'Send';
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);
  const {
    value: firstName, bind: bindFirstName, reset: resetFirstName,
  } = useInput((auth.user.firstName), true);
  const {
    value: lastName, bind: bindLastName, reset: resetLastName,
  } = useInput((auth.user.lastName), true);
  const {
    value: email, bind: bindEmail, reset: resetEmail,
  } = useInput((auth.user.email1), true);
  const {
    value: phone, bind: bindPhone, reset: resetPhone,
  } = useInput((''));
  const {
    value: message, bind: bindMessage, reset: resetMessage,
  } = useInput((''), true);

  const clearAllFields = () => {
    resetFirstName();
    resetLastName();
    resetEmail();
    resetPhone();
    resetMessage();
  };

  const validatePhoneNumber = (number) => isValidPhoneNumber(number, 'US') && isPossiblePhoneNumber(number, 'US');

  useEffect(() => {
    if (!firstName || !lastName || !email || (phone && !validatePhoneNumber(phone)) || !message) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  }, [firstName, lastName, email, phone, message]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmitting(true);
    const postData = new FormData();
    postData.append('firstName', firstName);
    postData.append('lastName', lastName);
    postData.append('email', email);
    postData.append('phone', phone);
    postData.append('message', message);
    const res = await sendContactEmail(postData);
    if (res && res.success) {
      setSubmitting(false);
      clearAllFields();
      closeModal();
      return true;
    }
    setSubmitting(false);
    return false;
  };

  const confirm = (e) => {
    handleSubmit(e);
  };
  const id = 'contact-us';
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id={`${id}-title`}>Contact Us</h2>
          <Form onSubmit={handleSubmit} id="contact-form">
            <ContactModalForm
              bindFirstName={bindFirstName}
              bindLastName={bindLastName}
              bindEmail={bindEmail}
              bindPhone={bindPhone}
              bindMessage={bindMessage}
            />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                type="button"
                size="medium"
                // color=""
                className={classes.margin}
                onClick={close}
                id={`${id}-cancel`}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="button"
                size="medium"
                color="primary"
                className={classes.margin}
                onClick={confirm}
                id={`${id}-confirm`}
                disabled={submitting || errors}
              >
                {confirmText || 'Yes'}
              </Button>
            </Grid>
          </Form>
        </div>
      </Fade>
    </Modal>
  );
};

ContactModal.propTypes = {
  openModal: propTypes.bool.isRequired,
  closeModal: propTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  auth: propTypes.any.isRequired,
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps)(ContactModal);
