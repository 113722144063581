import * as React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { Stack } from '@mui/material';
import MobileAccordion from './MobileAccordion';
import CurrentDistributionAccordion from './CurrentDistributionAccordion';

export default function MobileDistributionsTable({
  distributionData, showCurrentQuarterDistributions, fundCode, fundName,
  fundTotalDistributions,
}) {
  const allDistributionsView = (!showCurrentQuarterDistributions);

  if (allDistributionsView) {
    const [expanded, setExpanded] = React.useState('');
    const handleChange = (panel) => {
      if (expanded === panel) {
        setExpanded('');
      } else {
        setExpanded(panel);
      }
    };
    const accordions = distributionData.map((row) => {
      if (row.divider) {
        return (
          <div
            style={{
              width: '100%',
              display: 'inline-block',
              textAlign: 'center',
              backgroundColor: '#e0e0e0',
              marginBottom: '8px',
              fontFamily: 'Inter',
              fontWeight: 600,
              height: '23px',
            }}
            key={row.divider}
          >{row.divider === 'Pending' ? null : row.divider}
          </div>
        );
      }
      const key = uid(row);
      return (
        <MobileAccordion
          panelName={key}
          row={row}
          handleChange={handleChange}
          expanded={expanded}
          key={key}
        />
      );
    });

    return (
      <Stack>
        { accordions }
      </Stack>
    );
  }
  return (
    <Stack>
      <CurrentDistributionAccordion
        distributionData={distributionData}
        fundCode={fundCode}
        fundName={fundName}
        fundTotalDistributions={fundTotalDistributions}
      />

    </Stack>
  );
}

MobileDistributionsTable.propTypes = {
  distributionData: PropTypes.arrayOf(PropTypes.object).isRequired,
  fundCode: PropTypes.string,
  fundName: PropTypes.string,
  showCurrentQuarterDistributions: PropTypes.bool.isRequired,
  fundTotalDistributions: PropTypes.string,
};

MobileDistributionsTable.defaultProps = {
  fundCode: '',
  fundName: '',
  fundTotalDistributions: '',
};
