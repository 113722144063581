import * as React from 'react';
import { Typography } from '@mui/material';

const statusTextOne = `Status will remain in "Pending" until payment is issued. 
Funds that have <$250 total distributions will remain in "Pending" until the $250 threshold is exceeded.
Other corner cases exist, please get in touch with investor.relations@av.vc with further questions.`;

const statusTextTwo = `Status will be labeled as "Issued" once AV has processed the payment on our end. 
There may be a period of time between when a payment is issued and when it is received.`;

export default [
  {
    id: 'fundName',
    numeric: false,
    disablePadding: false,
    label: 'Fund',
    width: '25%',
    tooltip: (
      <Typography variant="tooltip">
        The name of the fund.
      </Typography>
    ),
  },
  {
    id: 'companyName',
    numeric: false,
    disablePadding: false,
    label: 'Company',
    width: '25%',
    tooltip: (
      <Typography variant="tooltip">
        The name of the portfolio company investment.
      </Typography>
    ),
  },
  {
    id: 'payee',
    numeric: false,
    disablePadding: false,
    label: 'Payee',
    tooltip: (
      <Typography variant="tooltip">
        The person or entity to whom the distributions will be paid.
      </Typography>
    ),
  },
  {
    id: 'paymentMethod',
    numeric: false,
    disablePadding: false,
    label: 'Payment Method',
    tooltip: (
      <Typography variant="tooltip">
        The method of payment delivery.
      </Typography>
    ),
  },
  {
    id: 'issueDate',
    numeric: false,
    disablePadding: false,
    label: 'Issue Date',
    tooltip: (
      <Typography variant="tooltip">
        The date the distribution was processed for payment.
      </Typography>
    ),
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    tooltip: (
      <>
        <Typography variant="tooltip">
          <p>{statusTextOne}</p>
          <p>{statusTextTwo}</p>
        </Typography>
      </>
    ),
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
    tooltip: (
      <Typography variant="tooltip">
        Amounts paid to you resulting from investment activity.
      </Typography>
    ),
  },
];
