/* eslint-disable no-console */
// import { BifService } from '@alumni-ventures-group/react-shared/lib/services';
import { verifyRecaptcha } from '../../../shared/services/apiGateway';
// const { verifyRecaptcha } = BifService;

const verifyRecaptchaSubmit = (callback, token) => async (event) => {
  if (!token) {
    return;
  }

  const result = await verifyRecaptcha(token);
  if (result.success) {
    callback(event);
  } else {
    console.error(result);
  }
};

export default verifyRecaptchaSubmit;
