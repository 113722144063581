import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const SyndicationBox = styled(Box)(({ theme }) => ({
  padding: '6px 12px',
  backgroundColor: theme.palette.lightGreen.main,
  color: theme.palette.darkGreen.dark,
  borderRadius: 6,
  fontSize: 12,
  fontWeight: 600,
}));

const SyndicationType = 'Syndication';

const PortfolioNewsFundCell = ({ funds, isMobile = false }) => (
  <Stack spacing={1} alignItems={isMobile ? 'flex-end' : 'flex-start'}>
    {funds.map((fund) => (
      fund === SyndicationType ? (
        <SyndicationBox key={fund}>{fund}</SyndicationBox>
      ) : (
        <Box key={fund}>{fund}</Box>
      )
    ))}
  </Stack>
);

PortfolioNewsFundCell.propTypes = {
  funds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool,
};

PortfolioNewsFundCell.defaultProps = {
  isMobile: false,
};

export default PortfolioNewsFundCell;
