/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions,
jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import TopbarNav from './topbar_nav/TopbarNav';
import TopbarProfile from './TopbarProfile';
import SidebarSwipeable from './sidebar_mobile/SidebarSwipeable';
import { getUserPortfolio } from '../../../shared/services/apiGateway';

const TopbarInvestor = ({ user }) => {
  const [userPortfolio, setUserPortfolio] = useState({});
  const matches = useMediaQuery('(max-width:991px)');

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      try {
        const portfolio = await getUserPortfolio(abortController.signal);
        if (!ignore && portfolio !== 'aborted' && portfolio.data) {
          setUserPortfolio(portfolio.data);
        }
      } catch (error) {
        if (error.statusCode !== 404) {
          throw error;
        }
      }
    }

    if (user) {
      fetchData();
    }

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [user]);

  return (
    <div
      className="topbar topbar--navigation"
      style={{ position: 'relative', zIndex: 1400 }}
    >
      <Helmet>
        <title>Venture Clubs - Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <div className="topbar__wrapper">
        <div className="topbar__left">
          {matches && user && <SidebarSwipeable user={user} userPortfolio={userPortfolio} />}
          <a
            style={{
              marginLeft: '15px', marginTop: '10px', marginRight: '15px', display: 'block', cursor: 'pointer',
            }}
            className="topbar__logo"
            onClick={() => window.location.replace(`${process.env.AVG_API_PATH}/`)}
          />
          {user != null && <TopbarNav user={user} userPortfolio={userPortfolio} />}
        </div>
        <div className="topbar__right">
          {user != null && <TopbarProfile user={user} />}
        </div>
      </div>
    </div>
  );
};

TopbarInvestor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
};

TopbarInvestor.defaultProps = {
  user: null,
};

function mapStateToProps(state) {
  const { auth } = state;
  const { user } = auth;
  return { user };
}

export default connect(mapStateToProps)(TopbarInvestor);
