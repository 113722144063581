import * as React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StatusBox from './StatusBox';
import Tooltip from '../../common/Tooltip';
import DistributionsHeadCells from './DistributionsHeadCells';
import MobileTableRow from '../../common/MobileTableRow';

const currentDistributionsStyle = {
  border: 'none',
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {
    border: 'none',
  },
  borderRadius: 0,
  m: 0,
  mb: '2px',
  backgroundColor: 'mobileTable.dark',
};

export default function MobileAccordion({
  panelName, row, handleChange, expanded,
  currentDistributionsOnly,
}) {
  return (
    <Accordion
      sx={{
        m: 0,
        mb: '8px',
        borderRadius: '8px',
        backgroundColor: 'mobileTable.light',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'border.dark',
        overflow: 'hidden',
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        '&:first-of-type': {
          borderRadius: '8px',
        },
        ...currentDistributionsStyle,
      }}
      expanded={expanded === panelName}
      onChange={() => {
        handleChange(panelName, expanded);
      }}
    >
      <AccordionSummary
        sx={{
          '& > div': {
            fontFamily: 'Inter',
            fontWeight: '700',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: 0,
            margin: 0,
          },
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
      >
        <span>{row.companyName}</span>
        <span>{row.amount}</span>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {DistributionsHeadCells.map((headCell) => {
          if ((currentDistributionsOnly && headCell.id === 'fundName') || headCell.id === 'companyName') {
            return null;
          }
          return (
            <MobileTableRow
              key={headCell.id}
              title={(
                <>
                  {headCell.tooltip && (
                    <Tooltip title={headCell.tooltip} />
                  )}
                  {headCell.label}
                </>
              )}
              contents={(
                <>
                  {headCell.id === 'status' ? (
                    <StatusBox status={row.status} />
                  ) : (
                    row[headCell.id]
                  )}
                </>
              )}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

MobileAccordion.propTypes = {
  panelName: PropTypes.string.isRequired,
  row: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    payee: PropTypes.string,
    paymentMethod: PropTypes.string,
    datePaid: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  currentDistributionsOnly: PropTypes.bool,
};

MobileAccordion.defaultProps = {
  currentDistributionsOnly: false,
};
