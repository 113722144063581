/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ToggleButton, Typography, Stack, Badge,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import FundLogo from './FundLogo';

const FundToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&&&': {
    borderWidth: 2,
    borderColor: theme.palette.border.main,
    borderRadius: 8,
    marginLeft: 0,
    paddingLeft: 9,
    paddingRight: 14,
    paddingTop: 7,
    paddingBottom: 6,
    textTransform: 'none',
    '&.Mui-selected': {
      backgroundColor: theme.palette.lighterPurple.main,
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const FundNameTypography = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
});

const FundAmountTypography = styled(Typography)(({ selected, theme }) => ({
  '&&': {
    fontSize: 14,
    fontWeight: 700,
    color: selected ? `${theme.palette.secondary.main}` : 'inherit',
  },
}));

const FundFilter = ({
  label,
  amount,
  value,
  fundCode,
  selected,
  ...props
}) => (
  <Badge
    badgeContent={selected ? <CheckIcon /> : null}
    color="secondary"
    sx={{
      '& .MuiBadge-badge': {
        width: 25,
        height: 25,
        borderRadius: 4,
        top: 5,
        right: 5,
      },
    }}
  >
    <FundToggleButton color="secondary" value={value} selected={selected} {...props}>
      {fundCode && <FundLogo label={label} fundCode={fundCode} />}
      <Stack direction="column">
        <FundNameTypography
          variant="body1"
          align="left"
          noWrap
        >
          {label}
        </FundNameTypography>
        <FundAmountTypography
          variant="body2"
          noWrap
          align="left"
          selected={selected}
        >
          {amount}
        </FundAmountTypography>
      </Stack>
    </FundToggleButton>
  </Badge>
);

FundFilter.propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  fundCode: PropTypes.string,
  selected: PropTypes.bool,
};

FundFilter.defaultProps = {
  fundCode: null,
  selected: false,
};

export default FundFilter;
