import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Wrapped route imports
import InvestorDashboard from '../../../Investor/InvestorDashboard';
import FundDetails from '../../../Investor/FundDetails';
import InvestorStatements from '../../../Investor/InvestorStatements';
import Managed from './Managed';

const WrappedRoutes = () => (
  <div className="container__wrap" style={{ paddingTop: '30px' }}>
    <Switch>
      <Route exact path="/funds/details/:fundCode" component={FundDetails} />
      <Route exact path="/funds/statements" component={InvestorStatements} />
      <Route exact path="/" component={InvestorDashboard} />
      <Route path="/managed" component={Managed} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  </div>
);

export default WrappedRoutes;
