import { useState, useEffect } from 'react';
import { getLoyaltyRewards } from '../../../shared/services/apiGateway';

function useLoyaltyRewards(userid = '') {
  const [loyaltyRewards, setloyaltyRewards] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getLoyaltyRewards(userid, abortController.signal);
      if (!ignore && response !== 'aborted' && response) {
        setloyaltyRewards(response);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [userid]);

  return loyaltyRewards;
}

export default useLoyaltyRewards;
