import { useState, useEffect } from 'react';
// TODO: Replace get documents with new request for document data
import { getUserDocuments } from '../../../shared/services/apiGateway';

function useUserDocuments(userid = '') {
  const [userDocuments, setUserDocuments] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getUserDocuments(userid, abortController.signal);
      if (!ignore && response !== 'aborted' && response) {
        setUserDocuments(response);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [userid]);

  return userDocuments;
}

export default useUserDocuments;
