/* eslint-disable max-len */
const avFundOptions = [
  { value: 'University of Michigan Alumni', label: 'University of Michigan Alumni: Arbor Street Ventures' },
  { value: 'UVA Alumni', label: 'UVA Alumni: Emmet Street Ventures' },
  { value: 'USC Alumni', label: 'USC Alumni: Expo Ventures' },
  { value: 'Penn State Alumni', label: 'Penn State Alumni: Allen Street Ventures' },
  { value: 'BYU Alumni', label: 'BYU Alumni: Provo Ventures' },
  { value: 'Ohio State Alumni', label: 'Ohio State Alumni: Mirror Lake Ventures' },
  { value: 'NYU Alumni', label: 'NYU Alumni: Bleecker Street Ventures' },
  { value: 'Carnegie Mellon Alumni', label: 'Carnegie Mellon Alumni: The Fence Ventures' },
  { value: 'Notre Dame Alumni', label: 'Notre Dame Alumni: Two Lakes Ventures' },
  { value: 'Georgia Tech Alumni', label: 'Georgia Tech Alumni: Fowler Street Ventures' },
  { value: 'UNC Alumni', label: 'UNC Alumni: Franklin Street Ventures' },
  { value: 'Georgetown Alumni', label: 'Georgetown Alumni: Potomac Ventures' },
  { value: 'Purdue Alumni', label: 'Purdue Alumni: Stadium Avenue Ventures' },
  { value: 'Boston University Alumni', label: 'Boston University Alumni: Comm Ave Ventures' },
  { value: 'U Washington Alumni', label: 'U Washington Alumni: Yoshino Ventures' },
  { value: 'Wisconsin Alumni', label: 'Wisconsin Alumni: Bascom Ventures' },
  { value: 'U Chicago Alumni', label: 'U Chicago Alumni: Lakeshore Ventures' },
  { value: 'Harvard Alums', label: 'Harvard Alums: Yard Ventures' },
  { value: 'Texas A&M Former Students', label: 'Texas A&M Former Students: Ring Ventures' },
  { value: 'Northwestern Alumni', label: 'Northwestern Alumni: Purple Arch Ventures' },
  { value: 'MIT Alumni', label: 'MIT Alumni: Castor Ventures' },
  { value: 'Dartmouth Alumni', label: 'Dartmouth Alumni: Green D Ventures' },
  { value: 'Columbia Alumni', label: 'Columbia Alumni: 116 Street Ventures' },
  { value: 'UC Berkeley Alumni', label: 'UC Berkeley Alumni: Strawberry Creek Ventures' },
  { value: 'UCLA Alumni', label: 'UCLA Alumni: Westwood Ventures' },
  { value: 'Brown Alumni', label: 'Brown Alumni: Waterman Ventures' },
  { value: 'Cornell Alumni', label: 'Cornell Alumni: Triphammer Ventures' },
  { value: 'Penn Alumni', label: 'Penn Alumni: Chestnut Street Ventures' },
  { value: 'Stanford Alumni', label: 'Stanford Alumni: Spike Ventures' },
  { value: 'University of Texas Alumni', label: 'University of Texas Alumni: Congress Avenue Ventures' },
  { value: 'Princeton Alumni', label: 'Princeton Alumni: Nassau Street Ventures' },
  { value: 'Yale Alumni', label: 'Yale Alumni: Blue Ivy Ventures' },
  { value: 'Duke Alumni', label: 'Duke Alumni: Towerview Ventures' },
  { value: 'AI & Robotics Fund', label: 'AI & Robotics Fund' },
  { value: 'Healthtech Fund', label: 'Healthtech Fund' },
  { value: 'Sports Fund', label: 'Sports Fund' },
  { value: 'Womens Fund', label: 'Womens Fund' },
  { value: 'CIO Select Fund', label: 'CIO Select Fund' },
  { value: 'Deep Tech Fund', label: 'Deep Tech Fund' },
  { value: 'Seed Fund', label: 'Seed Fund' },
  { value: 'Foundation Fund', label: 'Foundation Fund' },
  { value: 'US Strategic Tech', label: 'US Strategic Tech' },
  { value: 'Blockchain & Fintech Fund', label: 'Blockchain & Fintech Fund' },
];

export default avFundOptions;
