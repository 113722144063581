import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import { ClubResources } from '@alumni-ventures-group/react-shared/lib/services';
import { doSignIn } from '../../../../shared/services/authActions';
import navigateToResetPasswordHandler from '../../common/navigateToResetPasswordHandler';
import LoginForm from '../../../../shared/components/LoginForm';

const { blueColor } = ClubResources;

const navigatingToResetPassword = () => navigateToResetPasswordHandler();

const LoginCard = ({
  dispatch, existingEmail, switchScreen, history,
}) => {
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);

  if (navigatingToResetPassword()) {
    switchScreen(true);
  }

  const handleFormSubmit = async (data) => {
    const { email, password } = data;
    setLoading(true);
    try {
      const signInRes = await doSignIn(dispatch, {
        email,
        password,
      });
      if (signInRes === false) {
        setLoginError(true);
      } else {
        setLoginError(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setLoginError(true);
    } finally {
      setLoading(false);
    }
  };

  const url = new URL(window.location.href);
  const sessionTimeout = url.searchParams.get('session_timeout');

  return (
    <Card className="card--not-full-height">
      <CardBody>
        <Row className="w-100">
          <Col>
            <h3>
              <b style={{ color: blueColor.main }}>
                Investor Login
              </b>
            </h3>
            <h5>
              Login to Alumni Ventures
            </h5>
          </Col>
        </Row>
        <hr />
        {sessionTimeout && (
        <span style={{ color: '#AC2929' }}>
          Session expired. Please log in again to continue.
        </span>
        )}
        <LoginForm
          handleFormSubmit={handleFormSubmit}
          loginError={loginError}
          existingEmail={existingEmail}
          switchScreen={switchScreen}
          loading={loading}
        />
      </CardBody>
    </Card>
  );
};

LoginCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  existingEmail: PropTypes.string,
  switchScreen: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  history: PropTypes.any,
};

LoginCard.defaultProps = {
  existingEmail: '',
  switchScreen: null,
};

const mapStateToProps = (state) => ({
  dispatch: state.auth,
});

export default withRouter(connect(mapStateToProps)(LoginCard));
