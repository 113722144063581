import React, { useContext, useEffect } from 'react';
import {
  InputLabel, MenuItem, Select,
} from '@mui/material';
import { StyledFormControl } from './styles';
import { useSort, sortAlphanumerically } from '../../../../shared/hooks/useSort';
import { PortfolioNewsContext } from './PortfolioNews';

const sortOptions = [
  ['date-desc', 'Date: Newest'],
  ['date-asc', 'Date: Oldest'],
  ['headline-asc', 'Headline: A - Z'],
  ['headline-desc', 'Headline: Z - A'],
  ['source-asc', 'Source: A - Z'],
  ['source-desc', 'Source: Z - A'],
  ['company-asc', 'Company: A - Z'],
  ['company-desc', 'Company: Z - A'],
];

// const dateComparator = (a, b, field) => {
//   const strToDate = (value) => {
//     const parts = value.split('/'); // mm/dd/yyyy
//     const monthIdx = Number(parts[0]) - 1;
//     const day = Number(parts[1]);
//     const year = Number(parts[2]);
//     return new Date(year, monthIdx, day);
//   };
//   return strToDate(a[field]) - strToDate(b[field]);
// };

const dateComparator = (a, b, field) => new Date(a[field]) - new Date(b[field]);

export default () => {
  const { filteredNews, setFilteredNews } = useContext(PortfolioNewsContext);
  const { sortBy, setSortBy, setComparator } = useSort(filteredNews, 'date-desc', dateComparator, setFilteredNews);
  useEffect(() => {
    const [orderBy, _] = sortBy.split('-');
    if (orderBy === 'date') {
      setComparator(() => dateComparator);
    } else {
      setComparator(() => sortAlphanumerically);
    }
  }, [sortBy]);

  return (
    <StyledFormControl
      size="small"
      fullWidth
      sx={{
        mt: { xs: 1, sm: 1, md: 0 },
        alignSelf: { xs: 'center' },
        width: { xs: '18ch', sm: '18ch', md: '20ch' },
      }}
    >
      <InputLabel id="sortBy-label">Sort by</InputLabel>
      <Select
        labelId="sortBy-label"
        id="sortBy"
        value={sortBy}
        onChange={(e) => setSortBy(e.target.value)}
        label="Sort by"
      >
        {sortOptions.map(([value, label]) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};
