import React, { PureComponent } from 'react';
import { Field, reduxForm, propTypes } from 'redux-form';
import { Button } from 'reactstrap';
// import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import PropTypes from 'prop-types';
import { renderCheckBoxField } from '@alumni-ventures-group/react-shared/lib/components';

class LogInForm extends PureComponent {
  static propTypes = {
    ...propTypes,
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { error, handleSubmit } = this.props;
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="text"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <a
              href="/admin/default/reset-password"
            >
              Forgot a password?
            </a>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
            />
          </div>
        </div>
        {error
          ? (
            <h5
              style={{
                color: 'red',
                margin: '0 0 20px 0',
                textAlign: 'center',
                width: '100%',
              }}
            >
              Please check your credentials.
            </h5>
          )
          : ''
        }
        <Button
          type="submit"
          size="sm"
          color="primary"
          className="w-100"
        >
          Sign In
        </Button>
      </form>
    );
  }
}


export default reduxForm({
  form: 'log_in_form',
})(LogInForm);
