import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableRow = styled(TableRow)(({ index, source, theme }) => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.border.dark,
  backgroundColor: index % 2 ? theme.palette.table.light : theme.palette.table.main,
  borderLeft: source === 'MP Update'
    ? `3px solid ${theme.palette.secondary.main}`
    : `3px solid ${theme.palette.lightGrey.dark}`,
}));

export default StyledTableRow;
