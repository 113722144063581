// AVNewsDisplay.jsx
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Divider, Box, Stack, Typography, Link, useTheme,
} from '@mui/material';
import { Loader } from '@alumni-ventures-group/react-shared/lib/components';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  VerticalCenteredContainer, HorizontalCenteredContent, DataText, CustomPagination, AVNewsStyledBox,
} from './styles';
import useUserMessages from '../../common/useUserMessages';

const sortByDate = (messages) => messages.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

const formatNews = (
  userMessageData,
  path,
  currentPage,
  itemsPerPage,
) => {
  const sortedMessages = sortByDate(userMessageData.messages);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const slicedMessages = sortedMessages.slice(startIndex, endIndex);

  return (
    <Stack spacing={2}>
      {slicedMessages.map((message) => (
        <Box key={message.id}>
          <Stack spacing={1}>
            <Typography variant="h4" component="h3">
              {message.headline_text}
            </Typography>
            <Typography variant="body2Inter" component="p">
              {message.sub_text}
            </Typography>
            <Link
              href={(message.action_url && message.action_url.indexOf('http') === -1)
                ? `${path}${message.action_url}` : message.action_url}
              target={(message.action_url && message.action_url.indexOf('http') === -1) ? '' : '_blank'}
              sx={{ fontWeight: '600', marginBottom: '15px' }}
              rel="noreferrer"
            >
              Read More
            </Link>
            <Divider variant="fullWidth" />
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};

const NoNewsMessage = () => (
  <VerticalCenteredContainer>
    <DataText sx={{ xs: 'none', md: '20ch' }}>
      No news articles are available at this time
    </DataText>
  </VerticalCenteredContainer>
);

function AVNewsDisplay() {
  const theme = useTheme();
  const desktopSize = useMediaQuery(theme.breakpoints.up('lg'));
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const currentManagedUser = useSelector((state) => state.currentManagedUser);
  const [userMessageData] = useUserMessages(
    currentManagedUser ? `?userid=${currentManagedUser}` : '',
  );

  const totalMessages = userMessageData?.messages.length ?? 0;
  const totalPages = Math.ceil(totalMessages / itemsPerPage);
  const messagePerPage = desktopSize ? itemsPerPage : totalMessages;
  const messagePath = currentManagedUser
    ? 'https://investor.avgfunds.com/managed/'
    : 'https://investor.avgfunds.com/';

  if (!desktopSize && userMessageData && !totalMessages) {
    return <NoNewsMessage />;
  }

  return (
    <AVNewsStyledBox>
      <Stack spacing={2} sx={{ flex: 1 }}>
        { desktopSize && (
          <>
            <Typography component="h2" variant="h1">
              AV News
            </Typography>
            <Divider variant="fullWidth" sx={{ display: { xs: 'none', md: 'block' } }} />
          </>
        )}
        {!userMessageData && (
          <HorizontalCenteredContent>
            <Box sx={{ mt: 2 }}>
              <Loader />
            </Box>
          </HorizontalCenteredContent>
        )}
        {userMessageData && totalMessages > 0 && (
          <>
            {formatNews(userMessageData, messagePath, currentPage, messagePerPage)}
            {desktopSize && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body2Inter">
                {currentPage} of {totalPages}
              </Typography>
              <CustomPagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
              />
            </Stack>
            )}
          </>
        )}
        {userMessageData && !totalMessages && (
          <NoNewsMessage />
        )}
      </Stack>
    </AVNewsStyledBox>
  );
}

AVNewsDisplay.propTypes = {};

export default AVNewsDisplay;
