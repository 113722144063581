import React from 'react';
import PropTypes from 'prop-types';
import SmoothScrollbar from 'smooth-scrollbar';

export default class Scrollbar extends React.Component {
    static childContextTypes = {
      getScrollbar: PropTypes.func,
    };

    constructor(props) {
      super(props);
      this.callbacks = [];
    }

    getChildContext() {
      return {
        getScrollbar: (cb) => {
          if (typeof cb !== 'function') return;

          if (this.scrollbar) setTimeout(() => cb(this.scrollbar));
          else this.callbacks.push(cb);
        },
      };
    }

    componentDidMount() {
      this.scrollbar = SmoothScrollbar.init(this.$container, this.props);

      this.callbacks.forEach((cb) => {
        requestAnimationFrame(() => cb(this.scrollbar));
      });

      this.scrollbar.addListener(this.handleScroll.bind(this));
    }

    componentDidUpdate() {
      if (this.scrollbar) {
        this.scrollbar.update();
      }
    }

    componentWillUnmount() {
      if (this.scrollbar) {
        this.scrollbar.destroy();
      }
    }

    handleScroll(status) {
      const { onScroll } = this.props;
      if (onScroll) {
        onScroll(status, this.scrollbar);
      }
    }

    render() {
      const {
        damping,
        thumbMinSize,
        syncCallbacks,
        renderByPixels,
        alwaysShowTracks,
        continuousScrolling,
        delegateTo,
        plugins,
        onScroll,
        children,
        ...others
      } = this.props;

      return (
        <section data-scrollbar ref={(element) => { this.$container = element; }} {...others}>
          <div>{children}</div>
        </section>
      );
    }
}

Scrollbar.propTypes = {
  damping: PropTypes.number,
  thumbMinSize: PropTypes.number,
  syncCallbacks: PropTypes.bool,
  renderByPixels: PropTypes.bool,
  alwaysShowTracks: PropTypes.bool,
  continuousScrolling: PropTypes.bool,
  delegateTo: PropTypes.element,
  // eslint-disable-next-line react/forbid-prop-types
  plugins: PropTypes.object,
  onScroll: PropTypes.func,
  children: PropTypes.node,
};

Scrollbar.defaultProps = {
  /*
    damping: Momentum reduction damping factor, a float value between (0, 1),
      the lower the value is, the more smooth the scrolling will be (also the more paint frames).
  */
  damping: 0.1,
  thumbMinSize: 20, // Minimal size for scrollbar thumbs.
  syncCallbacks: null,
  renderByPixels: true, // Render every frame in integer pixel values, set to true to improve scrolling performance.
  alwaysShowTracks: false, // Keep scrollbar tracks always visible.
  /*
    continuousScrolling: Set to true to allow outer scrollbars continue scrolling when current scrollbar reaches edge.
  */
  continuousScrolling: true,
  delegateTo: null,
  /*
    plugins: Options for plugins, see Plugin System
      https://github.com/idiotWu/smooth-scrollbar/blob/HEAD/docs/plugin.md
  */
  plugins: {},
  onScroll: null,
  children: null,
};
