import React, { useRef, useContext } from 'react';
import { Box } from '@mui/material';
import { WVList } from 'virtua';
import MobilePortfolioNewsItem from './MobilePortfolioNewsItem';
import MobileManagingPartnerItem from './MobileManagingPartnerItem';
import MobileSkeleton from './MobileSkeleton';
import { PortfolioNewsContext } from '../PortfolioNews';

const MobilePortfolioNewsTable = () => {
  const {
    filteredNews,
    fetchPortfolioNews,
    isLoading,
  } = useContext(PortfolioNewsContext);

  const count = filteredNews.length;
  const fetchedCountRef = useRef(-1);
  return (
    <Box sx={{ width: '100%' }}>
      <WVList
        onRangeChange={async (_, end) => {
          if (!isLoading && end + 3 > count && fetchedCountRef.current < count) {
            fetchedCountRef.current = count;
            await fetchPortfolioNews({ start: count });
          }
        }}
      >
        {filteredNews.map((row) => (
          row.source !== 'MP Update' ? (
            <MobilePortfolioNewsItem key={`${row.source}-${row.headline}-${row.date}`} row={row} />
          ) : (
            <MobileManagingPartnerItem key={`${row.content}-${row.date}`} row={row} />
          )
        ))}
        {/** TODO: update to the number of news items we are pulling at once */}
        { isLoading && Array.from({ length: 28 }).map((_, i) => (
          <MobileSkeleton key={i} /> // eslint-disable-line react/no-array-index-key
        ))}
      </WVList>
    </Box>
  );
};

export default MobilePortfolioNewsTable;
